<template>
  <div class="fragment">

      <div class="section-label d-flex" v-if="!isAdmin || (isAdmin && FC.data.UserFiles.data.downloadFiles.length > 0)"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_DocumentsUp','fillClaim_DocumentsTxt','fillClaim_DocumentsTxt2'])"></div>
        {{$t('fillClaim_DocumentsUp.localization_value.value')}}

        <TooltipTextHelperPlugin>
          <template slot="text">
            <TooltipBtn/>
          </template>
          <template slot="paragraph">
            {{$t('fillClaim_DocumentsTxt.localization_value.value')}}
            {{$t('fillClaim_DocumentsTxt2.localization_value.value')}}
          </template>
        </TooltipTextHelperPlugin>

      </div>


      <div class="custom-row" :key="images">
        <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
             v-for="(item, index) in FC.data.UserFiles.data.downloadFiles"
             :key="index">
          <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item, 'user')">
                <CloseIcon/>
              </span>
            <div class="site-document__img">
              <img v-if="item.type !== 'application/pdf'"
                   @click="showSingle(item.id)"
                   :src="`data:${item.type};base64,` + item.base64" alt="img">
              <img v-else
                   @click="openPdf(item['base64'])"
                   style="width: 30px; height: 40px;"
                   src="/img/common/pdf_file_icon.svg" alt="ico">
            </div>
          </div>
        </div>
        <template v-if="!isAdmin">
          <div class="custom-col" v-if="FC.data.UserFiles.data.maxCountFiles > FC.data.UserFiles.data.downloadFiles.length">
            <DropZone class="drop-zone-bg"
                      :parentFiles="FC.data.UserFiles.data.files"
                      :multiple="true"
                      :maxCount="FC.data.UserFiles.data.maxCountFiles - FC.data.UserFiles.data.downloadFiles.length"
                      :maxSize="FC.data.UserFiles.data.maxSizeFiles"
                      :accept="'image/x-png,image/jpeg,application/pdf'"
                      @changeImg="changeImg"
                      v-bind:class="{'ui-no-valid': FC.validation.files}"
                      :error="FC.validation.files"
                      :errorTxt="$t(`${FC.validationTranslate.files}.localization_value.value`)"
            />
          </div>
        </template>
      </div>


    <template v-if="isAdmin">
      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_DocumentsUpAdmin',])"></div>
        {{$t('fillClaim_DocumentsUpAdmin.localization_value.value')}}
      </div>

      <div class="custom-row" :key="images - 100">
        <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
             v-for="(item, index) in FC.data.AdminFiles.data.downloadFiles"
             :key="index">
          <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item, 'admin')">
                <CloseIcon/>
              </span>
            <div class="site-document__img">
              <img v-if="item.type !== 'application/pdf'"
                   @click="showSingle(item.id)"
                   :src="`data:${item.type};base64,` + item.base64" alt="img">
              <img v-else
                   @click="openPdf(item['base64'])"
                   style="width: 30px; height: 40px;"
                   src="/img/common/pdf_file_icon.svg" alt="ico">
            </div>
          </div>
        </div>

        <div class="custom-col" v-if="FC.data.AdminFiles.data.maxCountFiles > FC.data.AdminFiles.data.downloadFiles.length">
          <DropZone class="drop-zone-bg"
                    :parentFiles="FC.data.AdminFiles.data.files"
                    :multiple="true"
                    :maxCount="FC.data.AdminFiles.data.maxCountFiles - FC.data.AdminFiles.data.downloadFiles.length"
                    :maxSize="FC.data.AdminFiles.data.maxSizeFiles"
                    :accept="'image/x-png,image/jpeg,application/pdf'"
                    @changeImg="changeImgAdmin"
          />
        </div>
      </div>
    </template>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>
  </div>
</template>

<script>
  import DropZone from "../../../../../coreComponents/DropZone/DropZone";
  import CloseIcon from '../../../../../../../public/img/modal-group/close-icon.svg?inline'
  import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import Lightbox from "vue-easy-lightbox";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";

  export default {
    name: "DocumentsBlock",
    components: {
      TooltipTextHelperPlugin,
      TooltipBtn,
      DropZone,
      CloseIcon,
      Lightbox
    },

    props: {
      FC: Object,
    },

    data() {
      return {
        images: -1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default: 0
      }
    },

    methods: {

      changeImg(files, maxSizeError) {
        this.FC.data.UserFiles.setFiles(files)

        this.FC.data.UserFiles.setFilesMaxSizeError(maxSizeError)
      },

      changeImgAdmin(files, maxSizeError) {
        this.FC.data.AdminFiles.setFiles(files)

        this.FC.data.AdminFiles.setFilesMaxSizeError(maxSizeError)
      },

      removeDocument(item, typeFile) {
        this.$store.dispatch('removeUserDocument', item.id).then(() => {

          let files = typeFile === 'admin' ? this.FC.data.AdminFiles : this.FC.data.UserFiles
          let updatedFiles = this._.remove(files.data.downloadFiles, function(n) {
            return n.id === item.id
          })
          files.getDownloadFiles(updatedFiles)
          this.images = item.id
        })
      },

      show() {
        this.visible = true
      },

      handleHide() {
        this.visible = false
      },

      showSingle(id) {
        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          console.log(response);
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        })
      },

      openPdf(base64) {
        this.globalOpenPdfBase64(base64)
      },

    }
  }
</script>

<style scoped>

</style>
