<template>
  <div class="fragment">
    <ProformDataTableAdmin
            @showMore="showMore"
            :countFilterParams="countFilterParams"
            @changeFilter="changeFilter"
            @resetFilter="resetFilter"
            :filterGetParams="filterGetParams"
            @updateTable="filter"
            @changeTab="(index) => changeCategory(index)"
            :tabs="tabs"

            v-if="$store.getters.getIsAdminRights === 'admin'"
    />

  </div>
</template>

<script>
  import ProformDataTableAdmin from "./ProformDataTableAdmin/ProformDataTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";

  export default {
    name: "ProformDataTable",

    components: {
      ProformDataTableAdmin,
    },

    mixins: [routeFilter, radioButtonsFunc],

    data() {
      return {
        forPage: this.$store.getters.getProformDataForPage,
        page: 1,
        onePage: 1,

        filterNameUa: '',
        filterCategory: '',
        filterGetParams: {},

        tabs: [],

        countFilterParams: 0,
      }
    },

    created() {
      this.$store.dispatch('fetchProformCategory', "?filter[ProformCategoryIsSystem]=0").then(() => {
        let firstTab = {
          id: -1,
          currentTranslate: {
            name: this.$t('proform_allCategory.localization_value.value'),
          },
          active: true,
        }

        this.tabs.push(firstTab)

        this.$store.getters.getProformCategory.map((item) => {
          item.active = false
          this.tabs.push(item)
        })


        // Init filter with check active category id
        this.checkCategory()
      }).catch(error => this.createErrorLog(error))
    },

    methods: {

      checkCategory() {
        if(this.$route.query.category !== undefined) {
          this.initialTabFilter({
            tabsVariableName: 'tabs',
            tabsActiveName: 'active',
            indexActiveTab: this._.findIndex(this.tabs, {'id': parseInt(this.$route.query.category)}),
            tabFilterName: 'category',
            tabItemIdName: 'id',
          })
        } else {
          this.filter()
        }
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          category: this.$route.query.category,
          nameUa: this.$route.query.nameUa,
        }
      },

      changeCategory(newIndex) {
        this.skipRadioChecked(this.tabs, 'active')
        this.tabs[newIndex].active = true

        if(newIndex === 0) this.changeFilter({category: ''})
        else this.changeFilter({category: this.tabs[newIndex].id})
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextProformDataPage', true)

        // save filter params in store
        this.$store.commit('setProformDataFilter', window.location.search)

        this.$store.dispatch('fetchProformData', url).then(() => {
          // skip get next page
          this.$store.commit('setNextProformDataPage', false)
        })

        this.checkCountFilter(['category'])

      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
          myQuery,
          {
            filterCategory: 'ProformAttachmentProformCategoryId',
            filterNameUa: 'value_all',
          },
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }

  }
</script>

<style scoped>

</style>
