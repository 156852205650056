<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
              'RequestReturn_RequestReturn',
            ])"></span>
        {{$t('RequestReturn_RequestReturn.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="transaction-info__section"
             v-if="item = $store.getters.getRequestReturnItem"
        >
          <div class="transaction-info__section-title"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_OrderDeatils',
                  ])"></span>
            {{$t('RequestReturn_OrderDeatils.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_LotNumber',
                  ])"></span>
                {{$t('RequestReturn_LotNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        v-if="item.parent_payment_transaction.payment_transaction_detail.l_number0 !== null"
                        :value="item.parent_payment_transaction.payment_transaction_detail.l_number0"
                />
                <ValueHelper
                        v-else
                        :value="item.parent_payment_transaction.payment_transaction_detail.invoice_number"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'requestReturn_Transactions',
                  ])"></span>
                {{$t('RequestReturn_PaymentTransactionId.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{item.parent_payment_transaction_id}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_ReturnsDetails',
                  ])"></span>
                {{$t('RequestReturn_ReturnsDetails.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{item.amount | number('0.00')}}
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_ReturnsDetails',
                  ])"></span>
                {{$t('requestReturn_Transactions.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="item.transaction_id">
                  {{item.transaction_id}}
                </template>
                 <template v-else>
                   —
                 </template>
              </div>
            </div>
          </div>

          <div class="transaction-info__section-title mt-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['requestReturn_Comment',])"></div>
            {{$t('requestReturn_Comment.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col w-100">
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="item.comment"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <!--<div class="d-flex flex-wrap mt-2 scoped-btns">-->

            <!--<span class="site-link site-link&#45;&#45;alt mr-3 mb-2 mt-2"-->
                  <!--@click="$emit('close')"-->
            <!--&gt;-->
              <!--Cancel-->
            <!--</span>-->

        <!--<MainButton-->
            <!--class="scoped-width"-->
            <!--:value="'Request'"-->
            <!--@click="$emit('close')"-->
        <!--/>-->

      <!--</div>-->
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  // import MainButton from "../../../../UI/buttons/MainButton/MainButton";


  export default {
    name: "RequestRefundsPopup",
    components: {
      ValueHelper,
      Modal,
      // MainButton,
    },
  }

</script>

<style scoped>
  .scoped-width{
    max-width: 120px;
  }

  .scoped-btns{
    display: flex;
    align-items: center;
  }
</style>
