<template>

  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number"
             @click="$emit('changeDetailClaimPopup')"
        >
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>
        </div>
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('fillClaim_TrackingNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
              >
                <div class="table-row ellipsis">
                  {{item.tracking_number}}
                </div>
                <template slot="popover">
                  <p>{{ item.tracking_number }}</p>
                </template>
              </v-popover>
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('fillClaim_Created.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('fillClaim_ShipDate.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.shipping_date | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('fillClaim_ReasonToClaim.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{$store.getters.getFillClaimCommonList.reasons[item.reason]}}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('fillClaim_Value.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.declared_value"
              />
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('fillClaim_ResultStatuses.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      v-if="item.status_result"
                      :value="$store.getters.getFillClaimCommonList.status_results[item.status_result]"
              />
              <span v-else>—</span>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('fillClaim_Status.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div class="table-card__item-info d-flex align-items-center">
                <StatusIcoBtn v-if="item.status === 'open'" class="mr-1" :type="'delivered'"/>
                <StatusIcoBtn v-if="item.status === 'draft'" class="mr-1" :type="'in-progress'"/>
                <StatusIcoBtn v-if="item.status === 'close'" class="mr-1" :type="'cancel'"/>
                {{$store.getters.getFillClaimCommonList.statuses[item.status]}}
              </div>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('fillClaim_Comment.localization_value.value') }}
            </div>
            <div class="table-card__item-info word-break">
              <ValueHelper
                      :value="item.comment"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    v-if="!isStatusClose(item.status) || isAdmin"
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.problemsFillClaim + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                    v-if="isStatusDraft(item.status)"
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('deleteItem')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {fillClaimMixin} from "../../../../../../../../mixins/fillClaimMixins/fillClaimMixin";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";

  export default {
    name: "FillClaimMobileTable",
    components: {TableUserColumn, ValueHelper, StatusIcoBtn, LinkButton},

    mixins: [fillClaimMixin],

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    }

  }
</script>

<style scoped>
.table-card__item-body .ellipsis {
  max-width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: inline-block;
}
</style>
