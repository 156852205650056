<template>
  <div class="fragment">
    <div class="custom-row">

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_CloseDate','common_dateYYYY_MM_DD'])"></div>
        <DatePickerDefault
                :label="$t('fillClaim_CloseDate.localization_value.value')"
                :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
                v-bind:class="{'ui-no-valid': FC.validation.closeDate}"
                :errorTxt="$t(`${FC.validationTranslate.closeDate}.localization_value.value`)"
                :error="FC.validation.closeDate"
                v-model="FC.data.closeDate"
        >
          <template slot="body">
            <date-picker
                    v-model="FC.data.closeDate"
                    ref="datePicker"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'YYYY-MM-DD'"
                    :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-if="isAdmin || !isAdmin && FC.data.status && isStatusOpen(FC.data.status.value)"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_ResultStatuses',])"></div>
        <DefaultSelect
                class="w-100"
                :options="resultStatuses"
                :label="$t('fillClaim_ResultStatuses.localization_value.value')"
                :optionsLabel="'name'"
                v-bind:class="{'ui-no-valid': FC.validation.resultStatus}"
                :errorTxt="$t(`${FC.validationTranslate.resultStatus}.localization_value.value`)"
                :error="FC.validation.resultStatus"
                :selected="FC.data.resultStatus"
                :disabled="!isAdmin && FC.data.status && isStatusOpen(FC.data.status.value)"
                @change="(val) => {FC.setResultStatus(val)}"
        />
      </div>

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_AdminCompensations',])"></div>
        <DefaultInput
                :label="$t('fillClaim_AdminCompensations.localization_value.value')"
                v-bind:class="{'ui-no-valid': FC.validation.compensationValue}"
                :errorTxt="$t(`${FC.validationTranslate.compensationValue}.localization_value.value`)"
                :error="FC.validation.compensationValue"
                v-model="FC.data.compensationValue"
        />
      </div>

    </div>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker';
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {fillClaimMixin} from "../../../../../../mixins/fillClaimMixins/fillClaimMixin";

  export default {
    name: "AdminFields",
    components: {
      DefaultSelect,
      DatePickerDefault,
      DefaultInput,
      DatePicker,
    },

    mixins: [fillClaimMixin],

    props: {
      FC: Object,
      createData: Object,
    },

    watch: {
      createData(newVal) {
        if(newVal?.status_results){
          this.resultStatuses = this.parseDataForSelectUse({data: newVal?.status_results, name: 'name', value: 'value'})
          console.log(this.resultStatuses);
        }
      }
    },

    data() {
      return {
        resultStatuses: []
      }
    },

    methods: {
    },

  }
</script>

<style scoped>

</style>
