<template>
  <modal
          @close="$emit('close')"
          class="custom-popup"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
              'proform_createProformRow',
            ])"></span>
        {{$t('proform_createProformRow.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="transaction-info__section" v-if="created">

          <div class="custom-row" :data-val="val">
            <template v-for="(itemType, indextype) in $store.getters.getProformDataCommonList.proformTypes">
              <div class="custom-col mb-0" :key="indextype">
                <h4 class="mt-0">
                  {{itemType.currentTranslate.name}}
                </h4>
                <div class="custom-row">
                  <template v-for="(itemLang, index) in $store.getters.GET_LANGUAGES">
                    <div class="custom-col custom-col--33 custom-col--md-100"
                         :key="index">
                      <DefaultInput
                              v-bind:class="{'ui-no-valid': validation[itemType.id + '_' + itemLang.id]}"
                              :error="validation[itemType.id + '_' + itemLang.id]"
                              :errorTxt="serverError ? validationTxt[itemType.id + '_' + itemLang.id] : $t(`${validationTranslate[itemType.id + '_' + itemLang.id]}.localization_value.value`)"
                              :label="itemLang.name"
                              :type="'text'"
                              v-model="proformTypes[itemType.id][itemLang.id]"
                              @input="changeInput"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>


          <h4 v-if="categoryId === -1 || type === 'update'" class="mt-0"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
                    'proform_category',
                  ])"></span>
            {{$t('proform_category.localization_value.value')}}
          </h4>

          <div class="custom-row" v-if="categoryId === -1 || type === 'update'">

            <div class="custom-col custom-col--100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'proform_category',
                  ])"></span>
              <DefaultSelect
                      :options="options"
                      :label="$t('proform_category.localization_value.value')"
                      @change="changeCategorySelect"
                      :otherValue="'proformData'"
                      :selected="selected"
              />
            </div>


          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns">

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
            >
              {{$t('common_cancel.localization_value.value')}}
            </span>

        <template v-if="type === 'create'">
          <MainButton
                  class="scoped-width"
                  :value="$t('common_create.localization_value.value')"
                  @click.native="handlerProformData"
          />
        </template>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import {validation} from "../../../../../services/validation";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";


  export default {
    name: "ProformDataRowPopup",
    components: {
      DefaultSelect,
      Modal,
      MainButton,
      DefaultInput,
    },

    props: {
      type: String,
      id: String,
      categoryId: Number,
    },

    data() {
      return {
        proformTypes: {},
        created: false,
        val: '',

        isEmpty: false,

        validation: [],
        validationTxt: [],
        validationTranslate: [],

        options: this.$store.getters.getProformCategory,
        selected: this.$store.getters.getProformCategory[0],

        proformCategoryId: this.$store.getters.getProformCategory[0].id,

        serverError: false,
      }
    },

    created() {

      if (this.$store.getters.GET_LANGUAGES.length > 0) {
        this.translationFields()
      } else {
        this.$store.dispatch('fetchCommonData').then(() => {
          this.translationFields()
        })
      }

    },

    mounted() {

    },

    methods: {

      changeInput(val) {
        this.val = val
      },

      changeCategorySelect(val) {
        this.selected = val
        this.proformCategoryId = val.id
      },

      translationFields() {
        if (this.categoryId !== -1) {
          this.proformCategoryId = this.categoryId
        }


        this.$store.getters.getProformDataCommonList.proformTypes.map((item) => {

          this.proformTypes[item.id] = {}
          this.validation[item.id] = {}
          this.validationTxt[item.id] = {}
          this.validationTranslate[item.id] = {}
          this.$store.getters.GET_LANGUAGES.map((itemLang) => {
            this.proformTypes[item.id][itemLang.id] = ''
            this.validation[item.id][itemLang.id] = ''
            this.validationTxt[item.id][itemLang.id] = ''
            this.validationTranslate[item.id][itemLang.id] = ''
          })
        })

        this.created = true
      },


      handlerProformData() {
        let validationItems = {}
        let validationOptions = {}

        let data = {
          proform_category_id: this.proformCategoryId,
          proform_data: [],
        }

        let subMaterialArray = []

        this.isEmpty = false

        Object.keys(this.proformTypes).map(item => {
          let proform_data = {
            proform_type_id: item,
            translations: {}
          }

          Object.keys(this.proformTypes[item]).map(typeTranslate => {
            if(this._.find(this.$store.getters.getProformDataCommonList.proformTypes, {id: parseInt(item)}).is_empty === 1) {
              subMaterialArray.push({val: this.proformTypes[item][typeTranslate]})
            }

            proform_data.translations[typeTranslate] = {
              name: this.proformTypes[item][typeTranslate],
            }
          })

          if(this._.find(this.$store.getters.getProformDataCommonList.proformTypes, {id: parseInt(item)}).is_empty === 1) {
            if(this._.filter(subMaterialArray, {val: ''}).length < this.$store.getters.GET_LANGUAGES.length) {
              this.isEmpty = true
              data.proform_data.push(proform_data)
            }
          } else {
            data.proform_data.push(proform_data)
          }
        })

        Object.keys(this.proformTypes).map(item => {
          let type = this._.find(this.$store.getters.getProformDataCommonList.proformTypes, {id: parseInt(item)})
          if(type.is_empty !== 1 || this.isEmpty){
            Object.keys(this.proformTypes[item]).map(typeTranslate => {
              validationItems[item + '_' + typeTranslate] = this.proformTypes[item][typeTranslate]
              validationOptions[item + '_' + typeTranslate] = {type: ['empty']}
            })
          }
        })

        let validate = validation(validationItems, validationOptions)
        this.validation = validate.validation
        this.validationTranslate = validate.validationError

        if (!validate.isValidate) return

        if(this.type === 'create'){
          this.createItem(data)
        } else {
          this.changeItem(data, this.$store.getters.getProformDataItem.id)
        }

      },

      createItem(data) {
        this.$store.dispatch('createProformData', data).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$emit('update')
            this.$emit('close')
          } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      changeItem(data, id) {
        this.$store.dispatch('updateProformData', {data: data, id: id}).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$emit('update')
            this.$emit('close')
          } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },
    }
  }

</script>

<style scoped>
  .scoped-width {
    max-width: 120px;
  }

  .scoped-btns {
    display: flex;
    align-items: center;
  }

  .custom-popup__content >>> .vs__clear {
    display: none !important;
  }

  .custom-popup >>> .modal-component__inner {
    max-width: 992px;
  }
</style>
