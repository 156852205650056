<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox class="empty-label" :selectedNow="selectedNow" :dataValue="item.id"/>
        <div class="table-card__item-number"
             @click="$emit('showPopup')"
        >
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-info">
            <div class="table-card__item-col " v-if="isAdmin">
              <div class="table-card__item-label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <TableUserColumn
                        :item="item.parent_payment_transaction"
                        :mobile="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('requestReturn_total.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.parent_payment_transaction.payment_total}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('requestReturn_dirtyAmount.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
<!--              <ValueHelper-->
<!--                      :value="item.amount"-->
<!--              />-->
              {{item.amount_send_payment_system | number('0.00')}}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">

          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('requestReturn_NetAmount.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.net_amount_payment_system">{{item.net_amount_payment_system | number('0.00')}}</template>
              <template v-else>—</template>
<!--              <template v-if="item.net_amount_from_socket">-->
<!--                {{item.net_amount_from_socket | number('0.00')}}-->
<!--              </template>-->
<!--              <template v-else-if="item.payment_transaction && item.parent_payment_transaction &&-->
<!--                       item.parent_payment_transaction.payment_system === PAYMENT_TYPE_NAME.payPall && !item.net_amount_from_socket">-->
<!--                {{item.payment_transaction.warehouse_amount | number('0.00')}}-->
<!--              </template>-->
<!--              <template v-else-if="item.net_amount !== null">-->
<!--                {{item.net_amount | number('0.00')}}-->
<!--              </template>-->
<!--              <template v-else>—</template>-->
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('requestReturn_paidByUser.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :costType="true"
                  :deep="'paid_by_user_summ'"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('requestReturn_Transactions.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
<!--              <div class="d-flex align-center">-->
<!--                <img class="mr-1" src="/img/company-icons-group/transaction-paypal-icon.png" alt="ico">-->
<!--                {{item.parent_payment_transaction_id}}-->
<!--              </div>-->

              <div class="d-flex align-items-center"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="d-flex align-items-center"
                     v-bind:class="{'table-link btn-style' : isAdmin && item.transaction_id}">
                  <template v-if="item.payment_transaction">
                    <template  v-if="item.parent_payment_transaction">
                      <img class="mr-1"
                           src="/img/company-icons-group/transaction-paypal-icon.png"
                           alt="ico"
                           v-if="item.payment_transaction &&
                       item.parent_payment_transaction.payment_system === PAYMENT_TYPE_NAME.payPall"
                      >
                      <img class="mr-1"
                           src="/img/company-icons-group/transaction-authorize-icon.png"
                           alt="ico"
                           v-if="item.payment_transaction &&
                       item.parent_payment_transaction.payment_system === PAYMENT_TYPE_NAME.authorize"
                      >
                    </template>{{item.payment_transaction && item.payment_transaction.transaction_id}}
                  </template>

                  <template v-else-if="item.transaction_id">{{item.transaction_id}}</template>

                  <template v-else-if="!isAdmin">—</template>

                </div>

                <TooltipTextHelperPlugin :fullTextWidth="300" :trigger="'click'" v-if="isAdmin">
                  <template slot="text">
                    <div class="site-link" v-if="item.transaction_id">
                      <LinkButton class="ml-1"
                                  :type="'edit'"/>
                    </div>
                    <div class="table-link btn-style" v-else>
                      {{ $t('importO_addNumber.localization_value.value') }}
                    </div>
                  </template>
                  <template slot="paragraph">
                    <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}</div>
                    <InputEditField
                        :value="item.transaction_id || ''"
                        :propId="item.id"
                        :hideEditField="hideEditField"
                        @onChange="(data) => $emit('changeTrackingNumber', data)"
                    />
                    <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                  </template>
                </TooltipTextHelperPlugin>
              </div>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('requestReturn_Status.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <div v-if="item.in_draft == 1"><StatusIcoBtn class="mr-1" :type="'in-progress'"/>{{$t('status_InDraft.localization_value.value')}}</div>
              <template v-else>
                <div v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS"><StatusIcoBtn class="mr-1" :type="'delivered'"/>{{$t('status_Returned.localization_value.value')}}</div>
                <div v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS"><StatusIcoBtn class="mr-1" :type="'in-transit'"/>{{$t('status_InProcess.localization_value.value')}}</div>
                <div v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS"><StatusIcoBtn class="mr-1" :type="'cancel-red'"/>{{$t('status_Error.localization_value.value')}}</div>
                <div v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS"><StatusIcoBtn class="mr-1" :type="'cancel-red'"/>{{$t('status_Canceled.localization_value.value')}}</div>
                <div v-if="item.status == 'undefined'"><StatusIcoBtn class="mr-1" :type="'cancel'"/>{{$t('status_Undefined.localization_value.value')}}</div>
              </template>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('requestReturn_Comment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.comment}}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn" v-if="item.in_draft == 1">
          <div class="table-card__item-btn-i">
              <LinkButton
                      v-if="
                        ($store.getters.getCurrentUserRights.additionalPermissions &&
                        $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]) &&
                        item.in_draft == 1 &&
                        item.status != CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS"
                      :value="$t('common_EditDraft.localization_value.value')"
                      :type="'edit'"
              />
              <LinkButton
                      v-if="item.in_draft == 1 &&
                      item.status != CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS &&
                      item.parent_payment_transaction.canMakeRefund && (isAdmin || (!isAdmin && item.parent_payment_transaction.payment_system !== PAYMENT_TYPE_NAME.authorize))"
                      :value="$t('common_Request.localization_value.value')"
                      @click.native="$emit('requestReturn')"
              />
              <LinkButton
                      v-if="item.in_draft == 1 && item.status != CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS"
                      :value="$t('common_delete.localization_value.value')"
                      :type="'delete'"
                      @click.native="$emit('delete')"
              />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  // import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import InputEditField from "@/components/UI/inputs/InputEditField/InputEditField";
  import {PAYMENT_TYPE_NAME} from "@/staticData/staticVariables";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";

  export default {
    name: "RequestReturnTableMobile",

    components: {
      TooltipTextHelperPlugin,
      InputEditField,
      // TooltipTextHelper,
      TableUserColumn,
      ValueHelper,
      DefaultCheckbox,
      StatusIcoBtn,
      LinkButton,
    },

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        hideEditField: false,
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,
      }
    }
  }
</script>

<style scoped>

</style>
