var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'RequestReturn_RequestReturn',
          ])}}}),_vm._v(" "+_vm._s(_vm.$t('RequestReturn_RequestReturn.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-popup__content"},[(_vm.item = _vm.$store.getters.getRequestReturnItem)?_c('div',{staticClass:"transaction-info__section"},[_c('div',{staticClass:"transaction-info__section-title",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_OrderDeatils',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('RequestReturn_OrderDeatils.localization_value.value'))+" ")]),_c('div',{staticClass:"transaction-info__row"},[_c('div',{staticClass:"transaction-info__col"},[_c('div',{staticClass:"transaction-info__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_LotNumber',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('RequestReturn_LotNumber.localization_value.value'))+" ")]),_c('div',{staticClass:"transaction-info__txt"},[(_vm.item.parent_payment_transaction.payment_transaction_detail.l_number0 !== null)?_c('ValueHelper',{attrs:{"value":_vm.item.parent_payment_transaction.payment_transaction_detail.l_number0}}):_c('ValueHelper',{attrs:{"value":_vm.item.parent_payment_transaction.payment_transaction_detail.invoice_number}})],1)]),_c('div',{staticClass:"transaction-info__col"},[_c('div',{staticClass:"transaction-info__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'requestReturn_Transactions',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('RequestReturn_PaymentTransactionId.localization_value.value'))+" ")]),_c('div',{staticClass:"transaction-info__txt"},[_vm._v(" "+_vm._s(_vm.item.parent_payment_transaction_id)+" ")])]),_c('div',{staticClass:"transaction-info__col"},[_c('div',{staticClass:"transaction-info__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_ReturnsDetails',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('RequestReturn_ReturnsDetails.localization_value.value'))+" ")]),_c('div',{staticClass:"transaction-info__txt"},[_vm._v(" $"+_vm._s(_vm._f("number")(_vm.item.amount,'0.00'))+" ")])]),_c('div',{staticClass:"transaction-info__col"},[_c('div',{staticClass:"transaction-info__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_ReturnsDetails',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('requestReturn_Transactions.localization_value.value'))+" ")]),_c('div',{staticClass:"transaction-info__txt"},[(_vm.item.transaction_id)?[_vm._v(" "+_vm._s(_vm.item.transaction_id)+" ")]:[_vm._v(" — ")]],2)])]),_c('div',{staticClass:"transaction-info__section-title mt-3",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['requestReturn_Comment',])}}}),_vm._v(" "+_vm._s(_vm.$t('requestReturn_Comment.localization_value.value'))+" ")]),_c('div',{staticClass:"transaction-info__row"},[_c('div',{staticClass:"transaction-info__col w-100"},[_c('div',{staticClass:"transaction-info__txt"},[_c('ValueHelper',{attrs:{"value":_vm.item.comment}})],1)])])]):_vm._e()])]),_c('template',{slot:"footer"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }