<template>
  <div class="fragment">

    <template v-if="Object.keys($store.getters.getProformData).length > 0 && $store.getters.getProformDataLoading !== true && loadingTable">
      <div class="site-table-wrap">
        <div class="site-table-wrap products-table">


          <table class="site-table" v-if="!isMobileFunc()">
            <thead>
            <tr v-if="_.has($store.getters.getProformDataCommonList, 'proformTypes')">
              <th
                v-for="(item, index) in $store.getters.getProformDataCommonList.proformTypes"
                :key="index"
              >
                <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="toggleTranslationProformTypePopup(true, index)"></span>
                    {{item.currentTranslate.name}}
                </span>

              </th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in largestColumn" :key="index">
              <td v-for="(itemType, indexType) in $store.getters.getProformDataCommonList.proformTypes"
                  :key="indexType + 'type'"
              >
                <template v-if="_.has($store.getters.getProformData[itemType.id], index)">
                  <span class="table-link btn-style text-decoration-underline"
                        @click="editItem($store.getters.getProformData[itemType.id][index].id, itemType.id)"
                  >
                    {{$store.getters.getProformData[itemType.id][index].proform_value.translationStorage.ua.name}}
                  </span>
                </template>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="table-card" v-else>
            <div class="table-card__list">
              <div class="table-card__item"
                   v-for="(itemType, indexType) in $store.getters.getProformDataCommonList.proformTypes"
                   :key="indexType + 'type'"
              >
                <ProformDataTableMobile
                        :itemsName="itemType.currentTranslate.name"
                        :items="$store.getters.getProformData[itemType.id]"
                        @showPopup="(id) => editItem(id, itemType.id)"
                />
              </div>
            </div>
          </div>


          <div class="table-bottom-btn" >
            <div class="table-bottom-btn__inner">
              <ShowMore
                      v-if="$store.getters.getProformDataCommonList.attachmentData.next_page_url !== null && Object.keys($store.getters.getProformData).length > 0"
                      @click.native="$emit('showMore')"
                      :count="25"
              />
              <!--$store.getters.getProformDataCommonList.attachmentData.total - $store.getters.getProformDataCommonList.attachmentData.to < $store.getters.getProformDataForPage ?-->
              <!--$store.getters.getProformDataCommonList.attachmentData.total - $store.getters.getProformDataCommonList.attachmentData.to :-->
              <!--$store.getters.getProformDataForPage-->
            </div>
          </div>
        </div>



    </div>
    </template>

    <template v-if="$store.getters.getProformDataLoading === false && $store.getters.getProformData.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <ProformDataPopup
            v-if="proformDataPopup"
            :type="'update'"
            :id="'' + proformDataPopupId"
            @close="toggleProformDataPopup(false)"
            @update="$emit('updateTable')"
    />

    <TranslationProformTypePopup
            v-if="translationProformTypePopup"
            @close="toggleTranslationProformTypePopup(false)"
            :index="translationProformTypeIndex"
    />

  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  // import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  // import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  // import RequestRefundsPopup from "../../../../popups/RequestRefundsPopup/RequestRefundsPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ProformDataPopup from "../../../../popups/ProformDataPopup/ProformDataPopup";
  import ProformDataTableMobile from "./ProformDataTableMobile/ProformDataTableMobile";
  import TranslationProformTypePopup from "../../../../popups/TranslationProformTypePopup/TranslationProformTypePopup";


  export default {
    name: "ProformDataTable",

    components: {
      ShowMore,
      // LinkButton,
      // ManagerButton,
      // RequestRefundsPopup,
      NoResult,
      ProformDataPopup,
      ProformDataTableMobile,
      TranslationProformTypePopup,
    },

    mixins: [mixinDetictingMobile],

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    computed: {
      count () {
        return this.$store.getters.getProformData
      }
    },

    watch: {
      count (newCount) {
        this.proformData = newCount
        if(Object.keys(newCount).length > 0){
          this.loadingTable = true
        }

        let counter = 0
        Object.keys(this.proformData).map(item => {
          if(this.proformData[item].length > counter) {
            counter = this.proformData[item].length
            this.largestColumn = this.proformData[item]
          }
        })

      }
    },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        orderId: '',
        largestColumn: [],

        translationProformTypePopup: false,
        translationProformTypeIndex: '',

        proformDataPopup: false,
        proformDataPopupId: '',

        loadingTable: false,

        proformData: this.$store.getters.getProformData,
      }
    },

    methods: {

      toggleProformDataPopup(val){
        this.proformDataPopup = val
      },

      toggleTranslationProformTypePopup(val, index = ''){
        this.translationProformTypePopup = val
        this.translationProformTypeIndex = index
      },

      editItem(id, typeId) {
        this.$store.dispatch('getProformData', id).then(() => {
          this.toggleProformDataPopup(true)
          this.proformDataPopupId = typeId
        })
      },

      removeRecord(id, index) {
        let text = {
          title: 'common_deleteConfirm',
          txt: 'proform_deleteProform',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteProformData', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let records = this.$store.getters.getProformData
              records.splice(index, 1)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)

      },

    }

  }
</script>

<style lang="scss" scoped>
  .site-table tbody td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
</style>
