<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
              'proform_createProformCategory',
              'proform_editProformCategory',
            ])"></span>
        <template v-if="type === 'create'">
          {{$t('proform_createProformCategory.localization_value.value')}}
        </template>
        <template v-else>
          {{$t('proform_editProformCategory.localization_value.value')}}
        </template>
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="transaction-info__section" v-if="created">

          <div class="custom-row">
            <template v-for="(itemLang, index) in $store.getters.GET_LANGUAGES">
              <div class="custom-col custom-col--100 custom-col--md-100"
                    :key="index">
                <DefaultInput
                        v-bind:class="{'ui-no-valid': validation[itemLang.id]}"
                        :error="validation[itemLang.id]"
                        :errorTxt="serverError ? validationTxt[itemLang.id] : $t(`${validationTranslate[itemLang.id]}.localization_value.value`)"
                        :label="itemLang.name"
                        :type="'text'"
                        v-model="name[itemLang.id]"
                />
              </div>
            </template>
            <div class="custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'proform_sort',
                  ])"></span>
              <DefaultInput
                      v-bind:class="{'ui-no-valid': validation.sortCount}"
                      :error="validation.sortCount"
                      :errorTxt="serverError ? validationTxt.sortCount : $t(`${validationTranslate.sortCount}.localization_value.value`)"
                      :label="$t('proform_sort.localization_value.value')"
                      v-model="sortCount"
              />
            </div>

          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns">

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
            >
              {{$t('common_cancel.localization_value.value')}}
            </span>

        <template v-if="type === 'create'">
          <MainButton
              class="scoped-width wfc"
              :value="$t('common_create.localization_value.value')"
              @click.native="handlerProformCategory"
          />
        </template>
        <template v-else>
          <MainButton
              class="scoped-width wfc"
              :value="$t('common_save.localization_value.value')"
              @click.native="handlerProformCategory"
          />
        </template>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  // import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import {validation} from "../../../../../services/validation";


  export default {
    name: "ProformCategoryPopup",
    components: {
      // ValueHelper,
      Modal,
      MainButton,
      DefaultInput,
    },

    props: {
      type: String,
    },

    data() {
      return {
        name: [],
        created: false,
        sortCount: '',

        validation: [],
        validationTxt: [],
        validationTranslate: [],

        serverError: false,
      }
    },

    created(){

      if(this.type === 'update'){
        this.translationUpdateFields()
      } else {
        if(this.$store.getters.GET_LANGUAGES.length > 0){
          this.translationFields()
        } else {
          this.$store.dispatch('fetchCommonData').then(() => {
            this.translationFields()
          })
        }
      }

    },

    methods: {

      translationFields() {
        this.$store.getters.GET_LANGUAGES.map(item => {
          this.name[item.id] = ''
          this.validation[item.id] = ''
          this.validationTxt[item.id] = ''
          this.validationTranslate[item.id] = ''
        })

        this.created = true
      },

      translationUpdateFields() {
        this.sortCount = this.$store.getters.getProformCategoryItem.sort_order
        this.$store.getters.GET_LANGUAGES.map(item => {
          this.name[item.id] = this.$store.getters.getProformCategoryItem.translationStorage[item.locale].name
          this.validation[item.id] = ''
          this.validationTxt[item.id] = ''
          this.validationTranslate[item.id] = ''
        })

        this.created = true
      },

      handlerProformCategory() {
        let validationItems = {}
        let validationOptions = {}

        let data = {
          translations : {},
          sort_order : this.sortCount
        }

        Object.keys(this.name).map(item => {
          data.translations[item] = {}
          data.translations[item]['name'] = this.name[item]
          validationItems[item] = this.name[item]
          validationOptions[item] = {type: ['empty']}
        })

        validationItems.sortCount = this.sortCount
        validationOptions.sortCount = {type: ['empty','numeric']}

        let validate = validation(validationItems, validationOptions)
        this.validation = validate.validation
        this.validationTranslate = validate.validationError

        if(this.type === 'create'){
          this.createItem(data)
        } else {
          this.changeItem(data, this.$store.getters.getProformCategoryItem.id)
        }

      },

      createItem(data) {
        this.$store.dispatch('createProformCategory', data).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$emit('update')
            this.$emit('close')
          } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      changeItem(data, id) {
        this.$store.dispatch('updateProformCategory', {data: data, id: id}).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$emit('update')
            this.$emit('close')
          } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },
    }
  }

</script>

<style scoped>
  .scoped-width{
    max-width: 120px;
  }

  .scoped-btns{
    display: flex;
    align-items: center;
  }
</style>
