<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['requestReturn_Date'])"></span>
        <DatePickerDefault
                :label="$t('requestReturn_Date.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
          $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
                :options="options"
                :label="$t('shop_user.localization_value.value')"
                :functionSearch="functionSearch"
                @change="changeUsersFilter"
                :selected="userName"
                :typeSelect="'users'"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['requestReturn_IDNumber'])"></span>
        <DefaultInput
            :label="$t('requestReturn_IDNumber.localization_value.value')"
            :type="'text'"
            v-model="refundId"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['requestReturn_Transactions'])"></span>
        <DefaultInput
            :label="$t('requestReturn_Transactions.localization_value.value')"
            :type="'text'"
            v-model="refundTransactionId"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['requestReturn_NetAmount'])"></span>
        <DefaultInput
            :label="$t('requestReturn_NetAmount.localization_value.value')"
            :type="'text'"
            v-model="netAmount"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['requestReturn_NetAmount'])"></span>
        <DefaultInput
            :label="$t('requestReturn_dirtyAmount.localization_value.value')"
            :type="'text'"
            v-model="dirtyAmount"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['requestReturn_NetAmount'])"></span>
        <DefaultInput
            :label="$t('requestReturn_total.localization_value.value')"
            :type="'text'"
            v-model="total"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['requestReturn_Comment'])"></span>
        <DefaultInput
            :label="$t('requestReturn_Comment.localization_value.value')"
            :type="'text'"
            v-model="comment"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['RequestReturn_PaymentTransactionId'])"></span>
        <DefaultInput
            :label="$t('RequestReturn_PaymentTransactionId.localization_value.value')"
            :type="'text'"
            v-model="paymentTransactionId"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "RequestReturnFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
      countFilterParams: Number,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        refundId: this.filterGetParams.refundId ? this.filterGetParams.refundId : '',
        refundTransactionId: this.filterGetParams.refundTransactionId ? this.filterGetParams.refundTransactionId : '',
        paymentTransactionId: this.filterGetParams.paymentTransactionId ? this.filterGetParams.paymentTransactionId : '',
        netAmount: this.filterGetParams.netAmount ? this.filterGetParams.netAmount : '',
        comment: this.filterGetParams.comment ? this.filterGetParams.comment : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        dirtyAmount: this.filterGetParams.dirtyAmount ? this.filterGetParams.dirtyAmount : '',
        total: this.filterGetParams.total ? this.filterGetParams.total : '',

        options: [],

        filterCounts: [
          'date',
          'refundId',
          'refundTransactionId',
          'paymentTransactionId',
          'netAmount',
          'comment',
          'userId',
          'userName',
          'dirtyAmount',
          'total',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.refundId = newVal.refundId ? newVal.refundId : ''
        this.refundTransactionId = newVal.refundTransactionId ? newVal.refundTransactionId : ''
        this.paymentTransactionId = newVal.paymentTransactionId ? newVal.paymentTransactionId : ''
        this.netAmount = newVal.netAmount ? newVal.netAmount : ''
        this.comment = newVal.comment ? newVal.comment : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeUsersFilter(value) {
        this.userId = value.id
        this.userName = value.contacts[0].user_full_name
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading, true)
      },

    },
  }
</script>

<style scoped>

</style>
