<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--empty"></div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter"/>
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_AddNew',])"></div>
        <router-link :to="$store.getters.GET_PATHS.problemsFillClaimCreate">
          <MainButton class="btn-fit-content ml-2"
                      :value="$t('fillClaim_AddNew.localization_value.value')"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


  export default {
    name: "FillClaimHead",
    components: {
      MainButton,
      PlusIconSVG,
      // DefaultCheckbox,
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    watch: {},

    created() {

    },

    data() {
      return {}
    },

    methods: {}
  }
</script>

