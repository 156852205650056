<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fillClaim_Claim',])"></div>
      <CardLeftBlock
              :name="''"
              :value="''"
              :backgroundImage="'product-admin'"
      >
        <template slot="button">
          <h2 class="mt-0">{{$t('fillClaim_Claim.localization_value.value')}}</h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">

            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_backTo',])"></div>
                <div class="order-create__head-back">
                  <LinkBack
                      :value="$t('fillClaim_backToTable.localization_value.value')"
                      @click.native="goRouterBack($store.getters.GET_PATHS.problemsFillClaim)"
                  />
                </div>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fillClaim_FileClaim',])"></div>
              {{$t('fillClaim_FileClaim.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fillClaim_PleaseFill',])"></div>
              {{$t('fillClaim_PleaseFill.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="custom-row" v-if="isAdmin">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :validationUser="FC.data.User.validation.userId"
                        :validationTranslateUser="FC.data.User.validationTranslate.userId"
                        :userSelected="FC.data.User.user"
                        :typeSelect="'users'"
                        :userEmail="true"
                        @changeUser="changeUser"
                />
              </div>

              <div class="custom-col custom-col--33 custom-col--md-100">
                <DefaultSelect
                    :options="adminOptions"
                    :optionsLabel="'email'"
                    :otherValue="'adminItem'"
                    :clearable="false"
                    v-bind:class="{'ui-no-valid': FC.data.AdminUser.validation.userId}"
                    :errorTxt="$t(`${FC.data.AdminUser.validationTranslate.userId}.localization_value.value`)"
                    :error="FC.data.AdminUser.validation.userId"
                    :label="$t('common_userAdmin.localization_value.value')"
                    @change="changeAdminUser"
                    :selected="FC.data.AdminUser.user"
                />
              </div>
            </div>

            <DetailsBlock
                    :FC="FC"
                    :createData="createData"
            />

            <CommentsBlock
                    :FC="FC"
            />

            <ShippingCompanyBlock
                    v-if="FC"
                    :FC="FC"
                    :createData="createData"
            />
            <DocumentsBlock
                    :FC="FC"
            />



          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel',])"></div>
              <router-link :to="$store.getters.GET_PATHS.problemsFillClaim"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_SaveInDrafts','common_drafts','common_savingInDrafts','common_create',])"></div>

              <MainButton
                      v-if="!isAdmin"
                      class="order-create__footer-btn-i secondary"
                      :value="$t('common_SaveInDrafts.localization_value.value')"
                      :tooltip="true"
                      @click.native="$emit('save', false, 'draft')"
                      v-bind:class="{'disabled-btn' : $store.getters.getFillClaimBtn}"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{$t('common_drafts.localization_value.value')}}</b>
                  </p>
                  <p>
                    {{$t('common_savingInDrafts.localization_value.value')}}
                  </p>
                </template>
              </MainButton>

              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_create.localization_value.value')"
                      @click.native="$emit('save', false, 'open')"
                      v-bind:class="{'disabled-btn' : $store.getters.getFillClaimBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import DetailsBlock from "../../components/DetailsBlock/DetailsBlock";
  import DocumentsBlock from "../../components/DocumentsBlock/DocumentsBlock";
  import ShippingCompanyBlock from "../../components/ShippingCompanyBlock/ShippingCompanyBlock";
  import CommentsBlock from "../../components/CommentsBlock/CommentsBlock";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";


  export default {
    name: "FillClaimCreationSection",

    components: {
      DefaultSelect,
      UserSelect,
      DocumentsBlock,
      ShippingCompanyBlock,
      CommentsBlock,
      DetailsBlock,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
    },

    props: {
      FC: Object,
      createData: Object,
      adminOptions: Array,
    },

    data() {
      return {

      }
    },

    methods: {

      changeUser(val) {
        this.FC.data.User.setUser(val)
      },

      changeAdminUser(val){
        this.FC.data.AdminUser.setUser(val)
      },

    }
  }
</script>

<style lang="scss">

  .hide-upc {
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps {
    max-width: 625px;
  }

</style>
