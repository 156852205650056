<template>
  <RequestReturnCreationUser/>
</template>

<script>
  import RequestReturnCreationUser from "./RequestReturnEditingUser/RequestReturnEditingUser.vue";

  export default {
    name: "RequestReturnEditing",

    components:{
      RequestReturnCreationUser,
    }
  }
</script>

<style scoped>

</style>
