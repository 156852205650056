<template>
  <div class="fragment">
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ordersInbound_comments'])"></div>
      {{ $t('fillClaim_Messages.localization_value.value') }}
    </div>

    <MessagesItems
            :FC="FC"
    />

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_newMessage'])"></div>
        <TextareaDefault
                :label="$t('fillClaim_newMessage.localization_value.value')"
                v-bind:class="{'ui-no-valid': FC.validation.message}"
                :error="FC.validation.message"
                :errorTxt="$t(`${FC.validationTranslate.message}.localization_value.value`)"
                v-model="FC.data.message"
        />
      </div>
    </div>

    <div class="order-create__row custom-row" >
      <div class="order-create__col custom-col">
        <div class="order-create__footer-btn"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fillClaim_Send'])"></div>
          <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('fillClaim_Send.localization_value.value')"
                  @click.native="sendMessage"
          />
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import MessagesItems from "./MessagesItems/MessagesItems";
  import {fillClaimMixin} from "../../../../../../mixins/fillClaimMixins/fillClaimMixin";

  export default {
    name: "MessagesBlock",
    components: {MessagesItems, MainButton, TextareaDefault},

    mixins: [fillClaimMixin],

    props: {
      FC: Object,
    },

    data() {
      return {

      }
    },

    methods: {

    }

  }
</script>

<style scoped lang="scss">


</style>
