<template>
  <modal
          @close="$emit('close')"
          class="custom-popup"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_Claim'])"></div>
        {{$t('fillClaim_Claim.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="section-label large mb-3">
          <!--{{ item.created_at | moment("DD MMM, YYYY") }}-->
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__txt d-flex align-items-center" v-if="_.has(FC.data.status, 'value')">
                <StatusIcoBtn v-if="FC.data.status.value === 'open'" class="mr-1 mt--1" :type="'delivered'"/>
                <StatusIcoBtn v-if="FC.data.status.value === 'draft'" class="mr-1 mt--1" :type="'in-progress'"/>
                <StatusIcoBtn v-if="FC.data.status.value === 'close'" class="mr-1 mt--1" :type="'cancel'"/>
                <b>{{FC.data.status.name}}</b>
              </div>
            </div>
          </div>
        </div>


        <div class="transaction-info__section">
          <div class="transaction-info__section-title"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fillClaim_Details',])"></div>
            {{$t('fillClaim_Details.localization_value.value')}}
          </div>

          <div class="d-flex" v-if="FC.data.User.userId && FC.data.AdminUser.userId">
            <TableUserNameRow
                    :colWidth="'custom-col--50'"
                    :item="FC.data.User"
            />
            <TableUserNameRow
                    :labelText="'common_userAdmin'"
                    :colWidth="'custom-col--50 pl-3'"
                    :item="FC.data.AdminUser"
            />
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fillClaim_shippingCompany'])"></div>
                {{$t('fillClaim_shippingCompany.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <div class="order-create__row custom-row" v-if="_.has(createData, 'delivery_services')">

                  <template
                          v-for="(item, index) in createData.delivery_services">
                    <div class="order-create__col custom-col custom-col--16  custom-col--sm-25 mb-0"
                         :key="index"
                         v-if="item.id === FC.data.deliverySystem"

                    >
                      <RadioLabelPicture
                              class=" mb-2 radio-label-custom"
                              :label="item['name']"
                              name="shippingCompany"
                              v-bind:class="{'ui-no-valid': FC.validation.deliverySystem}"
                              :errorTxt="$t(`${FC.validationTranslate.deliverySystem}.localization_value.value`)"
                              :error="FC.validation.deliverySystem"
                              :value="item.id === FC.data.deliverySystem"
                              :disabled="true"
                      >
                      </RadioLabelPicture>
                    </div>
                  </template>

                </div>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fillClaim_TrackingNumber',])"></div>
                {{$t('fillClaim_TrackingNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{FC.data.trackingNumber}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fillClaim_ReasonToClaim',])"></div>
                {{$t('fillClaim_ReasonToClaim.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="FC.data.claimReasons"
                        :deep="'name'"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fillClaim_ShipDate',])"></div>
                {{$t('fillClaim_ShipDate.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="FC.data.shippingDate"
                        :date="true"
                        :dateType="'DD MMM, YYYY'"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fillClaim_CloseDate',])"></div>
                {{$t('fillClaim_CloseDate.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="FC.data.closeDate"
                        :date="true"
                        :dateType="'DD MMM, YYYY'"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fillClaim_Value',])"></div>
                {{$t('fillClaim_Value.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="FC.data.declaredValue"
                />
              </div>
            </div>
          </div>

          <MessagesItems
                  :FC="FC"
          />

          <!--<div class="transaction-info__section-title mt-3"-->
               <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
            <!--<div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>-->
            <!--{{$t('common_comment.localization_value.value')}}-->
          <!--</div>-->

          <!--<div class="transaction-info__row">-->
            <!--<div class="transaction-info__col w-100">-->
              <!--<div class="transaction-info__txt word-break">-->
                <!--<ValueHelper-->
                        <!--:value="FC.data.comment"-->
                <!--/>-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->

          <template
              v-if="isAdmin">
            <div class="transaction-info__section-title mt-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fillClaim_CommentAdmin'])"></div>
              {{ $t('fillClaim_CommentAdmin.localization_value.value') }}
            </div>

            <div class="transaction-info__row">
              <div class="transaction-info__col w-100">
                <div class="transaction-info__txt word-break">
                  <ValueHelper
                      :value="FC.data.commentAdmin"
                  />
                </div>
              </div>
            </div>
          </template>

        </div>

        <div class="transaction-info__section" v-if="FC.data.UserFiles.data.downloadFiles.length > 0">
          <div class="transaction-info__section-title"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fillClaim_DocumentsUp',])"></div>
            {{$t('fillClaim_DocumentsUp.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col w-100">
              <div class="popup-documents d-flex flex-wrap">
                <div class="popup-documents__col custom-col--50 custom-col--sm-100"
                     v-for="(item, index) in FC.data.UserFiles.data.downloadFiles"
                     :key="index">
                  <div class="popup-documents__img">
                    <img v-if="item.type !== 'application/pdf'"
                         :src="`data:${item.type};base64,` + item.base64" alt="img"
                         @click="showGallery(item.id)">
                    <img v-else
                         @click="openPdf(item['base64'])"
                         style="width: 30px; height: 40px;"
                         src="/img/common/pdf_file_icon.svg" alt="ico">
                  </div>
                  <div class="popup-documents__right">
                    <div class="popup-documents__name word-break">
                      {{item.original_filename}}
                    </div>
                    <!--<div class="popup-documents__desc">-->
                      <!--300Mb-->
                    <!--</div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="transaction-info__section" v-if="isAdmin && FC.data.AdminFiles.data.downloadFiles.length > 0">
          <div class="transaction-info__section-title"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fillClaim_DocumentsUpAdmin',])"></div>
            {{$t('fillClaim_DocumentsUpAdmin.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col w-100">
              <div class="popup-documents d-flex flex-wrap">
                <div class="popup-documents__col custom-col--50 custom-col--sm-100"
                     v-for="(item, index) in FC.data.AdminFiles.data.downloadFiles"
                     :key="index">
                  <div class="popup-documents__img">
<!--                    <img v-if="item.type !== 'pdf'"-->
<!--                         :src="`data:${item.type};base64,` + item.base64" alt="img"-->
<!--                         @click="showGallery(item.id)"-->
<!--                    >-->
<!--                    <img v-else-->
<!--                         style="width: 30px; height: 40px;"-->
<!--                         src="/img/common/pdf_file_icon.svg" alt="ico">-->
                    <img v-if="item.type !== 'application/pdf'"
                         @click="showGallery(item.id)"
                         :src="`data:${item.type};base64,` + item.base64" alt="img">
                    <img v-else
                         @click="openPdf(item['base64'])"
                         style="width: 30px; height: 40px;"
                         src="/img/common/pdf_file_icon.svg" alt="ico">
                  </div>
                  <div class="popup-documents__right">
                    <div class="popup-documents__name word-break">
                      {{item.original_filename}}
                    </div>
                    <!--<div class="popup-documents__desc">-->
                      <!--300Mb-->
                    <!--</div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImageGalleryBlock
              :id="parseInt(photoId)"
              @close="photoId = -1"
      />

    </template>
    <!--<template slot="footer">-->
      <!--<div class="d-flex flex-wrap mt-2 scoped-btns">-->

            <!--<span class="site-link site-link&#45;&#45;alt mr-3 mb-2 mt-2"-->
                  <!--@click="$emit('close')"-->
            <!--&gt;-->
              <!--Cancel-->
            <!--</span>-->

        <!--<MainButton-->
                <!--class="scoped-width"-->
                <!--:value="'Request'"-->
                <!--@click="$emit('close')"-->
        <!--/>-->

      <!--</div>-->
    <!--</template>-->



  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {fillClaimMixin} from "../../../../../mixins/fillClaimMixins/fillClaimMixin";
  import {FillClaim} from "../../models/FillClaim";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import TableUserNameRow from "../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";
  import RadioLabelPicture from "../../../../UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";
  import ImageGalleryBlock from "../../../../coreComponents/ImageGalleryBlock/ImageGalleryBlock";
  import MessagesItems from "../../components/components/MessagesBlock/MessagesItems/MessagesItems";


  export default {
    name: "DetailClaimPopup",
    components: {
      MessagesItems,
      ImageGalleryBlock,
      RadioLabelPicture,
      TableUserNameRow,
      ValueHelper,
      Modal,
      StatusIcoBtn,
    },

    mixins: [fillClaimMixin],

    props: {
      id: Number
    },

    data() {
      return {
        FC: new FillClaim(),
        createData: null,
        photoId: -1,
      }
    },

    mounted() {
      this.initShippingCompanies()

      this.FC.setId(this.id)

      this.$store.dispatch('getFillClaimEdit', this.FC.getId()).then((response) => {
        let item = this.getRespData(response)
        this.createData = item
        this.FC.setItem(item, this.parseDataForSelectUse, this)

        this.getFileFromServer(item?.user_files).then(files => {
          this.FC.data.UserFiles.setDownloadFiles(files)
        })

        this.getFileFromServer(item?.admin_files).then(files => {
          this.FC.data.AdminFiles.setDownloadFiles(files)
        })

      }).catch(error => this.createErrorLog(error))

    },

    methods: {
      initShippingCompanies() {
        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        myQuery.where('DeliveryServiceUseForListings', true)

        url = url + myQuery.limit(100000).page(1).url().split('?')[1]

        this.$store.dispatch('fetchShippingCompany', url).then(response => {
          this.shippingCompanies = this.getRespPaginateData(response)
        })
      },

      showGallery(id) {
        this.photoId = id
      },

      openPdf(base64) {
        this.globalOpenPdfBase64(base64)
      },
    }

  }

</script>

<style scoped lang="scss">
  .scoped-width {
    max-width: 120px;
  }

  .scoped-btns {
    display: flex;
    align-items: center;
  }

  .popup-documents__col {
    cursor: pointer;
  }

</style>
