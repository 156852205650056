<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
              'proform_createProform',
              'proform_editProform',
            ])"></span>

        <template v-if="type === 'create'">
          <template v-if="$store.getters.getProformDataCommonList.proformTypes.length > 0">
            <template v-for="(item, index) in $store.getters.getProformDataCommonList.proformTypes">
              <span :key="index"
                v-if="item.id === parseInt(id)"
              >
                {{$t('proform_createProform.localization_value.value')}} {{item.currentTranslate.name}}
              </span>
            </template>
          </template>
        </template>
        <template v-else>
          <template v-if="$store.getters.getProformDataCommonList.proformTypes.length > 0">
            <template v-for="(item, index) in $store.getters.getProformDataCommonList.proformTypes">
              <span :key="index"
                    v-if="item.id === parseInt(id)"
              >
                {{$t('proform_editProform.localization_value.value')}} {{item.currentTranslate.name}}
              </span>
            </template>
          </template>
        </template>
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="transaction-info__section" v-if="created">

          <div class="custom-row">
            <template v-for="(itemLang, index) in $store.getters.GET_LANGUAGES">
              <div class="custom-col custom-col--100 custom-col--md-100"
                    :key="index">
                <DefaultInput
                        v-bind:class="{'ui-no-valid': validation[itemLang.id]}"
                        :error="validation[itemLang.id]"
                        :errorTxt="serverError ? validationTxt[itemLang.id] : $t(`${validationTranslate[itemLang.id]}.localization_value.value`)"
                        :label="itemLang.name"
                        :type="'text'"
                        v-model="name[itemLang.id]"
                />
              </div>
            </template>

          </div>

          <div class="custom-row" v-if="categoryId === -1 || type === 'update'">

              <div class="custom-col custom-col--100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'proform_category',
                  ])"></span>
                <DefaultSelect
                        :options="options"
                        :label="$t('proform_category.localization_value.value')"
                        @change="changeCategorySelect"
                        :otherValue="'proformData'"
                        :selected="selected"
                />
              </div>


          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns">

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
            >
              {{$t('common_cancel.localization_value.value')}}
            </span>

        <template v-if="type === 'create'">
          <MainButton
              class="scoped-width"
              :value="$t('common_create.localization_value.value')"
              @click.native="handlerProformData"
          />
        </template>
        <template v-else>
          <MainButton
                  class="scoped-width"
                  :value="$t('common_delete.localization_value.value')"
                  @click.native="deleteProformItem"
          />
          <div class="ml-auto">
            <MainButton
                    class="scoped-width wfc"
                    :value="$t('common_save.localization_value.value')"
                    @click.native="handlerProformData"
            />
          </div>
        </template>



      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  // import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import {validation} from "../../../../../services/validation";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";


  export default {
    name: "ProformDataPopup",
    components: {
      DefaultSelect,
      // ValueHelper,
      Modal,
      MainButton,
      DefaultInput,
    },

    props: {
      type: String,
      id: String,
      categoryId: Number,
    },

    data() {
      return {
        name: [],
        created: false,

        validation: [],
        validationTxt: [],
        validationTranslate: [],

        options: this.$store.getters.getProformCategory,
        selected: this.$store.getters.getProformCategory[0],

        proformCategoryId: this.$store.getters.getProformCategory[0].id,

        serverError: false,
      }
    },

    created(){

      if(this.type === 'update'){
        this.translationUpdateFields()
      } else {
        if(this.$store.getters.GET_LANGUAGES.length > 0){
          this.translationFields()
        } else {
          this.$store.dispatch('fetchCommonData').then(() => {
            this.translationFields()
          })
        }
      }

    },

    mounted() {

    },

    methods: {

      changeCategorySelect(val) {
        this.selected = val
        this.proformCategoryId = val.id
      },

      translationFields() {
        if(this.categoryId !== -1){
          this.proformCategoryId = this.categoryId
        }

        this.$store.getters.GET_LANGUAGES.map(item => {
          this.name[item.id] = ''
          this.validation[item.id] = ''
          this.validationTxt[item.id] = ''
          this.validationTranslate[item.id] = ''
        })

        this.created = true
      },

      translationUpdateFields() {

        this.selected = this.$store.getters.getProformDataItem.proform_category

        this.$store.getters.GET_LANGUAGES.map(item => {
          this.name[item.id] = this.$store.getters.getProformDataItem.proform_value.translationStorage[item.locale].name
          this.validation[item.id] = ''
          this.validationTxt[item.id] = ''
          this.validationTranslate[item.id] = ''
        })

        this.created = true
      },

      handlerProformData() {
        let validationItems = {}
        let validationOptions = {}

        let data = {
          proform_category_id: this.selected?.id,
          proform_type_id: this.id ? parseInt(this.id) : this.$store.getters.getProformDataItem.proform_type_id,
          values: {
            translations : {},
          }
        }

        Object.keys(this.name).map(item => {
          data.values.translations[item] = {}
          data.values.translations[item]['name'] = this.name[item]
          validationItems[item] = this.name[item]
          validationOptions[item] = {type: ['empty']}
        })

        let validate = validation(validationItems, validationOptions)
        this.validation = validate.validation
        this.validationTranslate = validate.validationError

        if (!validate.isValidate) return

        if(this.type === 'create'){
          this.createItem(data)
        } else {
          this.changeItem(data, this.$store.getters.getProformDataItem.id)
        }

      },

      createItem(data) {
        this.$store.dispatch('createPartialProformData', data).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$emit('update')
            this.$emit('close')
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      changeItem(data, id) {
        this.$store.dispatch('updateProformData', {data: data, id: id}).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$emit('update')
            this.$emit('close')
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      deleteProformItem() {

        let text = {
          title: 'common_deleteConfirm',
          txt: 'proform_deleteProform',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          this.$store.dispatch('deleteProformData', this.$store.getters.getProformDataItem.id).then(response => {
            if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.openNotify('success', 'common_notificationRecordDeleted')

              this.$emit('update')
              this.$emit('close')
            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }

</script>

<style scoped>
  .scoped-width{
    max-width: 120px;
  }

  .scoped-btns{
    display: flex;
    align-items: center;
  }

  .custom-popup__content >>> .vs__clear{
    display: none !important;
  }
</style>
