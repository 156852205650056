import { render, staticRenderFns } from "./RequestReturnCreationUser.vue?vue&type=template&id=180b2276"
import script from "./RequestReturnCreationUser.vue?vue&type=script&lang=js"
export * from "./RequestReturnCreationUser.vue?vue&type=script&lang=js"
import style0 from "./RequestReturnCreationUser.vue?vue&type=style&index=0&id=180b2276&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports