<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fillClaim_shippingCompany', 'fillClaim_shippingCompanyDesc'])"></div>
      {{$t('fillClaim_shippingCompany.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row" v-if="_.has(createData, 'delivery_services')">

      <div class="pl-3 pr-3 pb-3"
           v-for="(item, index) in createData.delivery_services"
           :key="index"
      >

        <RadioLabelPicture
                class="mb-2"
                :label="item['name']"
                name="shippingCompany"
                v-bind:class="{'ui-no-valid': FC.validation.deliverySystem}"
                :errorTxt="$t(`${FC.validationTranslate.deliverySystem}.localization_value.value`)"
                :error="FC.validation.deliverySystem"
                :value="item.id === FC.data.deliverySystem"
                :disabled="!isAdmin && FC.data.status && isStatusOpen(FC.data.status.value)"
                @change="FC.setDeliverySystem(item.id)"
        >
          <template slot="ico" v-if="item['logo_base64']">
            <img style="height: 11px;" :src="item['logo_base64']" alt="ico">
          </template>
        </RadioLabelPicture>
      </div>
    </div>

    <div v-if="FC.getDeliverySystem() === DHL_DELIVERY_SERVICE.id || FC.getDeliverySystem() === FEDEX_DELIVERY_SERVICE.id" class="order-create__row custom-row">
      <div class="custom-col">
        <div
            v-for="(item, index) in shippingTypes"
            :key="index"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([item.translation,])"></div>
          <RadioDefault
              class="mb-2"
              :label="$t(`${item.translation}.localization_value.value`)"
              name="shippingType"
              :value="item.value === FC.data.deliveryType"
              :disabled="!isAdmin && FC.data.status && isStatusOpen(FC.data.status.value)"
              @input="FC.setDeliveryType(item.value)"
          />
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import RadioLabelPicture from "../../../../../UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";
  import {fillClaimMixin} from "../../../../../../mixins/fillClaimMixins/fillClaimMixin";
  import {DHL_DELIVERY_SERVICE, FEDEX_DELIVERY_SERVICE} from "../../../../../../staticData/staticVariables";
  import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import {FILL_CLAIM_SHIPPING_TYPES_FEDEX_ARRAY, FILL_CLAIM_SHIPPING_TYPES_DHL_ARRAY} from "../../../../../../staticData/staticVariables";

  export default {
    name: "ShippingCompanyBlock",
    components: {RadioDefault, RadioLabelPicture},

    mixins: [fillClaimMixin],

    props: {
      FC: Object,
      createData: Object,
    },

    watch: {
      createData(newVal){
        if(newVal?.delivery_services && !this.FC.data.deliverySystem){
          this.FC.setDeliverySystem(newVal?.delivery_services[0].id)
        }
      },

      'FC.data.deliverySystem': function(newVal) {
        if (newVal === DHL_DELIVERY_SERVICE.id) {
          this.shippingTypes = FILL_CLAIM_SHIPPING_TYPES_DHL_ARRAY
          if (!this.FC.getDeliveryType()) {
            this.FC.setDeliveryType(FILL_CLAIM_SHIPPING_TYPES_DHL_ARRAY[0].value)
          }
        }
        if (newVal === FEDEX_DELIVERY_SERVICE.id) {
          this.shippingTypes = FILL_CLAIM_SHIPPING_TYPES_FEDEX_ARRAY
          if (!this.FC.getDeliveryType()) {
            this.FC.setDeliveryType(FILL_CLAIM_SHIPPING_TYPES_FEDEX_ARRAY[0].value)
          }
        }
      },
    },

    data(){
      return{
        shippingCompanies: true,
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        shippingTypes: []
      }
    },

    mounted() {
      this.initShippingCompanies()
    },

    methods: {
      initShippingCompanies() {
        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        myQuery.where('DeliveryServiceUseForListings', true)

        url = url + myQuery.limit(100000).page(1).url().split('?')[1]

        this.$store.dispatch('fetchShippingCompany', url).then(response => {
          this.shippingCompanies = this.getRespPaginateData(response)
        })
      },

    },

  }
</script>

<style scoped lang="scss">

  /deep/ .radio-label-custom .radio-label-picture__ico{
    max-width: 43px;
  }
</style>
