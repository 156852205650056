<template>
  <div class="table-filter-wrap">

    <div class="preloader-table-wrap" v-if="$store.getters.getMultiSearchLoading === true">
      <DotsShadowPreloader/>
    </div>
    <template
            v-if="$store.getters.getMultiSearch.length > 0 && $store.getters.getMultiSearchLoading !== true">
      <div class="site-table-wrap products-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'multiSearch_Type',
            'multiSearch_Id',
            'multiSearch_transaction',
            'multiSearch_Sum',
            'common_user',
            'multiSearch_payPalCases',
            'multiSearch_customersPayments',
            'multiSearch_unidentifiedPayments',
            'multiSearch_requestFunds',
            'multiSearch_requestReturn',
            'multiSearch_forbiddenGoods',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('multiSearch_Type.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{ $t('common_user.localization_value.value')}}</th>
            <th>{{ $t('multiSearch_Id.localization_value.value') }}</th>
            <th>{{ $t('multiSearch_transaction.localization_value.value') }}</th>
            <th>{{ $t('multiSearch_Sum.localization_value.value') }}</th>
<!--            <th>{{ $t('multiSearch_Link.localization_value.value') }}</th>-->
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getMultiSearch" :key="index">
            <td>
              <template v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_COMPLAINT_TRANSACTION.value">
                {{$t(`multiSearch_payPalCases.localization_value.value`)}}
              </template>
              <template v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_PAYMENT_TRANSACTION.value && item.status !== 'undefined'">
                {{$t(`multiSearch_customersPayments.localization_value.value`)}}
              </template>
              <template v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_PAYMENT_TRANSACTION.value && item.status === 'undefined'">
                {{$t(`multiSearch_unidentifiedPayments.localization_value.value`)}}
              </template>
              <template v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_BALANCE_OUTPUT_TRANSACTION.value">
                {{$t(`multiSearch_requestFunds.localization_value.value`)}}
              </template>
              <template v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_REFUND_TRANSACTION.value">
                {{$t(`multiSearch_requestReturn.localization_value.value`)}}
              </template>
              <template v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_FORBIDDEN_PAYMENT_TRANSACTION.value">
                {{$t(`multiSearch_forbiddenGoods.localization_value.value`)}}
              </template>
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="item.user"
                  :item="item"
              />
              <template v-else>—</template>
            </td>
            <td>
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_COMPLAINT_TRANSACTION.value"
                  class="table-link btn-style"
                  :value="'#' + item.id"
                  :link="$store.getters.GET_PATHS.financePayPalCases + '/?complaintId=' + item.id"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_PAYMENT_TRANSACTION.value && item.status !== 'undefined'"
                  class="table-link btn-style"
                  :value="'#' + item.id"
                  :link="$store.getters.GET_PATHS.cusPayments + '/?transactionNumber=' + item.transaction_number +
                  (item.payment_system === PAYMENT_TYPE_NAME.authorize ? '&type=' + PAYMENT_TYPE_NAME.authorize : '')"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_PAYMENT_TRANSACTION.value && item.status === 'undefined'"
                  class="table-link btn-style"
                  :value="'#' + item.id"
                  :link="$store.getters.GET_PATHS.financeUnidentifiedPayments + '/?trackingNumber=' + item.transaction_number +
                  (item.payment_system === PAYMENT_TYPE_NAME.authorize ? '&type=' + PAYMENT_TYPE_NAME.authorize : '')"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_BALANCE_OUTPUT_TRANSACTION.value"
                  class="table-link btn-style"
                  :value="'#' + item.id"
                  :link="$store.getters.GET_PATHS.financeRequestFunds + '/?id=' + item.id"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_REFUND_TRANSACTION.value"
                  class="table-link btn-style"
                  :value="'#' + item.id"
                  :link="$store.getters.GET_PATHS.financeRequestReturn + '/?refundId=' + item.id"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_FORBIDDEN_PAYMENT_TRANSACTION.value"
                  class="table-link btn-style"
                  :value="'#' + item.id"
                  :link="$store.getters.GET_PATHS.forbiddenGoods + '/?transactionNumber=' + item.transaction_number"
              />
            </td>
            <td>
              <span class="table-link btn-style"
                    @click="showTransactionInfo(item.transaction_id, item.payment_system)"
              >{{item.transaction_number}}</span>
            </td>
            <td>
                <ValueHelper
                    :value="item.payment_total"
                    :replaceValue="'—'"
                />
            </td>

          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getMultiSearch"
                 :key="index"
            >
              <MultiSearchMobileTable
                      :item="item"
                      @showTransactionInfo="showTransactionInfo"
              />
            </div>
          </div>
        </div>

<!--        <div class="table-bottom-btn">-->
<!--          <div class="table-bottom-btn__inner">-->
<!--            <ShowMore-->
<!--                    v-if="$store.getters.getMultiSearchCommonList.next_page_url !== null && $store.getters.getMultiSearch.length > 0"-->
<!--                    @click.native="$emit('showMore')"-->
<!--                    :count="$store.getters.getMultiSearchCommonList.total - $store.getters.getMultiSearchForPage < $store.getters.getMultiSearchForPage ?-->
<!--                    $store.getters.getMultiSearchCommonList.total - $store.getters.getMultiSearchForPage:-->
<!--                    $store.getters.getMultiSearchForPage"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </template>

    <template
            v-if="$store.getters.getMultiSearchLoading === false && $store.getters.getMultiSearch.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <TransactionInfo
        @close="closeTransactionInfoAdmin"
        v-if="isModalTransactionInfoAdmin"
        :itemId="showItemId"
        :transactionType="transactionType"
    />

  </div>
</template>

<script>
  // import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import MultiSearchMobileTable from "./MultiSearchMobileTable/MultiSearchMobileTable";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {MULTISEARCH_TRANSACTION_TYPES} from "../../../../../../../staticData/staticVariables";
  import {PAYMENT_TYPE_NAME} from "../../../../../../../staticData/staticVariables";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TransactionInfo from "../../../../../../coreComponents/Popups/TransactionInfoPopup/TransactionInfoPopup";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import DotsShadowPreloader from "../../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";


  export default {
    name: "MultiSearchTable",

    components: {
      DotsShadowPreloader,
      LinkButton,
      TransactionInfo,
      ValueHelper,
      TableUserColumn,
      MultiSearchMobileTable,
      NoResult,
      // ShowMore,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
      createData: Object,
    },

    data() {
      return {
        itemId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,
        isModalTransactionInfoAdmin: false,

        items: [],
        showItemId: false,
        transactionType: '',

        MULTISEARCH_TRANSACTION_TYPES: MULTISEARCH_TRANSACTION_TYPES,
        PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,

      }
    },

    // mounted() {
    //   let url = ''
    //   let query = this.$route.params.query
    //   this.$store.dispatch('fetchMultiSearch', {url, query}).then(response => {
    //     console.log(response)
    //     this.items = this.getRespData(response)
    //     console.log(this.items)
    //   })
    //
    // },

    methods: {
      showTransactionInfo(id, type) {
        this.$store.dispatch('getCusPayments', id).then(() => {
          this.isModalTransactionInfoAdmin = true
          this.transactionType = type
        })
      },

      showTransactionInfoAdmin() {
        this.isModalTransactionInfoAdmin = true
      },

      closeTransactionInfoAdmin() {
        this.isModalTransactionInfoAdmin = false
      },
    }

  }
</script>

<style lang="scss" scoped>
  @import "../../../../../../../scss/mixins/mixins";

  .products-table .table-manage-list .manager-btn {
    width: 165px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis {
    max-width: 320px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }


  @include from-1800 {
    th:last-child,
    td:last-child {
      padding-left: 50px !important;
      padding-right: 20px !important;
    }
  }

  @include for-1200 {
    .products-table .ellipsis {
      max-width: 150px;
    }
    .products-table .table-manage-list .manager-btn {
      width: 70px;
    }
  }

  .table-card__item-number {
    margin-bottom: 10px;
  }

  .preloader-table-wrap {
    padding-top: 50px;
  }

</style>
