<template>
  <div class="fragment">
    <template v-if="$store.getters.getRequestReturn.length > 0 && $store.getters.getRequestReturnLoading !== true">
      <div class="site-table-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'requestReturn_IDNumber',
          'requestReturn_Date',
          'requestReturn_total',
          'requestReturn_dirtyAmount',
          'requestReturn_NetAmount',
          'requestReturn_paidByUser',
          'requestReturn_Transactions',
          'RequestReturn_PaymentTransactionId',
          'requestReturn_Status',
          'requestReturn_Comment',
          'warehouseManagement_manage',
        ])"></span>
        <div class="site-table-wrap products-table">
          <table class="site-table" v-if="!isMobileFunc()">
            <thead>
            <tr>
              <th></th>
              <th>{{$t('requestReturn_IDNumber.localization_value.value')}}</th>
              <th>{{$t('requestReturn_Date.localization_value.value')}}</th>
              <th v-if="isAdmin">{{$t('shop_user.localization_value.value')}}</th>
              <th>{{$t('requestReturn_total.localization_value.value')}}</th>
              <th>{{$t('requestReturn_dirtyAmount.localization_value.value')}}</th>
              <th>{{$t('requestReturn_NetAmount.localization_value.value')}}</th>
              <th>{{$t('requestReturn_paidByUser.localization_value.value')}}</th>
              <th>{{$t('requestReturn_Transactions.localization_value.value')}}</th>
              <th>{{$t('RequestReturn_PaymentTransactionId.localization_value.value')}}</th>
              <th>{{$t('requestReturn_Status.localization_value.value')}}</th>
              <th>{{$t('requestReturn_Comment.localization_value.value')}}</th>
              <th>{{$t('warehouseManagement_manage.localization_value.value')}}</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in $store.getters.getRequestReturn"
                :key="index"
                v-bind:class="{'site-table__draft-row': item.in_draft == 1 && item.status != CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS}">
              <td>
                <template v-if="$store.getters.getIsAdminRights === 'admin'">
                  <DefaultCheckbox
                          class="empty-label"
                          :selectedNow="selectedNow"
                          :dataValue="item.id"
                          v-if="item.in_draft == 1 &&
                                item.status != CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS"
                  />
                </template>

                <template v-if="$store.getters.getIsAdminRights === 'user'">
                  <DefaultCheckbox
                          class="empty-label"
                          :selectedNow="selectedNow"
                          :dataValue="item.id"
                  />
                </template>
              </td>
              <td>
                <span class="table-link btn-style" @click="showPopup(item.id)">
                  #{{item.id}}
                </span>
              </td>
              <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>

              <td v-if="isAdmin">
                <TableUserColumn
                    v-if="item.parent_payment_transaction"
                        :item="item.parent_payment_transaction"
                />
                <template v-else>
                  —
                </template>
              </td>
              <td class="text-right">
                <template  v-if="item.parent_payment_transaction">
                  {{item.parent_payment_transaction.payment_total | number('0.00')}}
                </template>
                <template v-else>
                  —
                </template>
              </td>
              <td class="text-right">
                {{item.amount_send_payment_system | number('0.00')}}
              </td>
              <td class="text-right">
                <template v-if="item.net_amount_payment_system">{{item.net_amount_payment_system | number('0.00')}}</template>
                <template v-else>—</template>
<!--                <template v-if="item.net_amount_from_socket">-->
<!--                  {{item.net_amount_from_socket | number('0.00')}}-->
<!--                </template>-->
<!--                <template v-else-if="item.payment_transaction && item.parent_payment_transaction &&-->
<!--                       item.parent_payment_transaction.payment_system === PAYMENT_TYPE_NAME.payPall && !item.net_amount_from_socket">-->
<!--                  {{item.payment_transaction.warehouse_amount | number('0.00')}}-->
<!--                </template>-->
<!--                <template v-else-if="item.net_amount !== null">-->
<!--                  {{item.net_amount | number('0.00')}}-->
<!--                </template>-->
<!--                <template v-else>—</template>-->
              </td>
              <td class="text-right">
                <ValueHelper
                    :value="item"
                    :costType="true"
                    :deep="'paid_by_user_summ'"
                />
              </td>

              <td>
                <div class="d-flex align-items-center"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="d-flex align-items-center"
                       v-bind:class="{'table-link btn-style' : isAdmin && item.transaction_id}">
                    <template v-if="item.payment_transaction">
                      <template  v-if="item.parent_payment_transaction">
                        <img class="mr-1"
                             src="/img/company-icons-group/transaction-paypal-icon.png"
                             alt="ico"
                             v-if="item.payment_transaction &&
                       item.parent_payment_transaction.payment_system === PAYMENT_TYPE_NAME.payPall"
                        >
                        <img class="mr-1"
                             src="/img/company-icons-group/transaction-authorize-icon.png"
                             alt="ico"
                             v-if="item.payment_transaction &&
                       item.parent_payment_transaction.payment_system === PAYMENT_TYPE_NAME.authorize"
                        >
                      </template>{{item.payment_transaction && item.payment_transaction.transaction_id}}
                    </template>

                    <template v-else-if="item.transaction_id">{{item.transaction_id}}</template>

                    <template v-else-if="!isAdmin">—</template>

                  </div>

                  <TooltipTextHelperPlugin :fullTextWidth="300" :trigger="'click'" v-if="isAdmin">
                    <template slot="text">
                      <div class="site-link" v-if="item.transaction_id || item.payment_transaction">
                          <LinkButton class="ml-1"
                              :type="'edit'"/>
                      </div>
                      <div class="table-link btn-style" v-else>
                        {{ $t('importO_addNumber.localization_value.value') }}
                      </div>
                    </template>
                    <template slot="paragraph">
                      <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}</div>
                      <InputEditField
                          :value="item.transaction_id || ''"
                          :propId="item.id"
                          :hideEditField="hideEditField"
                          @onChange="(data) => changeTrackingNumber(data)"
                      />
                      <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                    </template>
                  </TooltipTextHelperPlugin>
                </div>

<!--                <div class="d-flex align-center">-->
<!--                  <template v-if="item.payment_transaction">-->
<!--                    <template  v-if="item.parent_payment_transaction">-->
<!--                      <img class="mr-1"-->
<!--                           src="/img/company-icons-group/transaction-paypal-icon.png"-->
<!--                           alt="ico"-->
<!--                           v-if="item.payment_transaction &&-->
<!--                         item.parent_payment_transaction.payment_system === PAYMENT_TYPE_NAME.payPall"-->
<!--                      >-->
<!--                      <img class="mr-1"-->
<!--                           src="/img/company-icons-group/transaction-authorize-icon.png"-->
<!--                           alt="ico"-->
<!--                           v-if="item.payment_transaction &&-->
<!--                         item.parent_payment_transaction.payment_system === PAYMENT_TYPE_NAME.authorize"-->
<!--                      >-->
<!--                    </template>-->
<!--                    {{item.payment_transaction && item.payment_transaction.transaction_id}}-->
<!--                  </template>-->
<!--                  <template v-else>-->
<!--                    <ValueHelper-->
<!--                        :value="item"-->
<!--                        :deep="'transaction_id'"-->
<!--                        :replaceValue="'—'"-->
<!--                    />-->
<!--                  </template>-->
<!--                </div>-->
              </td>
              <td>
                <template  v-if="item.parent_payment_transaction">
                  {{item.parent_payment_transaction_id}}
                </template>
                <template v-else>—</template>
              </td>
              <td>
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                    'status_InDraft',
                    'status_Returned',
                    'status_InProcess',
                    'status_Error',
                    'status_Canceled',
                    'status_Undefined',
                  ])"></span>
                  <div class="line-height-20" v-if="item.in_draft == 1 && item.status != CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS">
                    <StatusIcoBtn class="mr-1" :type="'in-progress'"/>{{$t('status_InDraft.localization_value.value')}}
                  </div>
                  <template v-else>
                    <div class="line-height-20" v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS"><StatusIcoBtn class="mr-1" :type="'delivered'"/>{{$t('status_Returned.localization_value.value')}}</div>
                    <div class="line-height-20" v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS"><StatusIcoBtn class="mr-1" :type="'in-transit'"/>{{$t('status_InProcess.localization_value.value')}}</div>
                    <div class="line-height-20" v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS"><StatusIcoBtn class="mr-1" :type="'cancel-red'"/>{{$t('status_Error.localization_value.value')}}</div>
                    <div class="line-height-20" v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS"><StatusIcoBtn class="mr-1" :type="'cancel-red'"/>{{$t('status_Canceled.localization_value.value')}}</div>
                    <div class="line-height-20" v-if="item.status == 'undefined'"><StatusIcoBtn class="mr-1" :type="'cancel'"/>{{$t('status_Undefined.localization_value.value')}}</div>
                  </template>

                </div>

              </td>
              <td>
                <div class="comment d-flex">
                  <TooltipTextHelper
                          :text="item.comment"
                          :paragraph="item.comment"
                  />
                </div>
              </td>
              <td>
                <div class="table-right table-manage-list" v-if="item.in_draft == 1">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                    <template slot="item">
                      <LinkButton
                              v-if="
                                ($store.getters.getCurrentUserRights.additionalPermissions &&
                                $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]) &&
                                item.in_draft == 1 &&
                                item.status != CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS"
                              :link="$store.getters.GET_PATHS.financeRequestReturnEdit + `/${item.id}`"
                              :value="$t('common_EditDraft.localization_value.value')"
                              :type="'edit'"
                      />
                      <LinkButton
                              v-if="item.in_draft == 1 && item.parent_payment_transaction &&
                              item.status != CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS && item.parent_payment_transaction.canMakeRefund &&
                              (isAdmin || (!isAdmin && item.parent_payment_transaction.payment_system !== PAYMENT_TYPE_NAME.authorize))"
                              :value="$t('common_Request.localization_value.value')"
                              :type="'tranasfer'"
                              @click.native="requestReturn(item.id, index)"
                      />
                      <LinkButton
                              v-if="item.in_draft == 1 && item.status != CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS"
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removeRequest(item.id, index)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="table-card" v-else>
            <div class="table-card__list">
              <div class="table-card__item"
                   v-for="(item, index) in $store.getters.getRequestReturn"
                   :key="index"
              >
                <RequestReturnTableMobile
                  :item="item"
                  @showPopup="showPopup(item.id)"
                  @delete="removeRequest(item.id, index)"
                  @requestReturn="requestReturn(item.id, index)"
                  @changeTrackingNumber="changeTrackingNumber"
                  :selectedNow="selectedNow"
                />
              </div>
            </div>
          </div>

          <div class="table-bottom-btn" >
            <div class="table-bottom-btn__inner">
              <ShowMore
                      v-if="$store.getters.getRequestReturnCommonList.next_page_url !== null && $store.getters.getRequestReturn.length > 0"
                      @click.native="$emit('showMore')"
                      :count="$store.getters.getRequestReturnCommonList.total - $store.getters.getRequestReturnForPage * $store.getters.getRequestReturnCommonList.current_page < $store.getters.getRequestReturnForPage ?
                  $store.getters.getRequestReturnCommonList.total - $store.getters.getRequestReturnForPage * $store.getters.getRequestReturnCommonList.current_page:
                  $store.getters.getRequestReturnForPage"
              />
              <ExportBtn
                  @downloadFiles="type => $emit('downloadFiles', type)"
                      class="table-bottom-btn__right"/>
            </div>
          </div>
        </div>


        <RequestRefundsPopup
          v-if="isModalRequestRefundsPopup"
          @close="changeRequestRefundsPopup(false)"
        />
    </div>
    </template>

    <template v-if="$store.getters.getRequestReturnLoading === false && $store.getters.getRequestReturn.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import RequestRefundsPopup from "../../../../popups/RequestRefundsPopup/RequestRefundsPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import RequestReturnTableMobile from "./RequestReturnTableMobile/RequestReturnTableMobile";
  import {CUSTOMERS_PAYMENTS_STATUSES, PAYMENT_TYPE_NAME} from "../../../../../../../staticData/staticVariables";
  import {requestReturnFunc} from "../../../../../../../mixins/requestReturnMixins/requestReturnFunc";
  import TooltipTextHelper from "../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  // import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import InputEditField from "@/components/UI/inputs/InputEditField/InputEditField";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";


  export default {
    name: "RequestReturnTable",

    components: {
      ValueHelper,
      TooltipTextHelperPlugin,
      InputEditField,
      // ValueHelper,
      TableUserColumn,
      TooltipTextHelper,
      ShowMore,
      ExportBtn,
      DefaultCheckbox,
      LinkButton,
      ManagerButton,
      StatusIcoBtn,
      RequestRefundsPopup,
      NoResult,
      RequestReturnTableMobile,
    },

    mixins: [mixinDetictingMobile, requestReturnFunc],

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        isModalRequestReturnToWarehouse: false,
        isModalRequestRefundsPopup: false,

        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,

        hideEditField: false,

        items: [
          {
            item: 'empty',
            draft: false,
            show: false
          },
          {
            item: 'empty',
            draft: true,
            show: false
          },
          {
            item: 'empty',
            draft: false,
            show: false
          },
        ]

      }
    },

    methods: {
      closePopup(){
        this.isModalRequestReturnToWarehouse = false
      },

      changeRequestRefundsPopup(val){
        this.isModalRequestRefundsPopup = val
      },

      showPopup(id) {
        this.$store.dispatch('getRequestReturn', id).then(() => {
          this.changeRequestRefundsPopup(true)
        })
      },

      changeTrackingNumber(val) {
        document.getElementsByClassName('tooltip__close')[0].click()

        let data = {
          "transaction_id": val.value
        }

        this.$store.dispatch('changeRequestReturnTransactions', {id: val.id, data}).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              this._.find(this.$store.getters.getRequestReturn, {id: val.id}).transaction_id = val.value
              this.openNotify('success', 'common_notificationRecordChanged')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      removeRequest(id, index) {
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteRequestReturn', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let requestReturn = this.$store.getters.getRequestReturn
              requestReturn.splice(index, 1)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)

      },

    }

  }
</script>

<style lang="scss" scoped>

  .products-table .table-manage-list .manager-btn{
    width: 145px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }


  tbody td:nth-child(7){
    min-width: 100px;
  }

  /deep/ .comment .tooltip-helper-full{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 142px;
    position: relative;
    display: inline-block !important;
    white-space: nowrap;
  }

  .table-manage-list .link-btn{
    white-space: nowrap;
  }

  .line-height-20{
    line-height: 20px;
    display: flex;
  }

</style>
