<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_Id',])"></div>
        <DefaultInput
                :label="$t('fillClaim_Id.localization_value.value')"
                :type="'text'"
                v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_Created','common_dateMM_DD_YY'])"></div>
        <DatePickerDefault
                :label="$t('fillClaim_Created.localization_value.value')"
                v-model="createdAt"
        >
          <template slot="body">
            <date-picker
                    v-model="createdAt"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="$t('common_dateMM_DD_YY.localization_value.value')"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
                :options="options"
                :label="$t('shop_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearchUserFilter"
                @change="changeUsersFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_userAdmin'])"></span>

        <DefaultSelect
            :options="adminOptions"
            :optionsLabel="'email'"
            :otherValue="'adminItem'"
            :label="$t('common_userAdmin.localization_value.value')"
            @change="(val) => {changeAdminsFilter(val)}"
            :selected="adminItem"
        />
      </div>

      <!--<div class="table-filter__item"-->
           <!--v-if="isAdmin"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit" @click="editTranslate(['common_userAdmin'])"></span>-->
        <!--<SearchSelect-->
                <!--:options="options"-->
                <!--:label="$t('common_userAdmin.localization_value.value')"-->
                <!--:optionsLabel="'email.contacts[0]'"-->
                <!--:functionSearch="functionSearch"-->
                <!--:selected="userAdminName"-->
                <!--:typeSelect="'users'"-->
                <!--:userEmail="true"-->
                <!--@change="changeUserAdminFilter"-->
        <!--/>-->
      <!--</div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_ShipDate',])"></div>
        <DatePickerDefault
                :label="$t('fillClaim_ShipDate.localization_value.value')"
                v-model="shippingDate"
        >
          <template slot="body">
            <date-picker
                    v-model="shippingDate"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_CloseDate',])"></div>
        <DatePickerDefault
                :label="$t('fillClaim_CloseDate.localization_value.value')"
                v-model="closeDate"
        >
          <template slot="body">
            <date-picker
                    v-model="closeDate"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="$t('fillClaim_TrackingNumber.localization_value.value')"
            :type="'text'"
            v-model="trackingNumber"
        />
      </div>

      <div class="table-filter__item"
           v-if="createData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_ReasonToClaim',])"></div>
        <DefaultSelect
                class="w-100"
                :options="createData.reasons"
                :label="$t('fillClaim_ReasonToClaim.localization_value.value')"
                :optionsLabel="'name'"
                :selected="reasonName ? {name: reasonName, value: reasonId} : ''"
                @change="(val) => {this.reasonId = val.value; this.reasonName = val.name}"
        />
      </div>

      <div class="table-filter__item"
           v-if="createData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_Status',])"></div>
        <DefaultSelect
                class="w-100"
                :options="createData.statuses"
                :label="$t('fillClaim_Status.localization_value.value')"
                :optionsLabel="'name'"
                :selected="statusName ? {name: statusName, value: statusId} : ''"
                @change="(val) => {this.statusId = val.value; this.statusName = val.name}"
        />
      </div>

      <div class="table-filter__item"
           v-if="createData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_shippingCompany',])"></div>
        <DefaultSelect
                class="w-100"
                :options="createData.delivery_services"
                :label="$t('fillClaim_shippingCompany.localization_value.value')"
                :optionsLabel="'name'"
                :selected="shippingCompanyName ? {name: shippingCompanyName, id: shippingCompanyId} : ''"
                @change="(val) => {this.shippingCompanyId = val.id; this.shippingCompanyName = val.name}"
        />
      </div>

      <div class="table-filter__item"
           v-if="createData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_ResultStatuses',])"></div>
        <DefaultSelect
                class="w-100"
                :options="createData.status_results"
                :label="$t('fillClaim_ResultStatuses.localization_value.value')"
                :optionsLabel="'name'"
                :selected="processName ? {name: processName, value: processId} : ''"
                @change="(val) => {this.processId = val.value; this.processName = val.name}"
        />
      </div>


    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "FillClaimFilter",
    components: {
      DefaultSelect,
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
      createData: Object,
    },

    mixins: [filterFunc, usersFunc],

    mounted() {
      if(this.loadUserAuthorizedData && this.isAdmin) {
        this.getAdminsInFilter()
      }
    },

    data(){
      return {
        createdAt: this.filterGetParams.createdAt ? this.generateFilterDate(this.filterGetParams.createdAt, 'MM/DD/YY'): '',
        shippingDate: this.filterGetParams.shippingDate ? this.generateFilterDate(this.filterGetParams.shippingDate, 'MM/DD/YY'): '',
        closeDate: this.filterGetParams.closeDate ? this.generateFilterDate(this.filterGetParams.closeDate, 'MM/DD/YY'): '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userAdminId: this.filterGetParams.userAdminId ? this.filterGetParams.userAdminId : '',
        userAdminName: this.filterGetParams.userAdminName ? this.filterGetParams.userAdminName : '',
        trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',

        reasonId: this.filterGetParams.reasonId ? this.filterGetParams.reasonId : '',
        reasonName: this.filterGetParams.reasonName ? this.filterGetParams.reasonName : '',
        statusId: this.filterGetParams.statusId ? this.filterGetParams.statusId : '',
        statusName: this.filterGetParams.statusName ? this.filterGetParams.statusName : '',
        shippingCompanyId: this.filterGetParams.shippingCompanyId ? this.filterGetParams.shippingCompanyId : '',
        shippingCompanyName: this.filterGetParams.shippingCompanyName ? this.filterGetParams.shippingCompanyName : '',
        processId: this.filterGetParams.processId ? this.filterGetParams.processId : '',
        processName: this.filterGetParams.processName ? this.filterGetParams.processName : '',

        filterCounts: [
          'createdAt',
          'shippingDate',
          'closeDate',
          'id',
          'userId',
          'userName',
          'userAdminId',
          'userAdminName',
          'trackingNumber',

          'reasonId',
          'reasonName',
          'statusId',
          'statusName',
          'shippingCompanyId',
          'shippingCompanyName',
          'processId',
          'processName',
        ],

        options: [],
        adminOptions: [],
        adminItem: null,
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.createdAt = newVal.createdAt ? this.generateFilterDate(newVal.createdAt, 'MM/DD/YY') : ''
        this.shippingDate = newVal.shippingDate ? this.generateFilterDate(newVal.shippingDate, 'MM/DD/YY') : ''
        this.closeDate = newVal.closeDate ? this.generateFilterDate(newVal.closeDate, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userAdminId = newVal.userAdminId ? newVal.userAdminId : ''
        this.userAdminName = newVal.userAdminName ? newVal.userAdminName : ''
        this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''

        this.reasonId = newVal.reasonId ? newVal.reasonId : ''
        this.reasonName = newVal.reasonName ? newVal.reasonName : ''
        this.statusId = newVal.statusId ? newVal.statusId : ''
        this.statusName = newVal.statusName ? newVal.statusName : ''
        this.shippingCompanyId = newVal.shippingCompanyId ? newVal.shippingCompanyId : ''
        this.shippingCompanyName = newVal.shippingCompanyName ? newVal.shippingCompanyName : ''
        this.processId = newVal.processId ? newVal.processId : ''
        this.processName = newVal.processName ? newVal.processName : ''
      },

      loadUserAuthorizedData() {
        if (this.isAdmin) {
          this.getAdminsInFilter()
        }
      },

    },

    methods: {

      getAdminsInFilter() {
        this.$store.dispatch('fetchAdminUsersFromFilter', '').then(response => {
          this.adminOptions = this.getRespData(response)
        })
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.createdAt = this.changeDateParams(this, 'createdAt')
        data.shippingDate = this.changeDateParams(this, 'shippingDate')
        data.closeDate = this.changeDateParams(this, 'closeDate')

        this.$emit('changeFilter', data)
      },

      changeAdminsFilter(value) {
        this.adminItem = value
        this.userAdminId = value.id
        this.userAdminName = value.user_personal_contact.user_full_name
      },
    },
  }
</script>

<style scoped>

</style>
