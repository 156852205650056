<template>
  <div>
    <FillClaimHead
            :countFilterParams="countFilterParams"
            :showFilter="showFilter"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <FillClaimFilter
              :showFilter="showFilter"
              :filterGetParams="filterGetParams"
              :createData="createData"
              @close="closeFilter"
              @changeFilter="data => $emit('changeFilter', data)"
              @resetFilter="$emit('resetFilter')"
      />

      <FillClaimTable
              :createData="createData"
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @updateTable="$emit('updateTable')"
              @reload="$emit('reload')"
              @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>


  import FillClaimHead from "./FillClaimHead/FillClaimHead";
  import FillClaimTable from "./FillClaimTable/FillClaimTable";
  import FillClaimFilter from "./FillClaimFilter/FillClaimFilter";

  export default {
    name: "FillClaimTableUser",

    components: {
      FillClaimHead,
      FillClaimTable,
      FillClaimFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      createData: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>
