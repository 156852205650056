<template>
  <div>
    <ProformCategoryHead
      :countFilterParams="countFilterParams"
      :showFilter="showFilter"
      @toggleFilter="toggleFilter"
      @updateTable="$emit('updateTable')"
    />

    <div class="table-filter-wrap">

      <ProformCategoryFilter
          :showFilter="showFilter"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
          :filterGetParams="filterGetParams"
      />

      <ProformCategoryTable
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @updateTable="$emit('updateTable')"
          :countFilterParams="countFilterParams"
      />
    </div>
  </div>
</template>

<script>


  import ProformCategoryHead from "./ProformCategoryHead/ProformCategoryHead";
  import ProformCategoryTable from "./ProformCategoryTable/ProformCategoryTable";
  import ProformCategoryFilter from "./ProformCategoryFilter/ProformCategoryFilter";

  export default {
    name: "ProformCategoryTableAdmin",

    components: {
      ProformCategoryHead,
      ProformCategoryTable,
      ProformCategoryFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },


      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
