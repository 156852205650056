export function RequestReturn() {

  this.lotNumber = '',
  this.transactionNumber = '',
  this.refundPartially = 0,
  this.returnType = '',
  this.comment = '',
  // this.refundFee = 0,
  this.withApi = false,
  // this.statusSelected = '',

  this.validation = {
    refundPartially: false,
    comment: false,
    // statusSelected: false,
    // refundFee: false,
  },

  this.validationTranslate = {
    refundPartially: '',
    comment: '',
    // statusSelected: '',
    // refundFee: '',
  },

  this.validationTxt = {
    refundPartially: false,
    comment: false,
    // statusSelected: false,
    // refundFee: false,
  }
}

RequestReturn.prototype.setReturnType = function (val) {
  this.returnType = val
}

RequestReturn.prototype.getValidation = function () {
  return this.validation
}

RequestReturn.prototype.getValidationTranslate = function () {
  return this.validationTranslate
}

RequestReturn.prototype.getValidationTxt = function () {
  return this.validationTxt
}
