<template>
  <modal
      @close="$emit('close')"
      class="custom-popup pdf-popup"
  >
    <template slot="body">
      <div class="custom-popup__content">
        <div class="pdf">
          <div class="pdf__wrap">
            <div class="pdf__inner"
              v-if="numPages"
            >
              <pdf class="pdf__pages"
                   v-for="i in numPages"
                   :key="i"
                   :src="src"
                   :page="i"
                   style="display: inline-block; width: 25%"
              ></pdf>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns">

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
            >
              Cancel
            </span>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import pdf from 'vue-pdf'


  export default {
    name: "PdfClaimPopup",
    components: {
      Modal,
      pdf,
    },

    data() {
      return {
        src: pdf.createLoadingTask('/pdf/swipes.pdf'),
        numPages: undefined,
      }
    },

    mounted() {
      this.src.promise.then(pdf => {
        this.numPages = pdf.numPages;
      });
    },
  }

</script>

<style lang="scss">

  .pdf-popup {
    .modal-component__inner{
      max-width: 700px;
    }

    .modal-component__header{
      display: none;
    }

    .modal-component__content{
      padding: 0;
    }

    .modal-component__footer{
      padding: 15px 35px 20px;
      background: white;
    }
  }


</style>
