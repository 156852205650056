<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number"
             @click="$emit('showPopup')"
        >
          {{itemsName}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row" v-for="(item, index) in items" :key="index">
          <div class="table-card__item-col">
            <div class="table-card__item-info">
              <span class="table-link btn-style text-decoration-underline"
                    @click="$emit('showPopup', item.id)"
              >
                    {{item.proform_value.translationStorage.ua.name}}
                  </span>
            </div>
          </div>
        </div>



      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  // import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  // import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ProformDataTableMobile",

    components: {
      // ValueHelper,
      // DefaultCheckbox,
      // StatusIcoBtn,
      // LinkButton,
    },

    props: {
      itemsName: String,
      items: Object,
    },

    data() {
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
