<template>
  <div>
    <RequestReturnHead
      :selectedNow="selectedNow"
      @selectAll="selectAll"
      @returnCheckedRequest="$emit('returnCheckedRequest')"
      :countFilterParams="countFilterParams"
      :showFilter="showFilter"
      @toggleFilter="toggleFilter"
    />

    <div class="table-filter-wrap">

      <RequestReturnFilter
          :showFilter="showFilter"
          :countFilterParams="countFilterParams"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
          :filterGetParams="filterGetParams"
      />

      <RequestReturnTable
          @showMore="$emit('showMore')"
          @downloadFiles="type => $emit('downloadFiles', type)"
          :selectedNow="selectedNow"
          @resetFilter="$emit('resetFilter')"
          :countFilterParams="countFilterParams"
      />
    </div>
  </div>
</template>

<script>


  import RequestReturnHead from "./RequestReturnHead/RequestReturnHead";
  import RequestReturnTable from "./RequestReturnTable/RequestReturnTable";
  import RequestReturnFilter from "./RequestReturnFilter/RequestReturnFilter";

  export default {
    name: "RequestReturnTableAllUser",

    components: {
      RequestReturnHead,
      RequestReturnTable,
      RequestReturnFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    data(){
      return{
        showFilter: false,
        filterId: '',
        filterName: '',
        forPage: 10,
        page: 1,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
