<template>
  <FillClaimCreationSection
          :FC="FC"
          :createData="createData"
          :adminOptions="adminOptions"
          @save="save"
  />
</template>

<script>
  import FillClaimCreationSection from "./FillClaimEditingSection/FillClaimEditingSection.vue";
  import {FillClaim} from "../../models/FillClaim";
  import {fillClaimMixin} from "../../../../../mixins/fillClaimMixins/fillClaimMixin";

  export default {
    name: "FillClaimEditing",

    components:{
      FillClaimCreationSection,
    },

    mixins: [fillClaimMixin],

    data() {
      return {
        FC: new FillClaim(),
        createData: null,
        adminOptions: [],
      }
    },

    mounted() {
      this.FC.setId(this.$route.params.id)

      this.$store.dispatch('getFillClaimEdit', this.FC.getId()).then((response) => {
        let item = this.getRespData(response)
        this.createData = item
        this.FC.setItem(item, this.parseDataForSelectUse, this)

        if (this.isAdmin) {
          this.getAdminsInFilter()
        }

        this.getFileFromServer(item?.user_files).then(files => {
          this.FC.data.UserFiles.setDownloadFiles(files)
        })

        this.getFileFromServer(item?.admin_files).then(files => {
          this.FC.data.AdminFiles.setDownloadFiles(files)
        })

      }).catch(error => this.createErrorLog(error))

    },

    methods: {
      getAdminsInFilter() {
        this.$store.dispatch('fetchAdminUsersFromFilter', '').then(response => {
          this.adminOptions = this.getRespData(response)
        })
      },
    }

  }
</script>

<style scoped>

</style>
