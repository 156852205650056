import { render, staticRenderFns } from "./ProformDataFilter.vue?vue&type=template&id=854bf632&scoped=true"
import script from "./ProformDataFilter.vue?vue&type=script&lang=js"
export * from "./ProformDataFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "854bf632",
  null
  
)

export default component.exports