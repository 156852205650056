var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[(_vm.$store.getters.getRequestReturn.length > 0 && _vm.$store.getters.getRequestReturnLoading !== true)?[_c('div',{staticClass:"site-table-wrap",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'requestReturn_IDNumber',
          'requestReturn_Date',
          'requestReturn_total',
          'requestReturn_dirtyAmount',
          'requestReturn_NetAmount',
          'requestReturn_paidByUser',
          'requestReturn_Transactions',
          'RequestReturn_PaymentTransactionId',
          'requestReturn_Status',
          'requestReturn_Comment',
          'warehouseManagement_manage',
        ])}}}),_c('div',{staticClass:"site-table-wrap products-table"},[(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(_vm._s(_vm.$t('requestReturn_IDNumber.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('requestReturn_Date.localization_value.value')))]),(_vm.isAdmin)?_c('th',[_vm._v(_vm._s(_vm.$t('shop_user.localization_value.value')))]):_vm._e(),_c('th',[_vm._v(_vm._s(_vm.$t('requestReturn_total.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('requestReturn_dirtyAmount.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('requestReturn_NetAmount.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('requestReturn_paidByUser.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('requestReturn_Transactions.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('RequestReturn_PaymentTransactionId.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('requestReturn_Status.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('requestReturn_Comment.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('warehouseManagement_manage.localization_value.value')))])])]),_c('tbody',_vm._l((_vm.$store.getters.getRequestReturn),function(item,index){return _c('tr',{key:index,class:{'site-table__draft-row': item.in_draft == 1 && item.status != _vm.CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS}},[_c('td',[(_vm.$store.getters.getIsAdminRights === 'admin')?[(item.in_draft == 1 &&
                                item.status != _vm.CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS)?_c('DefaultCheckbox',{staticClass:"empty-label",attrs:{"selectedNow":_vm.selectedNow,"dataValue":item.id}}):_vm._e()]:_vm._e(),(_vm.$store.getters.getIsAdminRights === 'user')?[_c('DefaultCheckbox',{staticClass:"empty-label",attrs:{"selectedNow":_vm.selectedNow,"dataValue":item.id}})]:_vm._e()],2),_c('td',[_c('span',{staticClass:"table-link btn-style",on:{"click":function($event){return _vm.showPopup(item.id)}}},[_vm._v(" #"+_vm._s(item.id)+" ")])]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"DD MMM, YYYY")))]),(_vm.isAdmin)?_c('td',[(item.parent_payment_transaction)?_c('TableUserColumn',{attrs:{"item":item.parent_payment_transaction}}):[_vm._v(" — ")]],2):_vm._e(),_c('td',{staticClass:"text-right"},[(item.parent_payment_transaction)?[_vm._v(" "+_vm._s(_vm._f("number")(item.parent_payment_transaction.payment_total,'0.00'))+" ")]:[_vm._v(" — ")]],2),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("number")(item.amount_send_payment_system,'0.00'))+" ")]),_c('td',{staticClass:"text-right"},[(item.net_amount_payment_system)?[_vm._v(_vm._s(_vm._f("number")(item.net_amount_payment_system,'0.00')))]:[_vm._v("—")]],2),_c('td',{staticClass:"text-right"},[_c('ValueHelper',{attrs:{"value":item,"costType":true,"deep":'paid_by_user_summ'}})],1),_c('td',[_c('div',{staticClass:"d-flex align-items-center",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"d-flex align-items-center",class:{'table-link btn-style' : _vm.isAdmin && item.transaction_id}},[(item.payment_transaction)?[(item.parent_payment_transaction)?[(item.payment_transaction &&
                       item.parent_payment_transaction.payment_system === _vm.PAYMENT_TYPE_NAME.payPall)?_c('img',{staticClass:"mr-1",attrs:{"src":"/img/company-icons-group/transaction-paypal-icon.png","alt":"ico"}}):_vm._e(),(item.payment_transaction &&
                       item.parent_payment_transaction.payment_system === _vm.PAYMENT_TYPE_NAME.authorize)?_c('img',{staticClass:"mr-1",attrs:{"src":"/img/company-icons-group/transaction-authorize-icon.png","alt":"ico"}}):_vm._e()]:_vm._e(),_vm._v(_vm._s(item.payment_transaction && item.payment_transaction.transaction_id)+" ")]:(item.transaction_id)?[_vm._v(_vm._s(item.transaction_id))]:(!_vm.isAdmin)?[_vm._v("—")]:_vm._e()],2),(_vm.isAdmin)?_c('TooltipTextHelperPlugin',{attrs:{"fullTextWidth":300,"trigger":'click'}},[_c('template',{slot:"text"},[(item.transaction_id || item.payment_transaction)?_c('div',{staticClass:"site-link"},[_c('LinkButton',{staticClass:"ml-1",attrs:{"type":'edit'}})],1):_c('div',{staticClass:"table-link btn-style"},[_vm._v(" "+_vm._s(_vm.$t('importO_addNumber.localization_value.value'))+" ")])]),_c('template',{slot:"paragraph"},[_c('div',{staticClass:"section-label mb-2"},[_vm._v(_vm._s(_vm.$t('importO_trackingNumber.localization_value.value')))]),_c('InputEditField',{attrs:{"value":item.transaction_id || '',"propId":item.id,"hideEditField":_vm.hideEditField},on:{"onChange":(data) => _vm.changeTrackingNumber(data)}}),_c('a',{directives:[{name:"close-popover",rawName:"v-close-popover"}],ref:"closePopover",refInFor:true,staticClass:"tooltip__close"})],1)],2):_vm._e()],1)]),_c('td',[(item.parent_payment_transaction)?[_vm._v(" "+_vm._s(item.parent_payment_transaction_id)+" ")]:[_vm._v("—")]],2),_c('td',[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                    'status_InDraft',
                    'status_Returned',
                    'status_InProcess',
                    'status_Error',
                    'status_Canceled',
                    'status_Undefined',
                  ])}}}),(item.in_draft == 1 && item.status != _vm.CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS)?_c('div',{staticClass:"line-height-20"},[_c('StatusIcoBtn',{staticClass:"mr-1",attrs:{"type":'in-progress'}}),_vm._v(_vm._s(_vm.$t('status_InDraft.localization_value.value'))+" ")],1):[(item.status == _vm.CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS)?_c('div',{staticClass:"line-height-20"},[_c('StatusIcoBtn',{staticClass:"mr-1",attrs:{"type":'delivered'}}),_vm._v(_vm._s(_vm.$t('status_Returned.localization_value.value')))],1):_vm._e(),(item.status == _vm.CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS)?_c('div',{staticClass:"line-height-20"},[_c('StatusIcoBtn',{staticClass:"mr-1",attrs:{"type":'in-transit'}}),_vm._v(_vm._s(_vm.$t('status_InProcess.localization_value.value')))],1):_vm._e(),(item.status == _vm.CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS)?_c('div',{staticClass:"line-height-20"},[_c('StatusIcoBtn',{staticClass:"mr-1",attrs:{"type":'cancel-red'}}),_vm._v(_vm._s(_vm.$t('status_Error.localization_value.value')))],1):_vm._e(),(item.status == _vm.CUSTOMERS_PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS)?_c('div',{staticClass:"line-height-20"},[_c('StatusIcoBtn',{staticClass:"mr-1",attrs:{"type":'cancel-red'}}),_vm._v(_vm._s(_vm.$t('status_Canceled.localization_value.value')))],1):_vm._e(),(item.status == 'undefined')?_c('div',{staticClass:"line-height-20"},[_c('StatusIcoBtn',{staticClass:"mr-1",attrs:{"type":'cancel'}}),_vm._v(_vm._s(_vm.$t('status_Undefined.localization_value.value')))],1):_vm._e()]],2)]),_c('td',[_c('div',{staticClass:"comment d-flex"},[_c('TooltipTextHelper',{attrs:{"text":item.comment,"paragraph":item.comment}})],1)]),_c('td',[(item.in_draft == 1)?_c('div',{staticClass:"table-right table-manage-list"},[_c('ManagerButton',{key:_vm.$store.getters.getGlobalUpdateManagerButton,on:{"reloadManagerBtn":function($event){_vm.$store.commit('setGlobalUpdateManagerButton', Math.random())}}},[_c('template',{slot:"item"},[(
                                (_vm.$store.getters.getCurrentUserRights.additionalPermissions &&
                                _vm.$store.getters.getCurrentUserRights.additionalPermissions[_vm.PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]) &&
                                item.in_draft == 1 &&
                                item.status != _vm.CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS)?_c('LinkButton',{attrs:{"link":_vm.$store.getters.GET_PATHS.financeRequestReturnEdit + `/${item.id}`,"value":_vm.$t('common_EditDraft.localization_value.value'),"type":'edit'}}):_vm._e(),(item.in_draft == 1 && item.parent_payment_transaction &&
                              item.status != _vm.CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS && item.parent_payment_transaction.canMakeRefund &&
                              (_vm.isAdmin || (!_vm.isAdmin && item.parent_payment_transaction.payment_system !== _vm.PAYMENT_TYPE_NAME.authorize)))?_c('LinkButton',{attrs:{"value":_vm.$t('common_Request.localization_value.value'),"type":'tranasfer'},nativeOn:{"click":function($event){return _vm.requestReturn(item.id, index)}}}):_vm._e(),(item.in_draft == 1 && item.status != _vm.CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS)?_c('LinkButton',{attrs:{"value":_vm.$t('common_delete.localization_value.value'),"type":'delete'},nativeOn:{"click":function($event){return _vm.removeRequest(item.id, index)}}}):_vm._e()],1)],2)],1):_vm._e()])])}),0)]):_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list"},_vm._l((_vm.$store.getters.getRequestReturn),function(item,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('RequestReturnTableMobile',{attrs:{"item":item,"selectedNow":_vm.selectedNow},on:{"showPopup":function($event){return _vm.showPopup(item.id)},"delete":function($event){return _vm.removeRequest(item.id, index)},"requestReturn":function($event){return _vm.requestReturn(item.id, index)},"changeTrackingNumber":_vm.changeTrackingNumber}})],1)}),0)]),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getRequestReturnCommonList.next_page_url !== null && _vm.$store.getters.getRequestReturn.length > 0)?_c('ShowMore',{attrs:{"count":_vm.$store.getters.getRequestReturnCommonList.total - _vm.$store.getters.getRequestReturnForPage * _vm.$store.getters.getRequestReturnCommonList.current_page < _vm.$store.getters.getRequestReturnForPage ?
                  _vm.$store.getters.getRequestReturnCommonList.total - _vm.$store.getters.getRequestReturnForPage * _vm.$store.getters.getRequestReturnCommonList.current_page:
                  _vm.$store.getters.getRequestReturnForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e(),_c('ExportBtn',{staticClass:"table-bottom-btn__right",on:{"downloadFiles":type => _vm.$emit('downloadFiles', type)}})],1)])]),(_vm.isModalRequestRefundsPopup)?_c('RequestRefundsPopup',{on:{"close":function($event){return _vm.changeRequestRefundsPopup(false)}}}):_vm._e()],1)]:_vm._e(),(_vm.$store.getters.getRequestReturnLoading === false && _vm.$store.getters.getRequestReturn.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }