<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number"
             @click="$emit('showPopup')"
        >
          #{{item.id}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('proform_nameUa.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.translationStorage.ua.name}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('proform_nameRu.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.translationStorage.ru.name}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('proform_nameEn.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.translationStorage.en.name}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('proform_sort.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.sort_order}}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    @click.native="$emit('editItem')"
                    class="mb-3"
            />
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removeRecord')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";

  export default {
    name: "ProformCategoryTableMobile",

    components: {
      LinkButton,
    },

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
