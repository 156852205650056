var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'proform_createProformRow',
          ])}}}),_vm._v(" "+_vm._s(_vm.$t('proform_createProformRow.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-popup__content"},[(_vm.created)?_c('div',{staticClass:"transaction-info__section"},[_c('div',{staticClass:"custom-row",attrs:{"data-val":_vm.val}},[_vm._l((_vm.$store.getters.getProformDataCommonList.proformTypes),function(itemType,indextype){return [_c('div',{key:indextype,staticClass:"custom-col mb-0"},[_c('h4',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(itemType.currentTranslate.name)+" ")]),_c('div',{staticClass:"custom-row"},[_vm._l((_vm.$store.getters.GET_LANGUAGES),function(itemLang,index){return [_c('div',{key:index,staticClass:"custom-col custom-col--33 custom-col--md-100"},[_c('DefaultInput',{class:{'ui-no-valid': _vm.validation[itemType.id + '_' + itemLang.id]},attrs:{"error":_vm.validation[itemType.id + '_' + itemLang.id],"errorTxt":_vm.serverError ? _vm.validationTxt[itemType.id + '_' + itemLang.id] : _vm.$t(`${_vm.validationTranslate[itemType.id + '_' + itemLang.id]}.localization_value.value`),"label":itemLang.name,"type":'text'},on:{"input":_vm.changeInput},model:{value:(_vm.proformTypes[itemType.id][itemLang.id]),callback:function ($$v) {_vm.$set(_vm.proformTypes[itemType.id], itemLang.id, $$v)},expression:"proformTypes[itemType.id][itemLang.id]"}})],1)]})],2)])]})],2),(_vm.categoryId === -1 || _vm.type === 'update')?_c('h4',{staticClass:"mt-0",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'proform_category',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('proform_category.localization_value.value'))+" ")]):_vm._e(),(_vm.categoryId === -1 || _vm.type === 'update')?_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'proform_category',
                ])}}}),_c('DefaultSelect',{attrs:{"options":_vm.options,"label":_vm.$t('proform_category.localization_value.value'),"otherValue":'proformData',"selected":_vm.selected},on:{"change":_vm.changeCategorySelect}})],1)]):_vm._e()]):_vm._e()])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"d-flex flex-wrap mt-2 scoped-btns"},[_c('span',{staticClass:"site-link site-link--alt mr-3 mb-2 mt-2",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),(_vm.type === 'create')?[_c('MainButton',{staticClass:"scoped-width",attrs:{"value":_vm.$t('common_create.localization_value.value')},nativeOn:{"click":function($event){return _vm.handlerProformData.apply(null, arguments)}}})]:_vm._e()],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }