<template>
  <div class="fragment">
    <!-- all props & events -->
    <Lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
    ></Lightbox>
  </div>
</template>

<script>
  import Lightbox from 'vue-easy-lightbox'

  export default {
    name: "ImageGalleryBlock",
    components: {
      Lightbox,
    },

    props: {
      id: Number,
    },

    watch: {
      id(id){
        if(id !== -1){
          this.showSingle(id)
        }
      }
    },

    data() {
      return {
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default:

        images: 0,
      }
    },

    mounted() {

    },

    methods: {
      handleHide() {
        this.visible = false
        this.$emit('close')
      },

      show() {
        this.visible = true
      },

      showSingle(id) {
        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        }).catch(error => this.createErrorLog(error))
      },
    }

  }
</script>

<style scoped>

</style>
