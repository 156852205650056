
<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--empty"></div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
        <DefaultCheckbox
                :label="$t('warehouseManagement_selectAll.localization_value.value')"
                @input="(val) => $emit('selectAll', val)"
        />
        <div class="btn-left-block-i"
             v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
                                $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]">

          <div class="btn-left-block__list">
            <div class="btn-left-block__list__i">
              <TableLeftBlockBtn
                      class="btn-left-block-i--link"
                      :value="$t('common_Request.localization_value.value')"
                      :type="'transfer'"
                      @click.native="$emit('returnCheckedRequest')"
              />
            </div>
          </div>

        </div>
      </div>
      <div class="btn-right-block">
        <!--<router-link :to="$store.getters.GET_PATHS.financeRequestReturnCreate">-->
          <!--<MainButton class="btn-fit-content"-->
                      <!--:value="'Request Return'"-->
                      <!--:ico="'ico'"-->
          <!--&gt;-->
            <!--<template slot="ico">-->
              <!--<PlusIconSVG class="btn-icon-plus" />-->
            <!--</template>-->
          <!--</MainButton>-->
        <!--</router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TableLeftBlockBtn from "../../../../../../coreComponents/TableComponents/TableLeftBlockBtn/TableLeftBlockBtn";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


  export default {
    name: "RequestReturnHead",
    components: {
      // MainButton,
      // PlusIconSVG,
      DefaultCheckbox,
      ToggleFilterButton,
      TableLeftBlockBtn,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },


    watch: {

    },

    created() {

    },

    data() {
      return {

      }
    },



    methods: {


      changeTab(val) {
        this.$emit('changeTab', val)
      },

    }
  }
</script>

