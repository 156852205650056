<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
          :name="$t('RequestReturn_Return.localization_value.value')"
          :value="'#' + $route.params.id"
          :backgroundImage="'product-admin'"
      >
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_BackReturns',
                  ])"></span>
                <router-link :to="$store.getters.GET_PATHS.financeRequestReturn" class="order-create__head-back">
                  <LinkBack
                      :value="$t('RequestReturn_BackReturns.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_RequestReturn',
                  ])"></span>
              {{ $t('RequestReturn_RequestReturn.localization_value.value') }}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_PleaseFill',
                  ])"></span>
              {{ $t('RequestReturn_PleaseFill.localization_value.value') }}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_OrderDeatils',
                  ])"></span>
              {{ $t('RequestReturn_OrderDeatils.localization_value.value') }}
            </div>
            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_LotNumber',
                  ])"></span>
                <DefaultInput
                    :label="$t('RequestReturn_LotNumber.localization_value.value')"
                    :errorTxt="'This field is required'"
                    v-model="requestReturn.lotNumber"
                />
              </div>
              <div class="custom-col custom-col--33 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_Transaction',
                  ])"></span>
                <DefaultInput
                    :label="$t('RequestReturn_Transaction.localization_value.value')"
                    :errorTxt="'This field is required'"
                    v-model="requestReturn.transactionNumber"
                />
              </div>
            </div>

            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_ReturnsDetails',
                  ])"></span>
              {{ $t('RequestReturn_ReturnsDetails.localization_value.value') }}
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--16 custom-col--md-50 custom-col--xs-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_RefundTotaly',
                  ])"></span>
                <RadioDefault
                    :label="$t('RequestReturn_RefundTotaly.localization_value.value')"
                    :name="'insuranceRadio'"
                    @change.native="changeRadio(returnTypes.totally)"
                    :value="requestReturn.returnType === returnTypes.totally"
                />
              </div>
              <div
                  class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--xs-100 flex-column mb-0"
                  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_RefundPartially',
                    'RequestReturn_RefundSum',
                  ])"></span>
                <RadioDefault
                    :label="$t('RequestReturn_RefundPartially.localization_value.value')"
                    :name="'insuranceRadio'"
                    @change.native="changeRadio(returnTypes.partially)"
                    :value="requestReturn.returnType === returnTypes.partially"
                />

                <div class="custom-row mt-4">
                  <div class="custom-col custom-col--50">
                    <InputSum
                        v-model="requestReturn.refundPartially"
                        :numeric="true"
                        :label="$t('RequestReturn_RefundSum.localization_value.value')"
                        :icoType="'dollar'"
                        :placeholder="'0'"
                        :disabled="requestReturn.returnType === returnTypes.totally"
                        v-bind:class="{'ui-no-valid': validation.amount,
                        'disabled-opacity' : requestReturn.returnType === returnTypes.totally}"
                        :error="validation.amount"
                        :errorTxt="serverError ? validationTxt.amount : $t(`${validationTranslate.amount}.localization_value.value`)"
                    />
                  </div>
                </div>
              </div>
            </div>


            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'requestReturn_Comment',
                  ])"></span>
              {{ $t('requestReturn_Comment.localization_value.value') }}
            </div>

            <div class="order-create__row custom-row"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'requestReturn_Comment',
                  ])"></span>
              <div class="order-create__col custom-col custom-col--66 custom-col--md-100">
                <TextareaDefault
                    :label="$t('requestReturn_Comment.localization_value.value')"
                    v-model="requestReturn.comment"
                    v-bind:class="{'ui-no-valid': validation.comment}"
                    :error="validation.comment"
                    :errorTxt="serverError ? validationTxt.comment : $t(`${validationTranslate.comment}.localization_value.value`)"
                />
              </div>
            </div>

            <div class="custom-row"
                 v-if="Object.hasOwnProperty.call($store.getters.getCusPaymentsItem, 'payment_system') &&
                 $store.getters.getCusPaymentsItem.payment_system == PAYMENT_TYPE_NAME.payPall &&
                  $store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'RequestReturn_WriteOff',
                  ])"></span>
                <DefaultCheckbox
                    class="wfc"
                    :label="$t('RequestReturn_WriteOff.localization_value.value')"
                    v-model="requestReturn.withApi"
                />
              </div>
            </div>

          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'common_cancel',
                  ])"></span>
              <router-link :to="$store.getters.GET_PATHS.financeRequestReturn"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-if="$store.getters.getCusPaymentsItem.canMakeRefund"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    // 'common_SaveInDrafts',
                    // 'common_drafts',
                    // 'common_savingInDrafts',
                    'common_create',
                  ])"></span>

              <MainButton
                  v-if="!isAdmin && Object.hasOwnProperty.call($store.getters.getCusPaymentsItem, 'payment_system') &&
                    $store.getters.getCusPaymentsItem.payment_system == PAYMENT_TYPE_NAME.authorize"
                  class="order-create__footer-btn-i secondary"
                  :value="$t('common_SaveInDrafts.localization_value.value')"
                  :tooltip="true"
                  @click.native="createReturns(true)"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{ $t('common_drafts.localization_value.value') }}</b>
                  </p>
                  <p>
                    {{ $t('common_savingInDrafts.localization_value.value') }}
                  </p>
                </template>
              </MainButton>

              <MainButton
                  v-else
                  class="order-create__footer-btn-i"
                  :value="$t('common_create.localization_value.value')"
                  @click.native="changeSavePopup(true)"
                  v-bind:class="{'disabled-btn' : $store.getters.getRequestReturnBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>

    <!--    <SaveInDraftsPopup-->
    <!--        @close="changeSaveInDraftsPopup(false)"-->
    <!--        v-if="isModalSaveInDraftsPopup"-->
    <!--        @yes="createReturns(true)"-->
    <!--        :disabledBtn="$store.getters.getRequestReturnBtn"-->
    <!--    />-->

    <ConfirmSavePopup
        v-if="isModalSavePopup"
        @close="changeSavePopup(false)"
        @yes="createReturns(false)"
        :customerName="customerName"
        :returnSum="requestReturn.returnType === returnTypes.partially ? requestReturn.refundPartially : $store.getters.getCusPaymentsItem.refundAmount"
        :disabledBtn="$store.getters.getRequestReturnBtn"
    />


  </div>
</template>

<script>
import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput.vue";
import InputSum from "../../../../../UI/inputs/InputSum/InputSum.vue";
import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
import {RequestReturn} from "../variebles";
import {validation} from "../../../../../../services/validation";
import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {PAYMENT_TYPE_NAME} from "../../../../../../staticData/staticVariables";
import {requestReturnSocketMixin} from "../../../../../../mixins/socketMixins/socketMixin";
import ConfirmSavePopup from "../../../popups/ConfirmSavePopup/ConfirmSavePopup";
// import SaveInDraftsPopup from "../../../../../coreComponents/Popups/SaveInDraftsPopup/SaveInDraftsPopup";


export default {
  name: "RequestReturnCreationUser",

  components: {
    ConfirmSavePopup,
    // SaveInDraftsPopup,
    DefaultCheckbox,
    CardLeftBlock,
    MainButton,
    CardRightBlock,
    LinkBack,
    DefaultInput,
    InputSum,
    RadioDefault,
    TextareaDefault,
  },

  mixins: [requestReturnSocketMixin],

  data() {
    return {

      typeReturns: 'new',

      PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,

      types: {
        new: 'new',
        withPayments: 'withPayments',
        draft: 'draft',
      },

      returnTypes: {
        totally: 'totally',
        partially: 'partially',
      },

      requestReturn: new RequestReturn(),

      serverError: false,
      validation: {},
      validationTranslate: {},
      validationTxt: {},

      isModalSavePopup: false,

      customerName: '',

    }
  },

  created() {
    this.requestReturn.setReturnType(this.returnTypes.totally)

    this.validation = this.requestReturn.getValidation()
    this.validationTranslate = this.requestReturn.getValidationTranslate()
    this.validationTxt = this.requestReturn.getValidationTxt()
  },

  mounted() {
    if (this.$route.params.id) {
      this.typeReturns = this.types.withPayments

      this.$store.dispatch('getCusPayments', this.$route.params.id).then(() => {
        let getCusPaymentsItem = this.$store.getters.getCusPaymentsItem

        this.customerName = getCusPaymentsItem?.payment_transaction_detail?.payment_transaction_contact?.full_name
        this.requestReturn.refundPartially = getCusPaymentsItem.refundAmount
        this.requestReturn.lotNumber = this.showLotNumber(getCusPaymentsItem)
        this.requestReturn.transactionNumber = getCusPaymentsItem.transaction_id +
            " : $" + getCusPaymentsItem.payment_total
      })
    }
  },

  methods: {

    changeSavePopup(val) {
      this.isModalSavePopup = val
    },

    trySave() {
      this.changeSavePopup(true)
    },


    showLotNumber(payment) {
      if (payment.payment_transaction_detail.l_number0 !== null) {
        return payment.payment_transaction_detail.l_number0
      } else if (payment.payment_transaction_detail.invoice_number !== null) {
        return payment.payment_transaction_detail.invoice_number
      } else {
        return '-'
      }
    },

    changeRadio(val) {
      let getCusPaymentsItem = this.$store.getters.getCusPaymentsItem
      this.requestReturn.refundPartially = getCusPaymentsItem.refundAmount
      this.requestReturn.setReturnType(val)
    },

    // onlyNumber(val){
    //   console.log(this.$options.filters.number(val));
    //   // this.requestReturn.refundPartially = this.$options.filters.number(val)
    // },

    createReturns(isDrafts = false) {
      let validationItems = {
        // comment : this.requestReturn.comment
      }

      let validationOptions = {
        // comment: {type: ['empty']},
      }

      if (this.requestReturn.returnType === this.returnTypes.partially) {
        let getCusPaymentsItem = this.$store.getters.getCusPaymentsItem
        let maxRefundAmount = Number(getCusPaymentsItem.refundAmount)

        if (maxRefundAmount < parseFloat(this.requestReturn.refundPartially)) {
          this.openNotify('error', {txtServer: this.$t('common_notificationMaxAmount.localization_value.value') + ' <b>' + maxRefundAmount + '$</b>'})
          return
        }
      }


      if (this.requestReturn.returnType === this.returnTypes.partially) {
        validationItems.amount = this.requestReturn.refundPartially.replace(',', '')
        validationOptions.amount = {type: ['empty', 'not-zero']}
      }

      let validate = validation(validationItems, validationOptions);
      this.validation = validate.validation;
      this.validationTranslate = validate.validationError;

      if (validate.isValidate) {
        this.serverError = false;

        let data = {
          "id": this.$route.params.id,
          "refund_type": this.requestReturn.returnType,
          "amount": this.requestReturn.returnType === this.returnTypes.partially ?
              this.requestReturn.refundPartially.replace(',', '') :
              this.$store.getters.getCusPaymentsItem.refundAmount,
          "in_draft": isDrafts ? 1 : 0,
          "withApi": this.requestReturn.withApi ? '0' : '1',
          "comment": this.requestReturn.comment
        }

        let prepareSocket = this.prepareCreatePrivateSocketChannel('RequestReturn')

        if (prepareSocket.active) {
          this.$store.commit('setRequestReturnSocket', true)
          this.createRequestReturnSocket(prepareSocket)
        }

        this.$store.dispatch('createRequestReturn', data).then((response) => {

          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.openNotify('success', 'common_notificationRecordCreated')
            this.$router.push(this.$store.getters.GET_PATHS.financeRequestReturn)

          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            this.serverError = true;

            if (Object.hasOwnProperty.call(errors, 'amount')) {
              this.openNotify('error', {txtServer: errors.amount[0]})
            }

            errors.comment ? (this.validation.comment = true, this.validationTxt.comment = errors.comment[0]) : false;
          }

        })

      }

    },

    // createReturns() {
    //   console.log(234);
    // },
  }
}
</script>

<style lang="scss">

.hide-upc {
  opacity: 0.5;
  pointer-events: none;
}

.product-progress-steps {
  max-width: 625px;
}

</style>
