<template>
  <div class="fragment">

    <template v-if="$store.getters.getProformCategory.length > 0 && $store.getters.getProformCategoryLoading !== true">
      <div class="site-table-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'proform_id',
          'proform_nameUa',
          'proform_nameRu',
          'proform_nameEn',
          'proform_sort',
          'common_manage',
        ])"></span>
        <div class="site-table-wrap products-table">
          <table class="site-table" v-if="!isMobileFunc()">
            <thead>
            <tr>
              <th>{{$t('proform_id.localization_value.value')}}</th>
              <th>{{$t('proform_nameUa.localization_value.value')}}</th>
              <th>{{$t('proform_nameRu.localization_value.value')}}</th>
              <th>{{$t('proform_nameEn.localization_value.value')}}</th>
              <th>{{$t('proform_sort.localization_value.value')}}</th>
              <th>{{$t('common_manage.localization_value.value')}}</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in $store.getters.getProformCategory"
                :key="index"
            >
              <td>
                <span class="table-link btn-style"  @click="editItem(item.id)">
                  #{{item.id}}
                </span>
              </td>
              <td>{{item.translationStorage.ua.name}}</td>

              <td>
                {{item.translationStorage.ru.name}}
              </td>
              <td>
                {{item.translationStorage.en.name}}
              </td>
              <td>
                {{item.sort_order}}
              </td>

              <td>
                <div class="table-right table-manage-list">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                    <template slot="item">
                      <LinkButton
                              :value="$t('common_edit.localization_value.value')"
                              :type="'edit'"
                              @click.native="editItem(item.id)"
                      />
                      <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removeRecord(item.id, index)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="table-card" v-else>
            <div class="table-card__list">
              <div class="table-card__item"
                   v-for="(item, index) in $store.getters.getProformCategory"
                   :key="index"
              >
                <ProformCategoryTableMobile
                  :item="item"
                  @editItem="editItem(item.id)"
                  @removeRecord="removeRecord(item.id, index)"
                />
              </div>
            </div>
          </div>

          <div class="table-bottom-btn" >
            <div class="table-bottom-btn__inner">
              <ShowMore
                      v-if="$store.getters.getProformCategoryCommonList.next_page_url !== null && $store.getters.getProformCategory.length > 0"
                      @click.native="$emit('showMore')"
                      v-bind:class="{'disabled-btn' : $store.getters.getNextProformCategoryPage}"
                      :count="$store.getters.getProformCategoryCommonList.total - $store.getters.getProformCategoryForPage < $store.getters.getProformCategoryForPage ?
                  $store.getters.getProformCategoryCommonList.total - $store.getters.getProformCategoryForPage:
                  $store.getters.getProformCategoryForPage"
              />
            </div>
          </div>
        </div>



    </div>
    </template>

    <template v-if="$store.getters.getProformCategoryLoading === false && $store.getters.getProformCategory.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <ProformCategoryPopup
            v-if="proformCategoryPopup"
            :type="'update'"
            @close="toggleProformCategoryPopup(false)"
            @update="$emit('updateTable')"
    />
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ProformCategoryPopup from "../../../../popups/ProformCategoryPopup/ProformCategoryPopup";
  import ProformCategoryTableMobile from "./ProformCategoryTableMobile/ProformCategoryTableMobile";


  export default {
    name: "ProformCategoryTable",

    components: {
      ShowMore,
      LinkButton,
      ManagerButton,
      NoResult,
      ProformCategoryPopup,
      ProformCategoryTableMobile,
    },

    mixins: [mixinDetictingMobile],

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        orderId: '',
        proformCategoryPopup: false,
      }
    },

    methods: {

      toggleProformCategoryPopup(val){
        this.proformCategoryPopup = val
      },

      editItem(id) {
        this.$store.dispatch('getProformCategory', id).then(() => {
          this.toggleProformCategoryPopup(true)
        })
      },

      removeRecord(id, index) {
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteProformCategory', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let records = this.$store.getters.getProformCategory
              records.splice(index, 1)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)

      },

    }

  }
</script>

<style lang="scss" scoped>
  .site-table tbody td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
</style>
