<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <vue-custom-scrollbar class="calc-ship__content-scrollbar site-custom-scroll-x" :settings="settings">
        <div class="content-tabs content-tabs--separator">
          <template
                  v-if="$store.getters.getProformCategory.length > 0 && $store.getters.getProformCategoryLoading !== true">
            <div class="content-tabs__item"
                 v-for="(item, index) in tabs"
                 :key="index"
                 v-bind:class="{active: item.active}"
                 @click="$emit('changeTab', index)"
            >
              {{ item.currentTranslate.name }}
            </div>
          </template>
        </div>
      </vue-custom-scrollbar>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter"/>
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
        <div
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['proform_import'])"></span>
          <label for="import" class="d-flex align-items-center">
            <input ref="import" id="import" type="file" @change="uploadImage" accept="xls/csv">
            <TableHeadButton
                    :value="$t('proform_import.localization_value.value')"
                    :ico="'import'"
            />
          </label>
        </div>

        <div class="likeTooltipBtn">
          <TableHeadButton
                  :value="''"
                  :ico="'question'"
                  class="likeTooltipBtn__item"
                  @click.native="downloadFiles"
          />
        </div>

      </div>
      <div class="btn-right-block">

        <!--<div-->
                <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
          <!--<span class="admin-edit" @click="editTranslate(['proform_exampelmport'])"></span>-->
          <!--<MainButton class="btn-fit-content ml-2"-->
                      <!--:value="$t('proform_exampelmport.localization_value.value')"-->
                      <!--@click.native="downloadFiles"-->
          <!--&gt;-->
          <!--</MainButton>-->
        <!--</div>-->
        <!--<label for="import"-->
                <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
          <!--<span class="admin-edit" @click="editTranslate(['proform_import'])"></span>-->
          <!--<input ref="import" id="import" type="file" @change="uploadImage" accept="xls/csv">-->
          <!--<MainButton class="btn-fit-content ml-2"-->
                      <!--:value="$t('proform_import.localization_value.value')"-->
                      <!--@click.native="$refs.import.click()"-->
          <!--&gt;-->
          <!--</MainButton>-->
        <!--</label>-->

        <template v-if="_.has($store.getters.getProformDataCommonList, 'proformTypes')">
          <template
                  v-for="(item, index) in $store.getters.getProformDataCommonList.proformTypes"
          >
            <MainButton class="btn-fit-content ml-2"
                        :key="index"
                        :value="item.currentTranslate.name"
                        :ico="'ico'"
                        @click.native="toggleProformDataPopup(true, item.id)"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus"/>
              </template>
            </MainButton>
          </template>

          <div
                  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['proform_createRow'])"></span>
            <MainButton class="btn-fit-content ml-2"
                        :value="$t('proform_createRow.localization_value.value')"
                        :ico="'ico'"
                        @click.native="toggleProformDataRowPopup(true)"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus"/>
              </template>
            </MainButton>
          </div>

        </template>

      </div>
    </div>

    <ProformDataPopup
            v-if="proformDataPopup"
            :type="'create'"
            :id="'' + proformDataPopupId"
            :categoryId="proformDataCategoryPopupId"
            @close="toggleProformDataPopup(false)"
            @update="$emit('updateTable')"
    />

    <ProformDataRowPopup
            v-if="proformDataRowPopup"
            :type="'create'"
            :categoryId="proformDataCategoryPopupId"
            @close="toggleProformDataRowPopup(false)"
            @update="$emit('updateTable')"
    />
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import TableLeftBlockBtn from "../../../../../../coreComponents/TableLeftBlockBtn/TableLeftBlockBtn";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import ProformDataPopup from "../../../../popups/ProformDataPopup/ProformDataPopup";
  import vueCustomScrollbar from 'vue-custom-scrollbar';
  import "vue-custom-scrollbar/dist/vueScrollbar.css"
  import ProformDataRowPopup from "../../../../popups/ProformDataRowPopup/ProformDataRowPopup";
  import TableHeadButton from "../../../../../../coreComponents/TableComponents/TableHeadButton/TableHeadButton";


  export default {
    name: "ProformDataHead",
    components: {
      MainButton,
      PlusIconSVG,
      // DefaultCheckbox,
      ToggleFilterButton,
      ProformDataPopup,
      ProformDataRowPopup,
      vueCustomScrollbar,
      TableHeadButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      tabs: Array,
    },


    watch: {},

    created() {

    },

    data() {
      return {
        settings: {
          suppressScrollY: true,
          suppressScrollX: false,
          wheelPropagation: false,
          useBothWheelAxes: true,
          wheelSpeed: 0.5,
          swipeEasing: true,
          scrollXMarginOffset: 16,
        },


        proformDataRowPopup: false,
        proformDataPopup: false,
        proformDataPopupId: '',
        proformDataCategoryPopupId: -1,
      }
    },


    methods: {

      toggleProformDataPopup(val, id = false) {
        this.proformDataPopup = val
        if (id)
          this.proformDataPopupId = id
        else
          this.proformDataPopupId = ''

        this.setCategoryId()
      },

      toggleProformDataRowPopup(val) {
        this.proformDataRowPopup = val
        this.setCategoryId()
      },

      setCategoryId() {
        this.tabs.map(item => {
          if (item.active)
            this.proformDataCategoryPopupId = item.id
        })
      },

      changeTab(val) {
        this.$emit('changeTab', val)
      },

      downloadFiles() {
        this.$store.dispatch('getProformExampleImportFile').then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: 'application/csv'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Proform Example.csv'
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },

      uploadImage(e){
        console.log(23423);
        const reader = new FormData();
        reader.append("import-file", e.target.files[0]);

        this.$store.dispatch('importProform', reader).then((response) => {

          if(response.status) {
            this.openNotify('success', 'common_notificationSuccessImport')
            this.$emit('updateTable')
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }

        })
      }

    }
  }
</script>

<style scoped>

  label[for="import"] >>> .content-top-line-btn{
    padding-bottom: 0;
  }

  .calc-ship__content-scrollbar >>> .ps__rail-x {
    bottom: 13px !important;
    height: 9px !important;
  }

  .calc-ship__content-scrollbar {
    width: calc(100% - 170px);
  }

  @media (max-width: 1150px) {
    .calc-ship__content-scrollbar {
      width: calc(100% - 60px);
    }
  }

  @media (max-width: 768px) {
    .calc-ship__content-scrollbar {
      width: 100%;
    }
  }

  .content-tabs {
    display: flex;
    width: initial;
    overflow-x: initial;
  }


  .content-tabs__item {
    position: relative;
    cursor: pointer;
    font-size: 15px;
    line-height: 21px;
    color: #BBAD9C;
    transition: 0.3s;
    min-width: fit-content;
    padding: 0 15px 14px;
    white-space: nowrap;
  }

  @media (max-width: 1150px) {
    .light-bg-block .btn-right-block {
      flex-wrap: wrap;
    }

    .light-bg-block .main-button{
      margin-bottom: 10px;
    }
  }

  .light-bg-block .main-button{
    font-size: 14px;
    padding: 0 9px;
  }

  .light-bg-block .main-button__ico .btn-icon-plus {
    width: 12px;
  }

  #import{
    display: none;
  }

  .likeTooltipBtn{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-25px, -15px);
  }

  .likeTooltipBtn__item{
    padding: 0;
    width: 20px;
    margin-top: -18px;
    margin-left: 4px;
  }

</style>

