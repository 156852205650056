<template>
  <modal
      @close="$emit('close')"
      class="custom-popup refound-queue"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'RequestReturn_RequestRefundsQueue',
        ])"></span>
        {{$t('RequestReturn_RequestRefundsQueue.localization_value.value')}}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="transaction-info__section"
             v-if="item = $store.getters.getRequestReturnItem"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'RequestReturn_RequestReturn',
            'requestReturn_IDNumber',
            'requestReturn_Status',
            'status_InDraft',
            'status_InProcess',
            'status_Error',
          ])"></span>
          <div class="transaction-info__section-title">
            {{$t('RequestReturn_RequestReturn.localization_value.value')}}
          </div>

          <div class="transaction-info__row" v-for="(item, index) in requests" :key="index">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('requestReturn_IDNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{item.id}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('requestReturn_Status.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <div class="d-flex align-items-center" v-if="item.status == 'in_draft'">
                  <StatusIcoBtn class="mr-1" :type="'in-progress'"/>{{$t('status_InDraft.localization_value.value')}}
                </div>
                <div class="d-flex align-items-center" v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS">
                  <StatusIcoBtn class="mr-1" :type="'active'"/>{{$t('status_Returned.localization_value.value')}}
                </div>
                <div class="d-flex align-items-center" v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS">
                  <StatusIcoBtn class="mr-1" :type="'in-transit'"/>{{$t('status_InProcess.localization_value.value')}}
                </div>
                <div class="d-flex align-items-center" v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS">
                  <StatusIcoBtn class="mr-1" :type="'cancel-red'"/>{{$t('status_Error.localization_value.value')}}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns">

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
                  v-if="done"
            >
              Cancel
            </span>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../staticData/staticVariables";
  import {requestReturnFunc} from "../../../../../mixins/requestReturnMixins/requestReturnFunc";
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {socketMixin} from "../../../../../mixins/socketMixins/socketMixin";
  // import MainButton from "../../../../UI/buttons/MainButton/MainButton";


  export default {
    name: "RequestManyRefundsPopup",
    components: {
      Modal,
      StatusIcoBtn,
      // MainButton,
    },

    mixins: [requestReturnFunc, socketMixin],

    props: {
      ids: Array,
    },

    data() {
      return {
        requests: [],
        done: false,
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES
      }
    },

    mounted() {
      let requests = this.ids
      let newRequests = []

      requests.map(item => {
        newRequests.push({
          id: item,
          status: 'in_draft'
        })
      })

      this.requests = newRequests

      let prepareSocket = this.prepareCreatePrivateSocketChannel('RequestReturn')
      if(prepareSocket.active) {
        console.log(prepareSocket);
        this.$store.commit('setRequestReturnSocket', true)
        window.Echo.private(`${prepareSocket.channel}.${prepareSocket.user}`)
            .listen(`.${prepareSocket.event}`, (response) => {

              console.log('open RequestReturnSocket');

              let index = this._.findKey(this.requests, {id: response.data.id + ''})
              this.requests[index].status = response.data.status

              let tableData = this.$store.getters.getRequestReturn
              let indexTableData = this._.findKey(tableData, {id: response.data.id})
              tableData[indexTableData].status = response.data.status
              tableData[indexTableData].in_draft = 0
            }).error(e => {
          console.log(e);
        })
      }

      this.requests.map((item) => {
        let data = {
          paymentTransactionRefundId: item.id
        }

        this.$store.dispatch('createDraftRequestReturn', data).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            item.status = CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS
          } else {
            setTimeout(() => {
              item.status = CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      })

      this.done = true
      // this.$emit('close')

      // Promise.all(this.requests.map((item) => {
      //   let data = {
      //     paymentTransactionRefundId: item.id
      //   }
      //
      //   this.$store.dispatch('createDraftRequestReturn', data).then((response) => {
      //     if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
      //       item.status = CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS
      //     } else {
      //       setTimeout(() => {
      //         item.status = CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS
      //         this.openNotify('error', 'common_notificationUndefinedError')
      //       }, 200)
      //     }
      //   })
      //
      // })).then(() => {
      //   this.done = true
      //   this.$emit('refresh')
      // })
    }
  }

</script>

<style scoped>
  .scoped-width{
    max-width: 120px;
  }

  .scoped-btns{
    display: flex;
    align-items: center;
  }

  .refound-queue .btn-close modal-component__close{
    display: none;
  }
</style>
