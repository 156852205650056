<template>
  <FillClaimCreationSection
          :FC="FC"
          :createData="createData"
          :adminOptions="adminOptions"
          @save="save"
  />
</template>

<script>
  import FillClaimCreationSection from "./FillClaimCreationSection/FillClaimCreationSection.vue";
  import {FillClaim} from "../../models/FillClaim";
  import {fillClaimMixin} from "../../../../../mixins/fillClaimMixins/fillClaimMixin";

  export default {
    name: "FillClaimCreation",

    components:{
      FillClaimCreationSection,
    },

    mixins: [fillClaimMixin],

    watch: {
      loadUserAuthorizedData: function () {
        this.initFillClaim()
      },
    },

    data() {
      return {
        FC: new FillClaim(),
        createData: null,
        adminOptions: [],
      }
    },

    mounted() {
      this.initFillClaimCreate()

      if(this.loadUserAuthorizedData){
        this.initFillClaim()
      }
    },

    methods: {

      initFillClaim() {
        if(this.isAdmin){
          this.getAdminsInFilter()
          this.FC.data.AdminUser.setUser(this.$store.getters.getUserProfile)
        }

        if (!this.isAdmin) {
          this.FC.data.User.setUser(this.loadUserAuthorizedData?.user)
        }
      },

      initFillClaimCreate() {
        this.$store.dispatch('getFillClaimCreate').then(response => {
          this.createData = this.getRespData(response)
        })
      },

      getAdminsInFilter() {
        this.$store.dispatch('fetchAdminUsersFromFilter', '').then(response => {
          this.adminOptions = this.getRespData(response)
        })
      },

    }

  }
</script>

<style scoped>

</style>
