<template>

  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number"
             @click="$emit('showTransactionInfo', item.transaction_id, item.payment_system)"
        >
          #{{item.id}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('multiSearch_transaction.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_COMPLAINT_TRANSACTION.value"
                  class="table-link btn-style"
                  :value="item.id"
                  :link="$store.getters.GET_PATHS.financePayPalCases + '/?complaintId=' + item.id"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_PAYMENT_TRANSACTION.value && item.status !== 'undefined'"
                  class="table-link btn-style"
                  :value="item.id"
                  :link="$store.getters.GET_PATHS.cusPayments + '/?transactionNumber=' + item.transaction_number +
                  (item.payment_system === PAYMENT_TYPE_NAME.authorize ? '&type=' + PAYMENT_TYPE_NAME.authorize : '')"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_PAYMENT_TRANSACTION.value && item.status === 'undefined'"
                  class="table-link btn-style"
                  :value="item.id"
                  :link="$store.getters.GET_PATHS.financeUnidentifiedPayments + '/?trackingNumber=' + item.transaction_number +
                  (item.payment_system === PAYMENT_TYPE_NAME.authorize ? '&type=' + PAYMENT_TYPE_NAME.authorize : '')"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_BALANCE_OUTPUT_TRANSACTION.value"
                  class="table-link btn-style"
                  :value="item.id"
                  :link="$store.getters.GET_PATHS.financeRequestFunds + '/?id=' + item.id"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_REFUND_TRANSACTION.value"
                  class="table-link btn-style"
                  :value="item.id"
                  :link="$store.getters.GET_PATHS.financeRequestReturn + '/?refundId=' + item.id"
              />
              <LinkButton
                  v-if="item.section === MULTISEARCH_TRANSACTION_TYPES.MULTISEARCH_FORBIDDEN_PAYMENT_TRANSACTION.value"
                  class="table-link btn-style"
                  :value="item.id"
                  :link="$store.getters.GET_PATHS.forbiddenGoods + '/?transactionNumber=' + item.transaction_number"
              />
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('multiSearch_Sum.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div class="table-card__item-info d-flex align-items-center">
                  <ValueHelper
                      :value="item.payment_total"
                      :replaceValue="'—'"
                  />
              </div>
            </div>
          </div>
        </div>

      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {fillClaimMixin} from "../../../../../../../../mixins/fillClaimMixins/fillClaimMixin";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {MULTISEARCH_TRANSACTION_TYPES, PAYMENT_TYPE_NAME} from "../../../../../../../../staticData/staticVariables";

  export default {
    name: "MultiSearchMobileTable",
    components: {TableUserColumn, ValueHelper, LinkButton},

    mixins: [fillClaimMixin],

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,

        MULTISEARCH_TRANSACTION_TYPES: MULTISEARCH_TRANSACTION_TYPES,
        PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,
      }
    }

  }
</script>

<style scoped>

</style>
