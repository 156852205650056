<template>
  <div class="fragment">
    <template v-if="!isAdmin && !isEditing">
      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_comments'])"></div>
        {{ $t('fillClaim_Comment.localization_value.value') }}
      </div>
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>
          <TextareaDefault
                  :label="$t('common_comment.localization_value.value')"
                  v-bind:class="{'ui-no-valid': FC.validation.comment}"
                  :error="FC.validation.comment"
                  :errorTxt="$t(`${FC.validationTranslate.comment}.localization_value.value`)"
                  v-model="FC.data.comment"
          />
        </div>
      </div>
    </template>



    <template v-if="isAdmin">
      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_CommentAdmin'])"></div>
        {{ $t('fillClaim_CommentAdmin.localization_value.value') }}
      </div>
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fillClaim_CommentAdmin'])"></div>
          <TextareaDefault
                  :label="$t('fillClaim_CommentAdmin.localization_value.value')"
                  v-bind:class="{'ui-no-valid': FC.validation.commentAdmin}"
                  :errorTxt="$t(`${FC.validationTranslate.commentAdmin}.localization_value.value`)"
                  :error="FC.validation.commentAdmin"
                  v-model="FC.data.commentAdmin"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";

  export default {
    name: "CommentsBlock",
    components: {
      TextareaDefault,
    },
    props: {
      FC: Object,
      isEditing: {
        type: Boolean,
        default: false
      },
    },
  }
</script>

<style scoped>

</style>
