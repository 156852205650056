<template>
  <div class="table-filter-wrap">

    <div class="preloader-table-wrap" v-if="$store.getters.getSearchLoading === true">
      <DotsShadowPreloader/>
    </div>
    <template
            v-if="$store.getters.getSearchLoading !== true && getItemsArray().length > 0">
      <div class="site-table-wrap products-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'search_Type',
            'search_trackNumber',
            'search_deliveryService',
            'common_user',
            'search_consolidation',
            'search_express',
            'search_ordersFBA',
            'search_createOrders',
            'search_shipmentToWarehouse',
          ])"></div>
<!--        v-if="!isMobileFunc()"-->
        <table class="site-table" >
          <thead>
          <tr>
            <th>Id</th>
            <th>{{ $t('search_Type.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{ $t('common_user.localization_value.value')}}</th>
            <th>{{ $t('search_trackNumber.localization_value.value') }}</th>
            <th>{{ $t('search_deliveryService.localization_value.value') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in getItemsArray()" :key="index">
            <td>
              {{item.object.id}}
            </td>
            <td>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_CONSOLIDATION">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_consolidation',
                ])"></div>
                  {{ $t('search_consolidation.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_EXPRESS">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_express',
                ])"></div>
                  {{ $t('search_express.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBA">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_ordersFBA',
                ])"></div>
                  {{ $t('search_ordersFBA.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBM">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_createOrders',
                ])"></div>
                  {{ $t('search_createOrders.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INBOUND">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_shipmentToWarehouse',
                ])"></div>
                  {{ $t('search_shipmentToWarehouse.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INTERNAL">
                {{item.delivery_service.name}}
              </template>
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="item.user"
                  :item="item"
              />
              <template v-else>—</template>
            </td>
            <td>
              {{item.object.tracking_number}}
              <div>
                <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBM">
                  <div class="d-flex align-items-end" v-if="item.object.expressposhta_tracking_number">
                    {{item.object.expressposhta_tracking_number}}
                    <span>
                      <v-popover class="site-tooltip" offset="5" placement="top" trigger="hover">
                        <TooltipBtn/>
                        <template slot="popover">
                          <p><b> {{ $t('search_ExpressposhtaTracking.localization_value.value') }}</b></p>
                          <a v-close-popover class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </span>
                  </div>
                  <div class="d-flex align-items-end" v-if="item.object.system_tracking_number">
                    {{item.object.system_tracking_number}}
                    <span>
                      <v-popover class="site-tooltip" offset="5" placement="top" trigger="hover">
                        <TooltipBtn/>
                        <template slot="popover">
                          <p><b>{{ $t('search_SystemTracking.localization_value.value') }}</b></p>
                          <a v-close-popover class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </span>
                  </div>
                </template>
              </div>
            </td>
            <td>
              {{item.delivery_service.name}}
            </td>
            <td>
              <div class="table-right table-manage-list">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton">

                  <template slot="item">
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_CONSOLIDATION">
                      <LinkButton
                          :value="$t('search_show.localization_value.value')"
                          :type="'show'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
                          getConsolidationTypeByDestinationId(item.object.consolidation_destination_id) + '/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_EXPRESS">
                      <LinkButton
                          :value="$t('search_show.localization_value.value')"
                          :type="'show'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.expressOrderShow + '/' +
                          getExpressTypeById(item.delivery_service.id).name + '/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBA">
                      <LinkButton
                          :value="$t('search_show.localization_value.value')"
                          :type="'show'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersFBA + '/show/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBM">
                      <LinkButton
                          :value="$t('search_show.localization_value.value')"
                          :type="'show'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.object.type).name + '/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INBOUND">
                      <LinkButton
                          :value="$t('search_show.localization_value.value')"
                          :type="'show'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                          getOrderType(item.object.type, item.object.return).value + '/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INTERNAL">

                      <template v-if="item.delivery_service.id === UKRPOSHTA_DELIVERY_SERVICE.id">
                        <LinkButton
                            :value="$t('search_show.localization_value.value')"
                            :type="'show'"
                            :target="'_blank'"
                            :link="$store.getters.GET_PATHS.ukrposhta + '/show/' + item.object.id"
                        />
                      </template>
<!--                      <template v-if="item.delivery_service.id === BELPOCHTA_DELIVERY_SERVICE.id">-->
<!--                        <LinkButton-->
<!--                            :value="$t('search_show.localization_value.value')"-->
<!--                            :type="'show'"-->
<!--                            :target="'_blank'"-->
<!--                            :link="$store.getters.GET_PATHS.belposhta + '/show/' + item.object.id"-->
<!--                        />-->
<!--                      </template>-->
                      <template v-if="item.delivery_service.id === CDEK_DELIVERY_SERVICE.id">
                        <LinkButton
                            :value="$t('search_show.localization_value.value')"
                            :type="'show'"
                            :target="'_blank'"
                            :link="$store.getters.GET_PATHS.cdek + '/show/' + item.object.id"
                        />
                      </template>
                      <template v-if="item.delivery_service.id === NOVAPOSHTA_DELIVERY_SERVICE.id">
                        <LinkButton
                            :value="$t('search_show.localization_value.value')"
                            :type="'show'"
                            :target="'_blank'"
                            :link="$store.getters.GET_PATHS.novaPoshta + '/show/' + item.object.id"
                        />
                      </template>

                    </template>
                  </template>

                  <template slot="item">
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_CONSOLIDATION">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersConsolidationEditing + '/' +
                          getConsolidationTypeByDestinationId(item.object.consolidation_destination_id) + '/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_EXPRESS">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.expressOrderEdit + '/' +
                          getExpressTypeById(item.delivery_service.id).name + '/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBA">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersFBA + '/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBM">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.object.type).name + '/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INBOUND">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                          getOrderType(item.object.type, item.object.return).value + '/' + item.object.id"
                      />
                    </template>
                    <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INTERNAL">

                      <template v-if="item.delivery_service.id === UKRPOSHTA_DELIVERY_SERVICE.id">
                        <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :target="'_blank'"
                            :link="$store.getters.GET_PATHS.ukrposhta + '/' + item.object.id"
                        />
                      </template>
<!--                      <template v-if="item.delivery_service.id === BELPOCHTA_DELIVERY_SERVICE.id">-->
<!--                        <LinkButton-->
<!--                            :value="$t('common_edit.localization_value.value')"-->
<!--                            :type="'edit'"-->
<!--                            :target="'_blank'"-->
<!--                            :link="$store.getters.GET_PATHS.belposhta + '/' + item.object.id"-->
<!--                        />-->
<!--                      </template>-->
                      <template v-if="item.delivery_service.id === CDEK_DELIVERY_SERVICE.id">
                        <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :target="'_blank'"
                            :link="$store.getters.GET_PATHS.cdek + '/' + item.object.id"
                        />
                      </template>
                      <template v-if="item.delivery_service.id === NOVAPOSHTA_DELIVERY_SERVICE.id">
                        <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :target="'_blank'"
                            :link="$store.getters.GET_PATHS.novaPoshta + '/' + item.object.id"
                        />
                      </template>

                    </template>
                  </template>

                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc() && false">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in getItemsArray()"
                 :key="index"
            >
              <SearchMobileTable
                      :item="item"
                      @showTransactionInfo="showTransactionInfo"
              />
            </div>
          </div>
        </div>

      </div>
    </template>

    <template
            v-if="$store.getters.getSearchLoading === false && getItemsArray().length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
  // import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import SearchMobileTable from "./SearchMobileTable/SearchMobileTable";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import DotsShadowPreloader from "../../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FBMMixinsHelper} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import {expressMixins} from "../../../../../../../mixins/expressMixins/expressMixin";
  import {consolidationMixin} from "../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import {ordersInboundMixin} from "../../../../../../../mixins/ordersInbound/ordersInboundMixin";
  import {
    SEARCH_TRACK_SECTIONS,
    UKRPOSHTA_DELIVERY_SERVICE,
    BELPOCHTA_DELIVERY_SERVICE,
    CDEK_DELIVERY_SERVICE,
    NOVAPOSHTA_DELIVERY_SERVICE,
  } from "../../../../../../../staticData/staticVariables";
  import {VPopover} from 'v-tooltip'
  import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";


  export default {
    name: "SearchTable",

    components: {
      LinkButton,
      ManagerButton,
      DotsShadowPreloader,
      TableUserColumn,
      SearchMobileTable,
      NoResult,
      VPopover,
      TooltipBtn,
      // ShowMore,
    },

    mixins: [
      mixinDetictingMobile,
      FBMMixinsHelper,
      expressMixins,
      consolidationMixin,
      ordersInboundMixin
    ],

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        itemId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,
        isModalTransactionInfoAdmin: false,

        items: [],

        SEARCH_TRACK_SECTIONS: SEARCH_TRACK_SECTIONS,
        UKRPOSHTA_DELIVERY_SERVICE: UKRPOSHTA_DELIVERY_SERVICE,
        BELPOCHTA_DELIVERY_SERVICE: BELPOCHTA_DELIVERY_SERVICE,
        CDEK_DELIVERY_SERVICE: CDEK_DELIVERY_SERVICE,
        NOVAPOSHTA_DELIVERY_SERVICE: NOVAPOSHTA_DELIVERY_SERVICE,
      }
    },

    methods: {
      showTransactionInfo(id, type) {
        this.$store.dispatch('getCusPayments', id).then(() => {
          this.isModalTransactionInfoAdmin = true
          this.transactionType = type
        })
      },

      showTransactionInfoAdmin() {
        this.isModalTransactionInfoAdmin = true
      },

      closeTransactionInfoAdmin() {
        this.isModalTransactionInfoAdmin = false
      },

      getItemsArray() {
        let items = []

        Object.keys(this.$store.getters.getSearch).forEach(key => {
          let addedItems = this.$store.getters.getSearch[key]
          addedItems = addedItems.map(item => {
            item['type'] = key
            return item
          })

          items = [...items, ...addedItems]
        })

        return items
      },
    }

  }
</script>

<style lang="scss" scoped>
  @import "../../../../../../../scss/mixins/mixins";

  .products-table .table-manage-list .manager-btn {
    width: 165px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis {
    max-width: 320px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }


  @include from-1800 {
    th:last-child,
    td:last-child {
      padding-left: 50px !important;
      padding-right: 20px !important;
    }
  }

  @include for-1200 {
    .products-table .ellipsis {
      max-width: 150px;
    }
    .products-table .table-manage-list .manager-btn {
      width: 70px;
    }
  }

  .table-card__item-number {
    margin-bottom: 10px;
  }

  .preloader-table-wrap {
    padding-top: 50px;
  }

  .table-overflow {
    overflow: auto;
  }

</style>
