import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../globalModels/Files";
import {User} from "../../../globalModels/User";
import {DHL_DELIVERY_SERVICE, FEDEX_DELIVERY_SERVICE} from "../../../../staticData/staticVariables";
const _ = require('lodash');


export function FillClaim() {

  this.checkValid = checkValid

  this.data = {
    User: new User(),
    AdminUser: new User(),
    id: '',
    createdAt: '',
    trackingNumber: '',
    claimReasons: null,
    resultStatus: null,
    status: null,
    shippingDate: null,
    closeDate: null,
    declaredValue: null,
    compensationValue: null,
    deliverySystem: '',
    deliveryType: '',
    comment: '',
    commentAdmin: '',
    claimMessages: [],

    UserFiles: new Files({
      maxCountFiles: 15,
      maxSizeFiles: 3
    }),

    AdminFiles: new Files({
      maxCountFiles: 15,
      maxSizeFiles: 3
    }),

    message: '',
    historyOfChanged: null,

    // User: new User(),
  }


  this.validation = {
    id: false,
    trackingNumber: false,
    claimReasons: false,
    resultStatus: false,
    status: false,
    shippingDate: false,
    closeDate: false,
    declaredValue: false,
    compensationValue: false,
    deliverySystem: false,
    deliveryType: false,
    comment: false,
    commentAdmin: false,
    claimMessages: false,
    files: false,

    message: false,
  }

  this.validationTranslate = {
    id: '',
    trackingNumber: '',
    claimReasons: '',
    resultStatus: '',
    status: '',
    shippingDate: '',
    closeDate: '',
    declaredValue: '',
    compensationValue: '',
    deliverySystem: '',
    deliveryType: '',
    comment: '',
    commentAdmin: '',
    claimMessages: '',
    files: '',

    message: '',
  }

  this.validationTxt = {
    id: false,
    trackingNumber: false,
    claimReasons: false,
    resultStatus: false,
    status: false,
    shippingDate: false,
    closeDate: false,
    declaredValue: false,
    compensationValue: false,
    deliverySystem: false,
    deliveryType: false,
    comment: false,
    commentAdmin: false,
    claimMessages: false,
    files: false,

    message: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getCreatedAt = () => {
    return this.data.createdAt
  }
  this.getTrackingNumber = () => {
    return this.data.trackingNumber
  }
  this.getClaimReasons = () => {
    return this.data.claimReasons
  }
  this.getResultStatus = () => {
    return this.data.resultStatus
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getShippingDate = () => {
    return this.data.shippingDate
  }
  this.getCloseDate = () => {
    return this.data.closeDate
  }
  this.getDeclaredValue = () => {
    return this.data.declaredValue
  }
  this.getCompensationValue = () => {
    return this.data.compensationValue
  }
  this.getDeliverySystem = () => {
    return this.data.deliverySystem
  }
  this.getDeliveryType = () => {
    return this.data.deliveryType
  }
  this.getComment = () => {
    return this.data.comment
  }
  this.getCommentAdmin = () => {
    return this.data.commentAdmin
  }
  this.getClaimMessages = () => {
    return this.data.claimMessages
  }
  this.getMessage = () => {
    return this.data.message
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setCreatedAt = (val) => {
    this.data.createdAt = val
  }
  this.setTrackingNumber = (val) => {
    this.data.trackingNumber = val
  }
  this.setClaimReasons = (val) => {
    this.data.claimReasons = val
  }
  this.setResultStatus = (val) => {
    this.data.resultStatus = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setShippingDate = (val) => {
    this.data.shippingDate = val
  }
  this.setCloseDate = (val) => {
    this.data.closeDate = val
  }
  this.setDeclaredValue = (val) => {
    this.data.declaredValue = val
  }
  this.setCompensationValue = (val) => {
    this.data.compensationValue = val
  }
  this.setDeliverySystem = (val) => {
    this.data.deliverySystem = val
  }
  this.setDeliveryType = (val) => {
    this.data.deliveryType = val
  }
  this.setComment = (val) => {
    this.data.comment = val
  }
  this.setCommentAdmin = (val) => {
    this.data.commentAdmin = val
  }
  this.setClaimMessages = (val) => {
    this.data.claimMessages = val
  }

  this.addMessages = (val) => {
    this.data.claimMessages.push(val)
  }

  this.setMessage = (val) => {
    this.data.message = val
  }
  this.setHistoryOfChanged = (val) => {
    this.data.historyOfChanged = val
  }

}

/**
 * Global Setters
 */

FillClaim.prototype.setItem = function(item, parseDataForSelectUse) {
  let arrayReasons = parseDataForSelectUse({data: item?.reasons, name: 'name', value: 'value'})
  let arrayStatuses = parseDataForSelectUse({data: item?.statuses, name: 'name', value: 'value'})
  let arrayStatusResults = parseDataForSelectUse({data: item?.status_results, name: 'name', value: 'value'})

  this.data.User.setUser(item?.user)
  if(item?.admin){
    this.data.AdminUser.setUser(item?.admin)
  }
  // else if(that.isAdmin){
  //   this.data.AdminUser.setUser(that.$store.getters.getUserProfile)
  // }

  this.setId(item?.id)
  this.setCreatedAt(item?.created_at)
  this.setTrackingNumber(item?.tracking_number)
  this.setClaimReasons(_.find(arrayReasons, {value: item.reason}))
  this.setResultStatus(_.find(arrayStatusResults, {value: item.status_result}))
  this.setStatus(_.find(arrayStatuses, {value: item.status}))
  this.setShippingDate(item?.shipping_date)
  this.setCloseDate(item?.close_date)
  this.setDeclaredValue(item?.declared_value.toFixed(2))
  this.setCompensationValue(item?.compensation_value)
  this.setDeliverySystem(item?.delivery_service_id)
  if (item?.is_express === 1) {
    this.setDeliveryType('express')
  }
  if (item?.is_from_usa === 1) {
    this.setDeliveryType('from_usa')
  }
  this.setComment(item.comment ? item.comment : '')
  this.setCommentAdmin(item.admin_comment ? item.admin_comment : '')
  this.setClaimMessages(item.messages)

  this.setHistoryOfChanged(item.activityUUID)

  // if(val?.user)
  //   this.data.User.setUser(val.user)
}


/**
 * Prepare Data
 */

FillClaim.prototype.prepareSaveUser = function() {
  let data = {
    'user_id': this.data.User.getUserId(),
    'delivery_service_id': this.getDeliverySystem(),
    'reason': this.getClaimReasons()?.value,
    'status': this.getStatus()?.value,
    'status_result': this.getResultStatus(),
    'shipping_date': this.getShippingDate(),
    'tracking_number': this.getTrackingNumber(),
    'declared_value': this.getDeclaredValue(),
    'comment': this.getComment(),
    'is_express': false,
    'is_from_usa': false,
  }

  if (this.getDeliverySystem() === FEDEX_DELIVERY_SERVICE.id || this.getDeliverySystem() === DHL_DELIVERY_SERVICE.id) {
    data['is_express'] = this.getDeliveryType() === 'express'
    data['is_from_usa'] = this.getDeliveryType() === 'from_usa'
  }

  return data
}

FillClaim.prototype.prepareSaveAdmin = function() {
  console.log(this.data.AdminUser.getUserId());
  let data = {
    'user_id': this.data.User.getUserId(),
    'admin_user_id': this.data.AdminUser.getUserId(),
    'delivery_service_id': this.getDeliverySystem(),
    'reason': this.getClaimReasons()?.value,
    'status': this.getStatus()?.value,
    'status_result': this.getResultStatus()?.value,
    'shipping_date': this.getShippingDate(),
    'tracking_number': this.getTrackingNumber(),
    'declared_value': this.getDeclaredValue(),
    'close_date': this.getCloseDate(),
    'compensation_value': this.getCompensationValue(),
    'comment': this.getComment(),
    'admin_comment': this.getCommentAdmin(),
    'is_express': false,
    'is_from_usa': false,
  }

  if (this.getDeliverySystem() === FEDEX_DELIVERY_SERVICE.id || this.getDeliverySystem() === DHL_DELIVERY_SERVICE.id) {
    data['is_express'] = this.getDeliveryType() === 'express'
    data['is_from_usa'] = this.getDeliveryType() === 'from_usa'
  }

  return data
}

FillClaim.prototype.prepareFilesData = function() {
  let readerArray = []

  this.data.UserFiles.getFiles().map(item => {
    let reader = new FormData();
    reader.append("file", item.file);
    readerArray.push(reader)
  })
  return readerArray
}

FillClaim.prototype.prepareAdminFilesData = function() {
  let readerArray = []

  this.data.AdminFiles.getFiles().map(item => {
    let reader = new FormData();
    reader.append("file", item.file);
    readerArray.push(reader)
  })
  return readerArray
}


/**
 * Validations
 */

FillClaim.prototype.firstValidationUser = function() {

  let validationItems = {
    trackingNumber: this.getTrackingNumber(),
    claimReasons: this.getClaimReasons(),
    shippingDate: this.getShippingDate(),
    declaredValue: this.getDeclaredValue(),
    deliverySystem: this.getDeliverySystem(),
    files: this.data.UserFiles.data.files.length > 0 ? this.data.UserFiles.data.files : this.data.UserFiles.data.downloadFiles
  }

  let validationOptions = {
    trackingNumber: {type: ['empty']},
    claimReasons: {type: ['empty']},
    shippingDate: {type: ['empty']},
    declaredValue: {type: ['numeric','not-zero','empty']},
    deliverySystem: {type: ['empty']},
    files: {type: ['file']},
  }


  return (this.checkValid(validationItems, validationOptions) && this.data.UserFiles.fileSizeValidation())
}

FillClaim.prototype.messageValidate = function() {

  let validationItems = {
    message: this.getMessage(),
  }

  let validationOptions = {
    message: {type: ['empty']},
  }


  return (this.checkValid(validationItems, validationOptions))
}

FillClaim.prototype.firstValidationAdmin = function() {

  let validationItems = {
    trackingNumber: this.getTrackingNumber(),
    claimReasons: this.getClaimReasons(),
    shippingDate: this.getShippingDate(),
    declaredValue: this.getDeclaredValue(),
    deliverySystem: this.getDeliverySystem(),
    // comment: this.getComment(),

    // status: this.getStatus(),
    // resultStatus: this.getResultStatus(),
    // closeDate: this.getCloseDate(),
    // compensationValue: this.getCompensationValue(),
    // commentAdmin: this.getCommentAdmin(),
    // claimMessages: this.getClaimMessages(),
  }

  let validationOptions = {
    trackingNumber: {type: ['empty']},
    claimReasons: {type: ['empty']},
    shippingDate: {type: ['empty']},
    declaredValue: {type: ['empty']},
    deliverySystem: {type: ['empty']},
    // comment: {type: ['empty']},

    // status: {type: ['empty', 'not-zero']},
    // trackingNumber: {type: ['empty']},
    // claimReasons: {type: ['empty']},
    // resultStatus: {type: ['empty', 'not-zero']},
    // status: {type: ['empty']},
    // shippingDate: {type: ['empty']},
    // closeDate: {type: ['empty']},
    // declaredValue: {type: ['empty']},
    // compensationValue: {type: ['empty']},
    // deliverySystem: {type: ['empty']},
    // comment: {type: ['empty']},
    // commentAdmin: {type: ['empty']},
    // claimMessages: {type: ['empty']},
  }

  if(this.getStatus()?.value === 'close') {
    validationItems['closeDate'] = this.getCloseDate()
    validationOptions['closeDate'] = {type: ['empty']}
  }

  if(!this.data.User.userValidation({userId: true}))
    return false

  if(!this.data.AdminUser.userValidation({userId: true}))
    return false

  return (this.checkValid(validationItems, validationOptions) && this.data.AdminFiles.fileSizeValidation())
}
