import {requestReturnSocketMixin} from "../socketMixins/socketMixin";

export const requestReturnFunc = {

  mixins: [requestReturnSocketMixin],

  methods: {
    requestReturn(id, index) {
      let text = {
        title: 'RequestReturn_RequestReturnsQuest',
        txt: '',
        yes: 'common_confirm',
        no: 'common_confirmNo'
      }

      let confirm = () => {
        let data = {
          paymentTransactionRefundId: id
        }

        let prepareSocket = this.prepareCreatePrivateSocketChannel('RequestReturn')

        if(prepareSocket.active) {
          this.$store.commit('setRequestReturnSocket', true)
          this.createRequestReturnSocket(prepareSocket)
        }

        this.$store.dispatch('createDraftRequestReturn', data).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            let requestReturn = this.$store.getters.getRequestReturn
            if(isFinite(index)){
              requestReturn[index].in_draft = 0
              requestReturn[index].status = 'on_hold'
            }
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)

    }
  }
}
