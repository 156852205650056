<template>

  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number"
        >
          #{{item.object.id}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('multiSearch_Type.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_CONSOLIDATION">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_consolidation',
                ])"></div>
                  {{ $t('search_consolidation.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_EXPRESS">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_express',
                ])"></div>
                  {{ $t('search_express.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBA">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_ordersFBA',
                ])"></div>
                  {{ $t('search_ordersFBA.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBM">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_createOrders',
                ])"></div>
                  {{ $t('search_createOrders.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INBOUND">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'search_shipmentToWarehouse',
                ])"></div>
                  {{ $t('search_shipmentToWarehouse.localization_value.value') }}
                </div>
              </template>
              <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INTERNAL">
                {{item.delivery_service.name}}
              </template>
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('search_trackNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.object.tracking_number}}
              <div>
                <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBM">
                  <div class="d-flex align-items-end" v-if="item.object.expressposhta_tracking_number">
                    {{item.object.expressposhta_tracking_number}}
                    <span>
                      <v-popover class="site-tooltip" :disabled="!tooltipAddProductActive" offset="5" placement="top" trigger="hover">
                        <TooltipBtn/>
                        <template slot="popover">
                          <p><b> {{ $t('search_ExpressposhtaTracking.localization_value.value') }}</b></p>
                          <a v-close-popover class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </span>
                  </div>
                  <div class="d-flex align-items-end" v-if="item.object.system_tracking_number">
                    {{item.object.system_tracking_number}}
                    <span>
                      <v-popover class="site-tooltip" :disabled="!tooltipAddProductActive" offset="5" placement="top" trigger="hover">
                        <TooltipBtn/>
                        <template slot="popover">
                          <p><b>{{ $t('search_SystemTracking.localization_value.value') }}</b></p>
                          <a v-close-popover class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{ $t('search_deliveryService.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div class="table-card__item-info d-flex align-items-center">
                {{item.delivery_service.name}}
              </div>
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_CONSOLIDATION">
              <LinkButton
                  :value="$t('search_show.localization_value.value')"
                  :type="'show'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
                          getConsolidationTypeById(item.delivery_service.id).name + '/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_EXPRESS">
              <LinkButton
                  :value="$t('search_show.localization_value.value')"
                  :type="'show'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.expressOrderShow + '/' +
                          getConsolidationTypeById(item.delivery_service.id).name + '/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBA">
              <LinkButton
                  :value="$t('search_show.localization_value.value')"
                  :type="'show'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.ordersFBA + '/show/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBM">
              <LinkButton
                  :value="$t('search_show.localization_value.value')"
                  :type="'show'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.object.type).name + '/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INBOUND">
              <LinkButton
                  :value="$t('search_show.localization_value.value')"
                  :type="'show'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                          getOrderType(item.object.type, item.object.return).value + '/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INTERNAL">

              <template v-if="item.delivery_service.id === UKRPOSHTA_DELIVERY_SERVICE.id">
                <LinkButton
                    :value="$t('search_show.localization_value.value')"
                    :type="'show'"
                    :target="'_blank'"
                    :link="$store.getters.GET_PATHS.ukrposhta + '/show/' + item.object.id"
                />
              </template>
<!--              <template v-if="item.delivery_service.id === BELPOCHTA_DELIVERY_SERVICE.id">-->
<!--                <LinkButton-->
<!--                    :value="$t('search_show.localization_value.value')"-->
<!--                    :type="'show'"-->
<!--                    :target="'_blank'"-->
<!--                    :link="$store.getters.GET_PATHS.belposhta + '/show/' + item.object.id"-->
<!--                />-->
<!--              </template>-->
              <template v-if="item.delivery_service.id === CDEK_DELIVERY_SERVICE.id">
                <LinkButton
                    :value="$t('search_show.localization_value.value')"
                    :type="'show'"
                    :target="'_blank'"
                    :link="$store.getters.GET_PATHS.cdek + '/show/' + item.object.id"
                />
              </template>
              <template v-if="item.delivery_service.id === NOVAPOSHTA_DELIVERY_SERVICE.id">
                <LinkButton
                    :value="$t('search_show.localization_value.value')"
                    :type="'show'"
                    :target="'_blank'"
                    :link="$store.getters.GET_PATHS.novaPoshta + '/show/' + item.object.id"
                />
              </template>

            </template>
          </div>
          <div class="table-card__item-btn-i">
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_CONSOLIDATION">
              <LinkButton
                  :value="$t('common_edit.localization_value.value')"
                  :type="'edit'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.ordersConsolidationEditing + '/' +
                          getConsolidationTypeById(item.delivery_service.id).name + '/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_EXPRESS">
              <LinkButton
                  :value="$t('common_edit.localization_value.value')"
                  :type="'edit'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.expressOrderEdit + '/' +
                          getExpressTypeById(item.delivery_service.id).name + '/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBA">
              <LinkButton
                  :value="$t('common_edit.localization_value.value')"
                  :type="'edit'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.ordersFBA + '/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_FBM">
              <LinkButton
                  :value="$t('common_edit.localization_value.value')"
                  :type="'edit'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.object.type).name + '/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INBOUND">
              <LinkButton
                  :value="$t('common_edit.localization_value.value')"
                  :type="'edit'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                          getOrderType(item.object.type, item.object.return).value + '/' + item.object.id"
              />
            </template>
            <template v-if="item.type === SEARCH_TRACK_SECTIONS.SEARCH_TRACK_SECTION_INTERNAL">

              <template v-if="item.delivery_service.id === UKRPOSHTA_DELIVERY_SERVICE.id">
                <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :target="'_blank'"
                    :link="$store.getters.GET_PATHS.ukrposhta + '/' + item.object.id"
                />
              </template>
<!--              <template v-if="item.delivery_service.id === BELPOCHTA_DELIVERY_SERVICE.id">-->
<!--                <LinkButton-->
<!--                    :value="$t('common_edit.localization_value.value')"-->
<!--                    :type="'edit'"-->
<!--                    :target="'_blank'"-->
<!--                    :link="$store.getters.GET_PATHS.belposhta + '/' + item.object.id"-->
<!--                />-->
<!--              </template>-->
              <template v-if="item.delivery_service.id === CDEK_DELIVERY_SERVICE.id">
                <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :target="'_blank'"
                    :link="$store.getters.GET_PATHS.cdek + '/' + item.object.id"
                />
              </template>
              <template v-if="item.delivery_service.id === NOVAPOSHTA_DELIVERY_SERVICE.id">
                <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :target="'_blank'"
                    :link="$store.getters.GET_PATHS.novaPoshta + '/' + item.object.id"
                />
              </template>

            </template>
          </div>
        </div>

      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {FBMMixinsHelper} from "../../../../../../../../mixins/FBMMixins/FBMMixins";
  import {expressMixins} from "../../../../../../../../mixins/expressMixins/expressMixin";
  import {consolidationMixin} from "../../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import {ordersInboundMixin} from "../../../../../../../../mixins/ordersInbound/ordersInboundMixin";
  import {
    SEARCH_TRACK_SECTIONS,
    UKRPOSHTA_DELIVERY_SERVICE,
    BELPOCHTA_DELIVERY_SERVICE,
    CDEK_DELIVERY_SERVICE,
    NOVAPOSHTA_DELIVERY_SERVICE,
  } from "../../../../../../../../staticData/staticVariables";
  import {VPopover} from 'v-tooltip'
  import TooltipBtn from "../../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";

  export default {
    name: "SearchMobileTable",
    components: {TableUserColumn, LinkButton, VPopover, TooltipBtn},

    mixins: [
      FBMMixinsHelper,
      expressMixins,
      consolidationMixin,
      ordersInboundMixin
    ],

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,

        SEARCH_TRACK_SECTIONS: SEARCH_TRACK_SECTIONS,
        UKRPOSHTA_DELIVERY_SERVICE: UKRPOSHTA_DELIVERY_SERVICE,
        BELPOCHTA_DELIVERY_SERVICE: BELPOCHTA_DELIVERY_SERVICE,
        CDEK_DELIVERY_SERVICE: CDEK_DELIVERY_SERVICE,
        NOVAPOSHTA_DELIVERY_SERVICE: NOVAPOSHTA_DELIVERY_SERVICE,
      }
    }

  }
</script>

<style scoped>

</style>
