<template>
  <div class="fragment">
    <RequestReturnTableUser
            @showMore="showMore"
            @downloadFiles="downloadFiles"
            @returnCheckedRequest="returnCheckedRequest"
            :countFilterParams="countFilterParams"
            @changeFilter="changeFilter"
            @resetFilter="resetFilter"
            :filterGetParams="filterGetParams"
    />

    <RequestManyRefundsPopup
            :ids="requestIds"
            v-if="openRequestManyRefoundsPopup"
            @close="openRequestManyRefoundsPopup = false"
            @refresh="refreshTable"
    />
  </div>
</template>

<script>
  import RequestReturnTableUser from "./RequestReturnTableAllUser/RequestReturnTableAllUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import RequestManyRefundsPopup from "../../popups/RequestManyRefundsPopup/RequestManyRefundsPopup";
  import {socketMixin} from "../../../../../mixins/socketMixins/socketMixin";

  export default {
    name: "RequestReturnTable",

    components: {
      RequestManyRefundsPopup,
      RequestReturnTableUser,
    },

    mixins: [routeFilter, socketMixin],

    data() {
      return {
        forPage: this.$store.getters.getRequestReturnForPage,
        page: 1,
        onePage: 1,

        filterType: 'paypal',
        filterRefundId: '',
        filterPaymentTransactionId: '',
        filterRefundTransactionId: '',
        filterNetAmount: '',
        filterComment: '',
        filterUserId: '',
        filterUserName: '',
        filterDirtyAmount: '',
        filterTotal: '',
        filterDate: [],


        requestIds: [],
        openRequestManyRefoundsPopup: false,
        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    created() {
      this.filter()
    },

    mounted() {
      // let prepareSocket = this.prepareCreatePrivateSocketChannel('RequestReturn')
      // if(prepareSocket.active && !this.$store.getters.getRequestReturnSocket) {
      //   this.$store.commit('setRequestReturnSocket', true)
      //
      //   window.Echo.private(`${prepareSocket.channel}.${prepareSocket.user}`)
      //       .listen(`.${prepareSocket.event}`, (e) => {
      //         console.log(e)
      //         console.log(23432);
      //       }).error(e => {
      //     console.log(e);
      //   })
      // }

    },

    beforeDestroy() {
      if(this.$store.getters.getRequestReturnSocket){
        let prepareSocket = this.prepareCreatePrivateSocketChannel('RequestReturn')
        console.log('close RequestReturnSocket');
        window.Echo.leave(`${prepareSocket.channel}.${prepareSocket.user}`)
        this.$store.commit('setRequestReturnSocket', false)
      }
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          refundId: this.$route.query.refundId,
          refundTransactionId: this.$route.query.refundTransactionId,
          paymentTransactionId: this.$route.query.paymentTransactionId,
          netAmount: this.$route.query.netAmount,
          comment: this.$route.query.comment,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          dirtyAmount: this.$route.query.dirtyAmount,
          total: this.$route.query.total,
          date: this.$route.query.date,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextRequestReturnPage', true)

        // save filter params in store
        this.$store.commit('setRequestReturnFilter', window.location.search)

        this.$store.dispatch('fetchRequestReturn', {filter: url}).then(() => {
          // skip get next page
          this.$store.commit('setNextRequestReturnPage', false)
        })

        this.checkCountFilter()

      },

      generateFilterUrl(page, forPage, exportIds = false) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            filterPaymentTransactionId: 'PaymentTransactionRefundParentPaymentTransactionId',
            filterRefundTransactionId: 'PaymentTransactionRefundTransactionId',
            filterRefundId: 'PaymentTransactionRefundId',
            filterNetAmount: 'PaymentTransactionRefundNetAmount',
            filterComment: 'PaymentTransactionRefundComment',
            filterUserId: 'by_user_id',
            filterDirtyAmount: 'PaymentTransactionRefundAmount',
            filterTotal: 'parentPaymentTransaction.payment_total',
          },
        )

        // If generate on export file
        if(exportIds) {
          myQuery.whereIn('PaymentTransactionRefundId', exportIds)
        }

        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_created_at',
            date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },


      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.$store.dispatch('getExportRequestReturn', {
          systemType: this.filterType,
          filter: url,
          type: type
        }).then((response) => {
          const blob = new Blob([response.data], {type: `application/${type}`})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Request Return.${type}`
          link.click()
          URL.revokeObjectURL(link.href)
        })

      },

      returnCheckedRequest() {
        let requestIds
        requestIds = this.getCheckedRows('row-name')

        if(requestIds.length > 0){
          let text = {
            title: 'RequestReturn_RequestReturnsQuest',
            txt: '',
            yes: 'common_confirm',
            no: 'common_confirmNo'
          }

          let confirm = () => {
            this.requestIds = requestIds
            this.openRequestManyRefoundsPopup = true

            this.$store.commit('setConfirmDeletePopup', false)
            return true
          }

          this.deleteFunc(text, confirm)
        }
      },


    }

  }
</script>

<style scoped>

</style>
