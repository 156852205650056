<template>
  <div class="order-create__row custom-row"
       v-if="isAdmin || FC.data.claimMessages.length > 0 || FC.data.comment.length > 0">
    <div class="order-create__col custom-col custom-col--md-100">
      <div class="messages">
        <div class="messages__item messages__item--right">
          <div class="messages__login"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fillClaim_nameMassege', 'fillClaim_Date'])"></div>
            <b>{{ $t('fillClaim_nameMassege.localization_value.value') }}</b>
            {{FC.data.User.getUserFirstName()}} &nbsp;
            {{FC.data.User.getUserLastName()}}
            <br>
            <b>{{ $t('fillClaim_Date.localization_value.value') }}</b>
            {{FC.data.createdAt}}
          </div>
          <div class="messages__txt">
            <ValueHelper
                    :value="FC.data.comment"
            />
          </div>
        </div>
        <template v-if="FC.data.claimMessages.length > 0">
          <div class="messages__item"
               v-for="(item, index) in FC.data.claimMessages"
               v-bind:class="{'messages__item--right': item.author && !item.author.isAdmin}"
               :key="index"
          >
            <div class="messages__login"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fillClaim_nameMassege', 'fillClaim_Date'])"></div>
              <b>{{ $t('fillClaim_nameMassege.localization_value.value') }}</b> &nbsp;
              {{item.author.user_personal_contact.user_full_name}}
<!--                <template v-if="!item.author.isAdmin">{{item.author.user_personal_contact.user_full_name}}</template>-->
<!--                <template v-else>-->
<!--                  <span v-if="item.author.user_personal_contact.first_name">{{item.author.user_personal_contact.first_name}}</span>-->
<!--                  <span v-else>{{item.author.user_personal_contact.name}}</span>-->
<!--                </template>-->
              <br>
              <b>{{ $t('fillClaim_Date.localization_value.value') }}</b>
              {{item.created_at}}
            </div>
            <div class="messages__txt">
              {{item.message}}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "MessagesItems",
    components: {ValueHelper},

    props: {
      FC: Object,
    },

  }
</script>

<style scoped lang="scss">

  .messages {
    background-color: rgba(143, 122, 97, 0.2);
    padding: 16px;
    border-radius: 5px;

    &__item {
      border-bottom: 1px solid #bbad9c;
      padding-bottom: 16px;
      margin-bottom: 16px;
      line-height: 20px;

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }

      &--right {
        text-align: right;
      }
    }

    &__txt{
      word-break: break-all;
    }

    &__login {
      padding-top: 10px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
</style>
