<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_id'])"></span>
        <DefaultInput
            :label="$t('proform_id.localization_value.value')"
            :type="'text'"
            v-model="proformCategoryId"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_nameUa'])"></span>
        <DefaultInput
            :label="$t('proform_nameUa.localization_value.value')"
            :type="'text'"
            v-model="proformCategoryUa"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_nameRu'])"></span>
        <DefaultInput
            :label="$t('proform_nameRu.localization_value.value')"
            :type="'text'"
            v-model="proformCategoryRu"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_nameEn'])"></span>
        <DefaultInput
            :label="$t('proform_nameEn.localization_value.value')"
            :type="'text'"
            v-model="proformCategoryEn"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "ProformCategoryFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        proformCategoryId: this.filterGetParams.proformCategoryId ? this.filterGetParams.proformCategoryId : '',
        proformCategoryUa: this.filterGetParams.proformCategoryUa ? this.filterGetParams.proformCategoryUa : '',
        proformCategoryRu: this.filterGetParams.proformCategoryRu ? this.filterGetParams.proformCategoryRu : '',
        proformCategoryEn: this.filterGetParams.proformCategoryEn ? this.filterGetParams.proformCategoryEn : '',

        options: [],

        filterCounts: [
          'proformCategoryId',
          'proformCategoryUa',
          'proformCategoryRu',
          'proformCategoryEn',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.proformCategoryId = newVal.proformCategoryId ? newVal.proformCategoryId : ''
        this.proformCategoryUa = newVal.proformCategoryUa ? newVal.proformCategoryUa : ''
        this.proformCategoryRu = newVal.proformCategoryRu ? newVal.proformCategoryRu : ''
        this.proformCategoryEn = newVal.proformCategoryEn ? newVal.proformCategoryEn : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeUsersFilter(value) {
        this.userId = value.id
        this.userName = value.contacts[0].user_full_name
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading)
      },

    },
  }
</script>

<style scoped>

</style>
