<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--empty"></div>

      <div class="content-top-line-wrap">
<!--        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter"/>-->
      </div>
    </div>

  </div>
</template>

<script>
  // import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


  export default {
    name: "MultiSearchHead",
    components: {
      // ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    watch: {},

    created() {

    },

    data() {
      return {}
    },

    methods: {}
  }
</script>

