<template>
  <FillClaimTableUser
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          :createData="createData"
          @resetFilter="resetFilter"
          @showMore="showMore"
          @changeFilter="changeFilter"
          @reload="reload"
          @downloadFiles="downloadFiles"
  />
</template>

<script>
  import FillClaimTableUser from "./FillClaimTableUser/FillClaimTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {fillClaimMixin} from "../../../../../mixins/fillClaimMixins/fillClaimMixin";

  export default {
    name: "FillClaimTable",

    components: {
      FillClaimTableUser,
    },

    mixins: [routeFilter, fillClaimMixin],

    data(){
      return {
        forPage: this.$store.getters.getFillClaimForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterUserAdminId: '',
        filterUserAdminName: '',
        filterTrackingNumber: '',
        filterCreatedAt: [],
        filterShippingDate: [],
        filterCloseDate: [],

        filterReasonId: '',
        filterReasonName: '',
        filterStatusId: '',
        filterStatusName: '',
        filterShippingCompanyId: '',
        filterShippingCompanyName: '',
        filterProcessId: '',
        filterProcessName: '',

        countFilterParams: 0,
        filterGetParams: {},

        createData: null,
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          userAdminId: this.$route.query.userAdminId,
          userAdminName: this.$route.query.userAdminName,
          trackingNumber: this.$route.query.trackingNumber,
          createdAt: this.$route.query.createdAt,
          shippingDate: this.$route.query.shippingDate,
          closeDate: this.$route.query.closeDate,

          reasonId: this.$route.query.reasonId,
          reasonName: this.$route.query.reasonName,
          statusId: this.$route.query.statusId,
          statusName: this.$route.query.statusName,
          shippingCompanyId: this.$route.query.shippingCompanyId,
          shippingCompanyName: this.$route.query.shippingCompanyName,
          processId: this.$route.query.processId,
          processName: this.$route.query.processName,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextFillClaimPage', true)
        this.$store.dispatch('fetchFillClaim', url).then(() => {
          this.$store.commit('setNextFillClaimPage', false)
          this.$store.commit('setGlobalUpdateTable', false)

          let {delivery_services, reasons, status_results, statuses} = this.$store.getters.getFillClaimCommonList
          let data = {reasons, status_results, statuses}

          for(let item in data){
            data[item] = this.parseDataForSelectUse({data: data[item], name: 'name', value: 'value'})
          }

          data.delivery_services = delivery_services
          this.createData = data
          console.log(this.createData);
        }).catch(error => this.createErrorLog(error))

        this.checkCountFilter(['userName', 'userAdminName', 'reasonName', 'statusName', 'shippingCompanyName', 'processName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})
        console.log(this.filterProcessId);
        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'id',
            filterUserId: 'user_id',
            filterUserAdminId: 'admin_user_id',
            filterTrackingNumber: 'tracking_number',

            filterReasonId: 'reason',
            filterStatusId: 'status',
            filterProcessId: 'status_result',
            filterShippingCompanyId: 'delivery_service_id',
          },
        )

        if (this.filterCreatedAt.length > 0) {
          let date = this.generateDateForFilter(this.filterCreatedAt, 'YYYY-MM-DD')
          myQuery.whereIn('createdAt', date)
        }

        if (this.filterShippingDate.length > 0) {
          let date = this.generateDateForFilter(this.filterShippingDate, 'YYYY-MM-DD')
          myQuery.whereIn('shippingDate', date)
        }

        if (this.filterCloseDate.length > 0) {
          let date = this.generateDateForFilter(this.filterCloseDate, 'YYYY-MM-DD')
          myQuery.whereIn('closeDate', date)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(type) {
        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage)

        let fileName = `Claim`

        this.exportTableFiles(url, type, 'getExportClaim', fileName)
      },

    },

  }
</script>

<style scoped>

</style>
