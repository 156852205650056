<template>
  <div class="fragment">
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fillClaim_Details',])"></div>
      {{$t('fillClaim_Details.localization_value.value')}}
    </div>
    <div class="custom-row">


      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_TrackingNumber',])"></div>
        <DefaultInput
                :label="$t('fillClaim_TrackingNumber.localization_value.value')"
                v-bind:class="{'ui-no-valid': FC.validation.trackingNumber}"
                :errorTxt="$t(`${FC.validationTranslate.trackingNumber}.localization_value.value`)"
                :error="FC.validation.trackingNumber"
                :disabled="!isAdmin && FC.data.status && isStatusOpen(FC.data.status.value)"
                v-model="FC.data.trackingNumber"
        />
      </div>
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_ReasonToClaim',])"></div>
        <DefaultSelect
                class="w-100"
                :options="reasons"
                :label="$t('fillClaim_ReasonToClaim.localization_value.value')"
                :optionsLabel="'name'"
                v-bind:class="{'ui-no-valid': FC.validation.claimReasons}"
                :errorTxt="$t(`${FC.validationTranslate.claimReasons}.localization_value.value`)"
                :error="FC.validation.claimReasons"
                :selected="FC.data.claimReasons"
                :disabled="!isAdmin && FC.data.status && isStatusOpen(FC.data.status.value)"
                @change="(val) => {FC.setClaimReasons(val)}"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_Value',])"></div>
        <InputSum
            :label="$t('fillClaim_Value.localization_value.value')"
            :icoType="'dollar'"
            :placeholder="'0'"
            v-bind:class="{'ui-no-valid': FC.validation.declaredValue}"
            :errorTxt="$t(`${FC.validationTranslate.declaredValue}.localization_value.value`)"
            :error="FC.validation.declaredValue"
            :disabled="!isAdmin && FC.data.status && isStatusOpen(FC.data.status.value)"
            :value="FC.data.declaredValue"
            @change="changeDeclaredValue"
        />
      </div>
    </div>
    <div class="custom-row">
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_ShipDate','common_dateYYYY_MM_DD'])"></div>
        <DatePickerDefault
                :label="$t('fillClaim_ShipDate.localization_value.value')"
                :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
                v-bind:class="{'ui-no-valid': FC.validation.shippingDate}"
                :errorTxt="$t(`${FC.validationTranslate.shippingDate}.localization_value.value`)"
                :error="FC.validation.shippingDate"
                :disabled="!isAdmin && FC.data.status && isStatusOpen(FC.data.status.value)"
                v-model="FC.data.shippingDate"
        >
          <template slot="body">
            <date-picker
                    v-model="FC.data.shippingDate"
                    ref="datePicker"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'YYYY-MM-DD'"
                    :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
                    :disabled="!isAdmin && FC.data.status && isStatusOpen(FC.data.status.value)"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div v-if="isAdmin" class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fillClaim_ClaimStatuses',])"></div>
        <DefaultSelect
            class="w-100"
            :options="statuses"
            :label="$t('fillClaim_ClaimStatuses.localization_value.value')"
            :optionsLabel="'name'"
            v-bind:class="{'ui-no-valid': FC.validation.status}"
            :errorTxt="$t(`${FC.validationTranslate.status}.localization_value.value`)"
            :error="FC.validation.status"
            @change="(val) => {FC.setStatus(val)}"
            :selected="FC.data.status"
        />
      </div>

    </div>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker';
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {fillClaimMixin} from "../../../../../../mixins/fillClaimMixins/fillClaimMixin";
  import InputSum from "../../../../../UI/inputs/InputSum/InputSum";

  export default {
    name: "DetailsBlock",
    components: {
      InputSum,
      DefaultSelect,
      DatePickerDefault,
      DefaultInput,
      DatePicker,
    },

    mixins: [fillClaimMixin],

    props: {
      FC: Object,
      createData: Object,
    },

    watch: {
      createData(newVal) {
        if(newVal?.reasons){
          this.reasons = this.parseDataForSelectUse({data: newVal?.reasons, name: 'name', value: 'value'})
        }
        if(newVal?.statuses){
          this.statuses = this.parseDataForSelectUse({data: newVal?.statuses, name: 'name', value: 'value'})
        }
      }
    },

    data() {
      return {
        reasons: [],
        statuses: [],
      }
    },

    methods: {
      changeDeclaredValue(val) {
        this.FC.setDeclaredValue(val)
      }
    },

  }
</script>

<style scoped>

</style>
