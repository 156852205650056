<template>
  <SearchTableAdmin
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @reload="reload"
  />
</template>

<script>
  import SearchTableAdmin from "./SearchTableAdmin/SearchTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "SearchTable",

    components: {
      SearchTableAdmin,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getSearchForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterUserId: '',
        filterTrackingNumber: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    mounted() {
      this.filter()
    },

    //fix for a situation where user makes another search from the search page
    beforeRouteUpdate (to, from, next) {
      next()
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          tracking_number: this.$route.query.tracking_number,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextSearchPage', true)
        this.$store.dispatch('fetchSearchTrack', url).then(() => {
          this.$store.commit('setNextSearchPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })

        this.checkCountFilter(['userName', 'userAdminName', 'tracking_number'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()
        // this.generateFilterQueryParams(
        //   myQuery,
        //   {
        //     filterId: 'id',
        //   },
        // )

        if (this.$route.query.userId) {
          myQuery.params({ tracking_number: this.$route.query.tracking_number, user_id: this.$route.query.userId})
        }
        else {
          myQuery.params({ tracking_number: this.$route.query.tracking_number })
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    },

  }
</script>

<style scoped>

</style>
