var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page"},[_c('div',{staticClass:"detail-page__left"},[_c('CardLeftBlock',{attrs:{"name":_vm.$t('RequestReturn_Return.localization_value.value'),"value":'#180378',"backgroundImage":'product-admin'}})],1),_c('div',{staticClass:"detail-page__right"},[_c('CardRightBlock',[_c('template',{slot:"header"},[_c('div',{staticClass:"order-create__head"},[_c('div',{staticClass:"order-create__head-line"},[_c('div',{staticClass:"order-create__header-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_BackReturns',
                ])}}}),_c('router-link',{staticClass:"order-create__head-back",attrs:{"to":_vm.$store.getters.GET_PATHS.financeRequestReturn}},[_c('LinkBack',{attrs:{"value":_vm.$t('RequestReturn_BackReturns.localization_value.value')}})],1)],1)]),_c('div',{staticClass:"order-create__head-title",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_RequestReturn',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('RequestReturn_RequestReturn.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__head-txt mb-0",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_PleaseFill',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('RequestReturn_PleaseFill.localization_value.value'))+" ")])])]),_c('template',{slot:"body"},[_c('div',{staticClass:"order-create__section"},[_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_OrderDeatils',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('RequestReturn_OrderDeatils.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_LotNumber',
                ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('RequestReturn_LotNumber.localization_value.value'),"errorTxt":'This field is required',"disabled":true},model:{value:(_vm.requestReturn.lotNumber),callback:function ($$v) {_vm.$set(_vm.requestReturn, "lotNumber", $$v)},expression:"requestReturn.lotNumber"}})],1),_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_Transaction',
                ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('RequestReturn_Transaction.localization_value.value'),"errorTxt":'This field is required',"disabled":true},model:{value:(_vm.requestReturn.transactionNumber),callback:function ($$v) {_vm.$set(_vm.requestReturn, "transactionNumber", $$v)},expression:"requestReturn.transactionNumber"}})],1)]),_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_ReturnsDetails',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('RequestReturn_ReturnsDetails.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--16 custom-col--md-50 custom-col--xs-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_RefundTotaly',
                ])}}}),_c('RadioDefault',{attrs:{"label":_vm.$t('RequestReturn_RefundTotaly.localization_value.value'),"name":'insuranceRadio',"value":_vm.requestReturn.returnType === _vm.returnTypes.totally},nativeOn:{"change":function($event){return _vm.changeRadio(_vm.returnTypes.totally)}}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--xs-100 flex-column mb-0",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_RefundPartially',
                  'RequestReturn_RefundSum',
                ])}}}),_c('RadioDefault',{attrs:{"label":_vm.$t('RequestReturn_RefundPartially.localization_value.value'),"name":'insuranceRadio',"value":_vm.requestReturn.returnType === _vm.returnTypes.partially},nativeOn:{"change":function($event){return _vm.changeRadio(_vm.returnTypes.partially)}}}),_c('div',{staticClass:"custom-row mt-4"},[_c('div',{staticClass:"custom-col custom-col--50"},[_c('InputSum',{class:{'ui-no-valid': _vm.validation.amount,
                      'disabled-opacity' : _vm.requestReturn.returnType === _vm.returnTypes.totally},attrs:{"numeric":true,"label":_vm.$t('RequestReturn_RefundSum.localization_value.value'),"icoType":'dollar',"placeholder":'0',"disabled":_vm.requestReturn.returnType === _vm.returnTypes.totally,"error":_vm.validation.amount,"errorTxt":_vm.serverError ? _vm.validationTxt.amount : _vm.$t(`${_vm.validationTranslate.amount}.localization_value.value`)},model:{value:(_vm.requestReturn.refundPartially),callback:function ($$v) {_vm.$set(_vm.requestReturn, "refundPartially", $$v)},expression:"requestReturn.refundPartially"}})],1)])],1)]),_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'requestReturn_Comment',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('requestReturn_Comment.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__row custom-row",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'requestReturn_Comment',
                ])}}}),_c('div',{staticClass:"order-create__col custom-col custom-col--66 custom-col--md-100"},[_c('TextareaDefault',{class:{'ui-no-valid': _vm.validation.comment},attrs:{"label":_vm.$t('requestReturn_Comment.localization_value.value'),"error":_vm.validation.comment,"errorTxt":_vm.serverError ? _vm.validationTxt.comment : _vm.$t(`${_vm.validationTranslate.comment}.localization_value.value`),"disabled":true},model:{value:(_vm.requestReturn.comment),callback:function ($$v) {_vm.$set(_vm.requestReturn, "comment", $$v)},expression:"requestReturn.comment"}})],1)]),(Object.hasOwnProperty.call(_vm.$store.getters.getRequestReturnItem, 'parent_payment_transaction') &&
               _vm.$store.getters.getRequestReturnItem.parent_payment_transaction.payment_system == _vm.PAYMENT_TYPE_NAME.payPall &&
                _vm.$store.getters.getIsAdminRights === 'admin')?_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-50 custom-col--sm-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'RequestReturn_WriteOff',
                ])}}}),_c('DefaultCheckbox',{staticClass:"wfc",attrs:{"label":_vm.$t('RequestReturn_WriteOff.localization_value.value')},model:{value:(_vm.requestReturn.withApi),callback:function ($$v) {_vm.$set(_vm.requestReturn, "withApi", $$v)},expression:"requestReturn.withApi"}})],1)]):_vm._e()])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"order-create__footer"},[_c('div',{staticClass:"order-create__footer-btn ml-auto",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'cuspayments_requestRefund',
                  'RequestReturn_CancelRefund',
                ])}}}),(!_vm.isAdmin && Object.hasOwnProperty.call(_vm.$store.getters.getRequestReturnItem, 'parent_payment_transaction') &&
                  _vm.$store.getters.getRequestReturnItem.parent_payment_transaction.payment_system == _vm.PAYMENT_TYPE_NAME.authorize)?_c('MainButton',{staticClass:"order-create__footer-btn-i secondary",attrs:{"value":_vm.$t('common_SaveInDrafts.localization_value.value'),"tooltip":true},nativeOn:{"click":function($event){return _vm.createReturns(true)}}},[_c('template',{slot:"tooltip"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('common_drafts.localization_value.value')))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('common_savingInDrafts.localization_value.value'))+" ")])])],2):(_vm._.has(_vm.$store.getters.getRequestReturnItem, 'parent_payment_transaction') &&
                    _vm.$store.getters.getRequestReturnItem.parent_payment_transaction.canMakeRefund)?_c('MainButton',{staticClass:"order-create__footer-btn-i wfc",class:{'disabled-btn' : _vm.$store.getters.getRequestReturnBtn},attrs:{"value":_vm.$t('cuspayments_requestRefund.localization_value.value')},nativeOn:{"click":function($event){return _vm.createReturns.apply(null, arguments)}}}):_vm._e()],1)])])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }