<template>
  <div class="fragment">
    <ProformCategoryTableAdmin
            @showMore="showMore"
            :countFilterParams="countFilterParams"
            @changeFilter="changeFilter"
            @resetFilter="resetFilter"
            :filterGetParams="filterGetParams"
            @updateTable="filter"

            v-if="$store.getters.getIsAdminRights === 'admin'"
    />

  </div>
</template>

<script>
  import ProformCategoryTableAdmin from "./ProformCategoryTableAdmin/ProformCategoryTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ProformCategoryTable",

    components: {
      ProformCategoryTableAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getProformCategoryForPage,
        page: 1,
        onePage: 1,

        filterGetParams:{},

        filterProformCategoryId: '',
        filterProformCategoryUa: '',
        filterProformCategoryRu: '',
        filterProformCategoryEn: '',

        countFilterParams: 0,
      }
    },

    created() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          proformCategoryId: this.$route.query.proformCategoryId,
          proformCategoryUa: this.$route.query.proformCategoryUa,
          proformCategoryRu: this.$route.query.proformCategoryRu,
          proformCategoryEn: this.$route.query.proformCategoryEn,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextProformCategoryPage', true)

        // save filter params in store
        this.$store.commit('setProformCategoryFilter', window.location.search)

        this.$store.dispatch('fetchProformCategory', url).then(() => {
          // skip get next page
          this.$store.commit('setNextProformCategoryPage', false)
        })

        this.checkCountFilter()

      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
          myQuery,
          {
            filterProformCategoryId: 'ProformCategoryId',
            filterProformCategoryUa: 'name_ua',
            filterProformCategoryRu: 'name_ru',
            filterProformCategoryEn: 'name_en',
          },
        )

        myQuery.where('ProformCategoryIsSystem', '0')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }

  }
</script>

<style scoped>

</style>
